import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRootAuth = ENDPOINTS.API + '/auth';
const apiRootUser = ENDPOINTS.API + '/user';

export const getAll = (getActive) => {
	let e = {
		getActive: getActive
	}
	let url = `${apiRootUser}/GetUsers`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(e)
	};
	return handleFetch(url, options, true);
}

export const getEmployees = () => {
	let url = `${apiRootUser}/GetEmployees`;

	const options = {
		method: API_METHOD.POST
	};
	return handleFetch(url, options, true);
}

export const update = (data) => {
	// we are hitting the user controller
	let url = `${apiRootUser}/UpdateUser`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const insert = (data) => {
	// we are hitting the auth controller
	let url = `${apiRootAuth}/AddUser`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);

}

export const adminResetPassword = (data) => {
	// we are hitting the user controller	
	let url = `${apiRootAuth}/AdminPasswordReset`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}