export const VERSION = '8.18.23.107';
export const API_VERSION = '4.8.21.1';
export const ENDPOINTS = {
	// local dev
	//API: 'http://localhost:5200/api',
	//CALL_BACK_URL: 'http://localhost:3000/reset-password'

	// prod
	API: 'https://caa-api.azurewebsites.net/api',
	//CALL_BACK_URL: 'https://prodapi.caa-apps.com/reset-password'

	// deploy
	// do a yarn build
	// then do a swa deploy
};

export const EMAIL_REGEX = `const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;`;

export const TAB_ITEMS = {
	ASSIGNED: 0,
	AVAILABLE: 1
}

export const PROVIDERS = [
	{
		value: 1,
		label: 'ATI'
	},
	{
		value: 2,
		label: 'Vandenberg'
	},
	{
		value: 3,
		label: 'TRACE'
	}
]

export const LOCAL_STORAGE = {
	PROFILE: 'caa-profile',
	TOKEN: 'caa-token',
	MODULE_ID: 'caa-module-id',
	SECTION_ID: 'caa-section-id',
	STUDENT_MODULE: 'caa-student-module',
};

export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE'
};

export const AUTH_HEADER = {
	AUTH: {
		'Authorization': null,
		'Content-Type': 'application/json; charset=utf-8'
	},
	NO_AUTH: {
		'Content-Type': 'application/json; charset=utf-8'
	}
};

export const BUTTON_ACTIONS = {
	INSERT: 'Insert',
	UPDATE: 'Update',
	EDIT: 'Edit',
	DELETE: 'Delete'
};


export const MODULE_STATUS = [
	{
		value: 1,
		label: 'Draft'
	},
	{
		value: 2,
		label: 'Active'
	},
	{
		value: 3,
		label: 'Inactive'
	},
	{
		value: 4,
		label: 'Archived'
	},
	// {
	// 	value: 5,
	// 	label: 'Deleted'
	// }
]


export const USER_MODULE_STATUS = [
	{
		value: 1,
		label: 'New'
	},
	{
		value: 2,
		label: 'In Progress'
	},
	{
		value: 3,
		label: 'Completed'
	},
	{
		value: 4,
		label: 'Expired'
	}
]


export const USER_MODULE_STATUS_VALUES = {
	NEW: 1,
	IN_PROGRESS: 2,
	COMPLETED: 3,
	FAILED: 4, // hit the attempt limit
	EXPIRED: 5,
	REMOVED: 6
}


export const SECTION_TYPEID = {
	VIDEO: 1,
	PDF: 2,
	QUIZ: 3
}

export const SECTION_TYPE = [
	{
		value: 0,
		label: 'Select a Type....'
	},
	{
		value: 1,
		label: 'Video'
	},
	{
		value: 2,
		label: 'PDF'
	},
	{
		value: 3,
		label: 'Quiz'
	}
]


export const USER_QUIZ_ATTEMPT_STATUS = [
	{
		value: 1,
		label: 'New'
	},
	{
		value: 2,
		label: 'Failed'
	},
	{
		value: 3,
		label: 'Passed'
	}
]

export const USER_QUIZ_STATUS_VALUES = {
	NEW: 1,
	FAILED: 2,
	PASSED: 3
}



export const USER_SECTION_STATUS_VALUES = {
	INCOMPLETE: 1,
	COMPLETE: 2,
	ATTEMPT_LIMIT_REACHED: 3,
	CAN_COMPLETE_SECTION: 4
}