import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
	Button, Row, Col, FormGroup, Label, Alert, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
// components

// services

const SectionFormModal = ({ showModal, cancelItem, sectionSubmit }) => {

	const { section } = useSelector(state => state.sectionReducer);

	const descriptionRef = useRef();
	const passingScoreRef = useRef();
	const attemptsAllowedRef = useRef();
	const siteCodeRef = useRef();
	const siteCodeDateRef = useRef();
	const ceHoursRef = useRef();

	const [errorMessage, setErrorMessage] = useState('');
	const [selectedItem, setSelectedItem] = useState({});


	useEffect(() => {
		setSelectedItem(section);

	}, [section]);

	const submitModal = () => {
		if (validateForm()) {

			sectionSubmit(selectedItem);
		}
	}

	const validateForm = () => {
		// 	dateMustCompletedBy: completeDate === 'Invalid date' ? null : completeDate

		if (!selectedItem.description) {
			setErrorMessage('Please enter a section description');
			descriptionRef.current.focus();
			return false;
		}

		if (selectedItem.sectionTypeId === 3) {
			if (!selectedItem.passingScore) {
				setErrorMessage('Please enter a passing score');
				passingScoreRef.current.focus();
				return false;
			}
		}

		setErrorMessage('');
		return true;
	}

	const cancelModal = () => {
		cancelItem();
	};


	return (
		<Modal isOpen={showModal} centered style={{ maxWidth: 1000 }}>
			<ModalHeader>Update Section</ModalHeader>
			<ModalBody className="bg-light">
				{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
				<Row>
					<Col className="col-6">
						<FormGroup>
							<Label className="font-weight-bold">Description *</Label>
							<Input
								placeholder='Description'
								innerRef={descriptionRef}
								autoComplete='nofill'
								value={(selectedItem && selectedItem.description) ? selectedItem.description : ''}
								onChange={({ target }) => setSelectedItem({ ...selectedItem, description: target.value })}
							/>
						</FormGroup>

						{selectedItem.sectionTypeId === 3 && // if it's a quiz
							<FormGroup>
								<Label className="font-weight-bold">Passing Score</Label><br />
								<Input
									placeholder='Passing Score'
									autoComplete='nofill'
									innerRef={passingScoreRef}
									min={0} max={100}
									type="number" step="1"
									value={(selectedItem && selectedItem.passingScore) ? selectedItem.passingScore : '0'}
									onChange={({ target }) => setSelectedItem({ ...selectedItem, passingScore: target.value })}
								/>
							</FormGroup>
						}
					</Col>
					<Col className="col-6">
						<FormGroup>
							<Label>Section Type *</Label>
							<div style={{ height: 38 }}>
								<Input
									type="radio"
									disabled={true}
									name="pdfSection"
									id="pdfSection"
									label="Pdf Section"
									inline
									checked={selectedItem.sectionTypeId === 2 ? true : false}
									onChange={() => setSelectedItem({ ...selectedItem, sectionTypeId: 2 })}
								/>
								<Input
									type="radio"
									disabled={true}
									name="videoSection"
									id="videoSection"
									label="Video Section"
									inline
									checked={selectedItem.sectionTypeId === 1 ? true : false}
									onChange={() => setSelectedItem({ ...selectedItem, sectionTypeId: 1 })}
								/>
								<Input
									type="radio"
									disabled={true}
									name="quizSection"
									id="quizSection"
									label="Quiz Section"
									inline
									checked={selectedItem.sectionTypeId === 3 ? true : false}
									onChange={() => setSelectedItem({ ...selectedItem, sectionTypeId: 3 })}
								/>
							</div>
						</FormGroup>
						{selectedItem.sectionTypeId === 3 && // if it's a quiz						
							<FormGroup>
								<Label className="font-weight-bold">Attempts Allowed (0 = infinite)</Label><br />
								<Input
									placeholder='Attempts Allowed'
									autoComplete='nofill'
									innerRef={attemptsAllowedRef}
									min={0} max={100}
									type="number" step="1"
									value={(selectedItem && selectedItem.numberOfAttemptsAllowed) ? selectedItem.numberOfAttemptsAllowed : '0'}
									onChange={({ target }) => setSelectedItem({ ...selectedItem, numberOfAttemptsAllowed: target.value })}
								/>
							</FormGroup>

						}
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={submitModal}>Update</Button>
				<Button size="sm" outline={true} className="pr-3 fs--1" color="danger" onClick={cancelModal}>Cancel</Button>
			</ModalFooter>
		</Modal>

	);
};
export default SectionFormModal;