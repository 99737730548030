import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/facility';


export const getAll = () => {

	let url = `${apiRoot}/GetAll`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}


export const search = (data) => {

	let url = `${apiRoot}/Search`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}



export const update = (data) => {

	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const insert = (data) => {

	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

