import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, FormGroup, Label, Alert, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import Select from 'react-select';
import MaskedInput from 'react-maskedinput';
import 'react-datetime/css/react-datetime.css';
//import moment from 'moment';

// components

// services
import * as moduleActions from '../../redux/actions/moduleActions';
import { BUTTON_ACTIONS, MODULE_STATUS } from '../../services/appConstants'

const ModuleFormModal = ({ buttonAction, showModal, cancelItem, formSubmit }) => {

	let dispatch = useDispatch();
	// moment(selectedItem.dateSiteCodeExpires).format('L')
	const { module } = useSelector(state => state.moduleReducer);

	const titleRef = useRef();
	const releaseDateRef = useRef();
	const completeDateRef = useRef();
	const siteCodeRef = useRef();
	const siteCodeDateRef = useRef();
	const ceHoursRef = useRef();

	const [errorMessage, setErrorMessage] = useState('');
	const [selectedItem, setSelectedItem] = useState({});

	useEffect(() => {
		setSelectedItem(module);
	}, [module]);

	const submitModal = () => {
		if (validateForm()) {
			//place this in the action
			if (buttonAction === BUTTON_ACTIONS.UPDATE) {
				dispatch(moduleActions.updateItem(selectedItem))
					.then(resp => {
						formSubmit(selectedItem);
					});

			} else {
				dispatch(moduleActions.insertItem(selectedItem))
					.then(resp => {

						formSubmit(selectedItem);
					});
			}

		}
	}

	const validateForm = () => {
		// 	dateMustCompletedBy: completeDate === 'Invalid date' ? null : completeDate

		if (!selectedItem.title) {
			setErrorMessage('Please enter a module title');
			titleRef.current.focus();
			return false;
		}

		if (!selectedItem.dateScheduledForRelease) {
			setErrorMessage('Please enter a release date');
			releaseDateRef.current.focus();
			return false;
		} else {

			if (selectedItem.dateScheduledForRelease.includes("_")) {
				setErrorMessage('Release Date is not properly formatted.  Include, mm/dd/yyyy');
				releaseDateRef.current.focus();
				return false;
			} else {

				// check if it's a date at all
				let temp = new Date(selectedItem.dateScheduledForRelease);
				if (temp.toString() === 'Invalid Date') {
					setErrorMessage('Release Date is invalid, please try again');
					releaseDateRef.current.focus();
					return false;
				}
			}
		}

		if (!selectedItem.ceHours) {
			setErrorMessage('Please enter CE hours');
			ceHoursRef.current.focus();
			return false;
		}

		if (!selectedItem.siteCode) {
			setErrorMessage('Please enter a site code');
			siteCodeRef.current.focus();
			return false;
		}

		if (!selectedItem.dateSiteCodeExpires) {
			setErrorMessage('Please enter a site code expiration date');
			siteCodeDateRef.current.focus();
			return false;
		} else {

			if (selectedItem.dateSiteCodeExpires.includes("_")) {
				setErrorMessage('Site Code Expiration Date is not properly formatted.  Include, mm/dd/yyyy');
				siteCodeDateRef.current.focus();
				return false;
			} else {

				// check if it's a date at all
				let temp = new Date(selectedItem.dateSiteCodeExpires);
				if (temp.toString() === 'Invalid Date') {
					setErrorMessage('Site Code Expiration Date is invalid, please try again');
					siteCodeDateRef.current.focus();
					return false;
				}
			}
		}

		if (selectedItem.dateMustCompletedBy !== 'Invalid date' && selectedItem.dateMustCompletedBy !== '') {
			// if we have something in the txt
			if (selectedItem.dateMustCompletedBy.includes("_")) {
				setErrorMessage('Date Complete By is not properly formatted.  Include, mm/dd/yyyy');
				completeDateRef.current.focus();
				return false;
			} else {

				// check if it's a date at all
				let temp = new Date(selectedItem.dateMustCompletedBy);
				if (temp.toString() === 'Invalid Date') {
					setErrorMessage('Date Complete By is invalid, please try again');
					completeDateRef.current.focus();
					return false;
				}
			}
		}
		setErrorMessage('');
		return true;
	}

	const cancelModal = () => {
		cancelItem();
	};

	const handleTypeChange = (dropDownItem) => {
		setSelectedItem({ ...selectedItem, moduleStatusId: dropDownItem.value, moduleStatus: dropDownItem.label });
	}

	// const dateChange = (e) => {
	// 	setSelectedItem({ ...selectedItem, dateScheduledForRelease: e });
	// }


	return (
		<Modal isOpen={showModal} centered style={{ maxWidth: 1000 }}>
			<ModalHeader>{buttonAction} Module</ModalHeader>
			<ModalBody className="bg-light">
				{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
				<Row>
					<Col className="col-6">
						<FormGroup>
							<Label className="font-weight-bold">Title *</Label>
							<Input
								placeholder='Title'
								innerRef={titleRef}
								autoComplete='nofill'
								value={(selectedItem && selectedItem.title) ? selectedItem.title : ''}
								onChange={({ target }) => setSelectedItem({ ...selectedItem, title: target.value })}
							/>

						</FormGroup>
						<FormGroup>
							<Label className="font-weight-bold">Status</Label>
							<Select
								value={{ value: selectedItem.moduleStatusId, label: selectedItem.moduleStatus }}
								onChange={handleTypeChange}

								isDisabled={buttonAction === BUTTON_ACTIONS.INSERT ? true : false}
								options={MODULE_STATUS} />
						</FormGroup>
						<FormGroup>
							<Label className="font-weight-bold">Module Release Date *</Label><br />
							<MaskedInput className="form-control" mask="11/11/1111"
								ref={releaseDateRef}
								name="expiry"
								placeholder="mm/dd/yyyy"
								//value={(selectedItem && selectedItem.dateScheduledForRelease) ? moment(selectedItem.dateScheduledForRelease).format('L') : null}
								value={(selectedItem && selectedItem.dateScheduledForRelease) ? selectedItem.dateScheduledForRelease : null}
								onChange={({ target }) => setSelectedItem({ ...selectedItem, dateScheduledForRelease: target.value })}

							/>
						</FormGroup>

						<FormGroup>
							<Label className="font-weight-bold">CE Hours *</Label><br />
							<Input
								placeholder='CE Hours'
								autoComplete='nofill'
								innerRef={ceHoursRef}
								min={0} max={100} type="number" step="1"
								value={(selectedItem && selectedItem.ceHours) ? selectedItem.ceHours.toString() : ''}
								onChange={({ target }) => setSelectedItem({ ...selectedItem, ceHours: target.value })}
							/>
						</FormGroup>
					</Col>
					<Col className="col-6">
						<FormGroup>
							<Label className="font-weight-bold">Site Code *</Label>
							<Input
								placeholder='Site Code'
								autoComplete='nofill'
								innerRef={siteCodeRef}
								value={(selectedItem && selectedItem.siteCode) ? selectedItem.siteCode : ''}
								onChange={({ target }) => setSelectedItem({ ...selectedItem, siteCode: target.value })}
							/>
						</FormGroup>
						<FormGroup>
							<Label className="font-weight-bold">Site Code Expiration *</Label>
							<MaskedInput className="form-control" mask="11/11/1111"
								ref={siteCodeDateRef}
								name="siteCodeDate"
								placeholder="mm/dd/yyyy"
								value={(selectedItem && selectedItem.dateSiteCodeExpires) ? selectedItem.dateSiteCodeExpires : ''}
								onChange={({ target }) => setSelectedItem({ ...selectedItem, dateSiteCodeExpires: target.value })}
							/>
						</FormGroup>

						<FormGroup>
							<Label className="font-weight-bold">Is Required</Label><br />
							<div style={{ height: 38 }}>
								{/* <Form.Check
									type="switch"
									id="isRequired"
									label="Is Module Required"
									checked={selectedItem.isRequired}
									onChange={({ target }) => setSelectedItem({ ...selectedItem, isRequired: target.checked })}
								/> */}
							</div>
						</FormGroup>
						<FormGroup>
							<Label className="font-weight-bold">Date Must Complete By</Label><br />
							<MaskedInput className="form-control" mask="11/11/1111"
								ref={completeDateRef}
								name="completeDate"
								value={(selectedItem && selectedItem.dateMustCompletedBy) ? selectedItem.dateMustCompletedBy : ''}
								onChange={({ target }) => setSelectedItem({ ...selectedItem, dateMustCompletedBy: target.value })}
							/>
						</FormGroup>

					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={submitModal}>{buttonAction}</Button>
				<Button size="sm" outline={true} className="pr-3 fs--1" color="danger" onClick={cancelModal}>Cancel</Button>
			</ModalFooter>
		</Modal>

	);
};
export default ModuleFormModal;