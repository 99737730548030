import { TRAINING as ActionTypes } from '../actionTypes';

const initialState = {
	module: {
		moduleTemplateId: 0,
		moduleStatus: 'Draft', // string interpolation
		moduleStatusId: 0,
		ceHours: 0,
		isRequired: false,
		siteCode: '',
		title: '',
		dateMustCompletedBy: '',
		dateScheduledForRelease: '',
		dateSiteCodeExpires: ''
	},
	modules: [],
	moduleUsers: [],
	availableUsers: []

};

export const moduleReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_MODULES:
			return {
				...state,
				modules: action.payload
			};
		case ActionTypes.SET_SELECTED_MODULE:
			return {
				...state,
				module: action.payload
			};
		case ActionTypes.SET_EMPTY_MODULE:
			return {
				...state,
				module: initialState.module
			};

		case ActionTypes.INSERT_MODULE:
			// push item into  list
			let newList = [...state.modules];
			newList.push(action.payload);

			return {
				...state,
				modules: newList,
				module: action.payload
			};
		case ActionTypes.UPDATE_MODULE:
			// clear the selected item with the initial.state item
			// update the item in the list of items
			let myItems = [...state.modules];
			let indexToUpdate = myItems.findIndex(item => item.moduleTemplateId === action.payload.moduleTemplateId);
			myItems[indexToUpdate] = action.payload;

			return {
				...state,
				modules: myItems,
				module: action.payload
			};
		default:
			return state;
	};
}