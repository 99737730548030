import React, { useState, useEffect, useRef } from 'react';
import {
	Button, Card, CardBody, Row, Col, CardHeader, FormGroup, Label, Alert, Input,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

// components
import Questions from './_Questions';
import Answers from './_Answers';

// services


const UploadSelectModal = ({ sectionTemplateId, showUploadModal, closeAttachmentModal, addAttachmentToSection, fileList }) => {


	useEffect(() => {

		//loadData();
	}, []);

	const loadData = async () => {


	}



	const selectedItem = (item) => {

		addAttachmentToSection(item);
	}


	return (
		<>
			<Modal isOpen={showUploadModal} centered style={{ maxWidth: 900 }}>
				<ModalHeader>Select Upload</ModalHeader>
				<ModalBody className="bg-light">
					<ul>
						{fileList.map((item, index) => {
							return (<div key={index}>
								<li><a href="#" onClick={() => selectedItem(item)} >{item.name}</a></li>
								<br /></div>)
						})}
						{/* {errorMessage && <Alert color='danger'>{errorMessage}</Alert>} */}
					</ul>

				</ModalBody>
				<ModalFooter>

					<Button size="sm" outline={true} className="pr-3 fs--1" color="danger"
						onClick={() => closeAttachmentModal()}>Cancel</Button>
				</ModalFooter>
			</Modal>


		</>
	);
};
export default UploadSelectModal;