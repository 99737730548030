import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitRegistration from 'components/authentication/split/Registration';
import SplitForgetPassword from 'components/authentication/split/ForgetPassword';
import SplitPasswordReset from 'components/authentication/split/PasswordReset';
import SplitConfirmMail from 'components/authentication/split/ConfirmMail';
import SplitLockScreen from 'components/authentication/split/LockScreen';


import AppContext from 'context/Context';

import Dashboard from 'app/dashboard';
import StudentModule from 'student/StudentModule';
import ModuleDashboard from 'module/ModuleDashboard';
import ModuleDetails from 'module/ModuleDetails';
import ModuleUsers from 'module/ModuleUsers';
import Users from 'users/Users';
import MediaMangement from 'media/MediaManagement';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route path="landing" element={<Landing />} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}




        {/*- ------------- Split ---------------------------  */}

        {/* <Route path="authentication/split/login" element={<SplitLogin />} /> */}
        <Route path="/" element={<SplitLogin />} />
        <Route path="/logout" element={<SplitLogout />} />
        <Route path="register" element={<SplitRegistration />} />
        <Route path="authentication/split/forgot-password" element={<SplitForgetPassword />} />
        <Route path="authentication/split/reset-password" element={<SplitPasswordReset />} />
        <Route path="authentication/split/confirm-mail" element={<SplitConfirmMail />} />
        <Route path="authentication/split/lock-screen" element={<SplitLockScreen />} />


        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          <Route path="/app/dashboard" element={<Dashboard />} />
          <Route path="/app/student-module" element={<StudentModule />} />
          <Route path="/app/lms-dashboard" element={<ModuleDashboard />} />
          <Route path="/app/module-users" element={<ModuleUsers />} />
          <Route path="/app/lms-details" element={<ModuleDetails />} />
          <Route path="/app/users" element={<Users />} />
          <Route path="/app/media" element={<MediaMangement />} />

        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      {/* <SettingsToggle /> */}
      {/* <SettingsPanel /> */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
