import { STUDENT as ActionTypes } from '../actionTypes';

const initialState = {
	module: {
		moduleTemplateId: 0,
		moduleStatus: '', // string interpolation
		moduleStatusId: 0,
		ceHours: 0,
		isRequired: false,
		siteCode: '',
		title: '',
		createdBy: '',
		dateCreated: '',
		dateDeleted: '',
		dateModified: '',
		modifiedBy: '',
		dateMustCompletedBy: '',
		dateScheduledForRelease: '',
		dateSiteCodeExpires: '',
		deletedBy: ''
	},
	userModule: {
		userModuleId: 0,
		moduleTemplateId: 0,
		status: '', // string interpolation
		statusId: 0,
		isRequired: false,
		appUserId: '',
		createdBy: '',
		dateCompleteBy: '',
		dateCompleted: '',
		dateCreated: '',
		dateDeleted: '',
		dateModified: '',
		dateStarted: '',
		deletedBy: '',
		modifiedBy: ''
	},
	//answers: [], // from template
	//questions: [], // from template
	sections: [], // from template
	//quizes: [], // from template
	userSections: [],
	userQuizes: [],
	userQuestions: [],
	selectedQuestion: {
		userQuestionId: 0,
		userQuizId: 0,
		questionTemplateId: 0,
		question: "",
		selectedAnswerId: null,
		questionOrder: 0
	},
	questionIndex: 0,
	maxQuestionIndex: 0
};


export const studentReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_MANIFEST:
			// load everything into our initial state
			// used when a student load a module		


			return {
				...state,
				module: action.payload.module,
				sections: action.payload.sections,
				//quizes: action.payload.quizes,
				//questions: action.payload.questions,
				//answers: action.payload.answers,
				userModule: action.payload.userModule,
				userSections: action.payload.userSections,
				userQuizes: action.payload.userQuizes
			};

		case ActionTypes.UPDATE_QUESTION_LIST:
			// we take the current selected question and update the question in the list


			return {
				...state
			};

		case ActionTypes.UPDATE_USER_SECTION:
			let userSectionList0 = [...state.userSections];
			let indexToUpdate0 = userSectionList0.findIndex(item => item.userSectionId === action.payload.userSectionId);

			userSectionList0[indexToUpdate0] = action.payload;

			return {
				...state,
				userSections: userSectionList0
			};
		case ActionTypes.UPDATE_VIDEO_PROGRESS:
			// find the section in the array of user sections and replace that item with the payload
			let userSectionList1 = [...state.userSections];
			let indexToUpdate = userSectionList1.findIndex(item => item.userSectionId === action.payload.userSectionId);

			userSectionList1[indexToUpdate] = action.payload;

			return {
				...state,
				userSections: userSectionList1
			};
		default:
			return state;
	};
}