import { LOCAL_STORAGE, ENDPOINTS, API_METHOD } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/resource';


export const getPdfList = () => {
    let url = `${apiRoot}/GetPdfList`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
}


export const getVideoList = (showAll) => {
    let url = `${apiRoot}/GetVideoList`;

    let payload = {
        getAll: showAll
    };

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };
    return handleFetch(url, options, true);
}


export const update = (item) => {
    let url = `${apiRoot}/Update`;

    let payload = {
        mediaId: item.mediaId,
        isActive: item.isActive,
        name: item.name,
        uri: item.uri
    };

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };
    return handleFetch(url, options, true);
}

export const insert = (item) => {
    let url = `${apiRoot}/Insert`;

    let payload = {
        name: item.name,
        uri: item.uri
    };

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };
    return handleFetch(url, options, true);
}