import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// components
import PageHeader from '../components/common/PageHeader';
import UserSearch from './components/users/_UserSearch';
import AssignedUsers from './components/users/_AssignedUsers';
import AvailableUsers from './components/users/_AvailableUsers';
import Loader from '../components/common/Loader';

// services
import { TAB_ITEMS } from '../services/appConstants';
import * as moduleUserActions from '../redux/actions/moduleUserActions';


const ModuleUsers = () => {
	let dispatch = useDispatch();
	let navigate = useNavigate();
	const { module } = useSelector(state => state.moduleReducer);
	const { tabView, filteredAssignedUsers, filteredAvailableUsers, assignedUsers } = useSelector(state => state.moduleUserReducer);

	const [selectedUserId, setSelectedUserId] = useState('');
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		if (module.moduleTemplateId === 0) {
			navigate('/module-dashboard');
		}
	}, []);

	const updateTabView = (e) => {
		dispatch(moduleUserActions.updateTabView(e));
		dispatch(moduleUserActions.resetSearchFilters(filteredAvailableUsers, filteredAssignedUsers));
		dispatch(moduleUserActions.setInitAvailableUsers(module.moduleTemplateId));
		dispatch(moduleUserActions.setInitAssignedUsers(module.moduleTemplateId));
	}


	const updateCoreLists = (itemToAttach, addToListName) => {

		setShowLoader(true);
		if (addToListName === 'attached') {
			// means we are adding from available to attached

			// add to attached list first
			dispatch(moduleUserActions.assignUserToModule(itemToAttach))
				.then(() => {
					reloadLists();
				});

		} else {
			// means we are adding from attached to available		
			// remove from attached list
			dispatch(moduleUserActions.removeAssignedUserToModule(itemToAttach))
				.then(() => {
					reloadLists();
				});
		}
	}

	const reloadLists = () => {
		dispatch(moduleUserActions.setInitAvailableUsers(module.moduleTemplateId))
			.then(() => {
				dispatch(moduleUserActions.setInitAssignedUsers(module.moduleTemplateId))
					.then(() => {
						setShowLoader(false);
					});
			});
	}




	return (
		<>
			<PageHeader className="mb-3" title="">
				<Row className="align-items-center">
					<Col className="col-5">
						<h3>Module User Management</h3>
					</Col>
					<Col className="col-7 text-right">
						<Row className="align-items-right">
							<Col className="col-12 text-right">
								<Button size="sm" outline={true} className="pr-3 fs--1" color="info" onClick={() => history.push('/module-dashboard')}><FontAwesomeIcon icon="chevron-left" className="ml-1 fs--2" /> Back To Dashboard</Button>
							</Col>
						</Row>
					</Col>
				</Row>
				<Label>Below is a list of users that have been assigned to this module.  Click on the "Search Users" button below to assign users to this module.</Label>
			</PageHeader>
			<Row >
				<Col className="col-12">
					<Card className="mb-3">
						<CardBody>
							<Row className="align-items-center mt-2">
								<Col className="col-12">
									<UserSearch />

								</Col>
							</Row>
							<Row style={{ marginBottom: 30, marginTop: 15 }}>
								<Col className={`col-6 border-bottom ${tabView === TAB_ITEMS.ASSIGNED ? "border-info" : "border-light"} border-2x`} style={{ cursor: 'pointer' }} onClick={() => updateTabView(TAB_ITEMS.ASSIGNED)}>
									<table style={{ height: 55 }}>
										<tbody>
											<tr>
												<td className="align-bottom"><h5 className={`p-1 ${tabView === TAB_ITEMS.ASSIGNED ? `text-info` : ``} `} >
													Assigned Users ({assignedUsers.length})</h5></td>

											</tr>
										</tbody>
									</table>
								</Col>
								<Col className={`col-6 border-bottom ${tabView === TAB_ITEMS.AVAILABLE ? "border-info" : "border-light"} border-2x`} style={{ cursor: 'pointer' }} onClick={() => updateTabView(TAB_ITEMS.AVAILABLE)}>
									<table style={{ height: 55 }}>
										<tbody>
											<tr>
												<td className="align-bottom"><h5 className={`p-1 ${tabView === TAB_ITEMS.AVAILABLE ? `text-info` : ``} `} >
													Select From Available Users</h5></td>
											</tr>
										</tbody>
									</table>
								</Col>

							</Row>
							{showLoader === true &&
								<Row className="align-items-center mt-2 mb-2">
									<Col className="col-12">
										<Loader />
									</Col>
								</Row>}

							{showLoader === false && <Row className="align-items-center mt-2 mb-2">
								<Col className="col-12">
									{(tabView === TAB_ITEMS.ASSIGNED) ?
										(<AssignedUsers onAction={updateCoreLists} />) :
										(<AvailableUsers onAction={updateCoreLists} />)}
								</Col>
							</Row>}

						</CardBody>

					</Card>



				</Col>
			</Row>

		</>
	);
};

export default ModuleUsers;