import { QUESTIONS as ActionTypes } from '../actionTypes';
import * as questionApi from '../../services/questionApi';



export function setItems(sectionTemplateId) {
	return async (dispatch) => {
		let response = await questionApi.getAll(sectionTemplateId);

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_QUESTIONS,
				payload: payload
			}
		)
	}
}

export function setSelectedItem(item) {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_SELECTED_QUESTION,
				payload: item
			}
		)
	}
}

export function clearQuestions() {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_SELECTED_QUESTION,
				payload: []
			}
		)
	}
}


export function showAnswers(item, shouldWeShow) {
	return async (dispatch) => {
		let payload = {
			showAnswers: shouldWeShow,
			question: item
		};

		dispatch(
			{
				type: ActionTypes.SHOW_ANSWERS,
				payload: payload
			}
		)
	}
}

export function setAsCorrectAnswer(questionTemplateId, answerTemplateId) {
	return async (dispatch) => {
		await questionApi.setAsCorrectAnswer(questionTemplateId, answerTemplateId);

		return;
	}
}

export function updateItem(item) {
	return async (dispatch) => {

		let response = await questionApi.update(item);

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.result;
		}

		dispatch(
			{
				type: ActionTypes.UPDATE_QUESTION,
				payload: payload
			}
		)
	}
}

export function updateOrder(sectionTemplateId, allQuestions, questionToMove, direction) {
	return async (dispatch) => {

		let newTemp = allQuestions;

		// get the current index of the row
		let indexOfItemToRemove = allQuestions.findIndex((c) => c.questionTemplateId === questionToMove.questionTemplateId);

		// get the index of where we are going to place the new row		
		let newIndex = (direction === 'down') ? indexOfItemToRemove + 1 : indexOfItemToRemove - 1;

		// remove the item from the array using its current index
		newTemp.splice(indexOfItemToRemove, 1);

		// place the row into its new home using the newIndex position
		newTemp.splice(newIndex, 0, questionToMove);

		let newList = newTemp.map((questionToMove, index) => {
			questionToMove.order = index;
			return questionToMove.questionTemplateId;
		});

		await questionApi.updateOrder({ newOrderList: newList });


		// get me a new list with the desired order
		let response = await questionApi.getAll(sectionTemplateId);

		let payload = {};
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_QUESTIONS,
				payload: payload
			}
		)
	}
}

