import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/module';

export const getAll = () => {
	let url = `${apiRoot}/GetAll`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const getSingle = (id) => {

	let url = `${apiRoot}/GetSingle/${id}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const update = (data) => {
	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const remove = (moduleTemplateId) => {

	let url = `${apiRoot}/Delete/${moduleTemplateId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}