import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import {
	Button, CardBody, Row, Col, CustomInput,
	Modal, ModalHeader, ModalBody, ModalFooter,
	Label, Badge, Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// components

// services
import * as moduleUserActions from '../../../redux/actions/moduleUserActions';
import { TAB_ITEMS } from '../../../services/appConstants';

const UserSearch = () => {
	let dispatch = useDispatch();
	const { searchText, searchFilters, availableUsers, assignedUsers, tabView } = useSelector(state => state.moduleUserReducer);

	const treeRef = useRef();


	const [filterMessage, setFilterMessage] = useState('');
	const [localSearchText, setLocalSearchText] = useState(searchText);


	const clearFilter = () => {
		setLocalSearchText('');
		dispatch(moduleUserActions.resetSearchFilters(availableUsers, assignedUsers));
	}

	const filterResults = (e) => {
		e.preventDefault();
		if (!localSearchText) {
			setFilterMessage('Oops! Enter name');
			treeRef.current.focus();
			return;
		}
		setFilterMessage('');

		switch (tabView) {
			case TAB_ITEMS.AVAILABLE:
				dispatch(moduleUserActions.textSearchMasterListAvailable(localSearchText, availableUsers))
					.then((resp) => {

						if (resp) {
							// if we have an error, 
							setFilterMessage(resp);
						}
					});
				break;
			case TAB_ITEMS.ASSIGNED:
				dispatch(moduleUserActions.textSearchMasterListAssigned(localSearchText, assignedUsers))
					.then((resp) => {

						if (resp) {
							// if we have an error, 
							setFilterMessage(resp);
						}
					});
				break;
		}


	}

	const filterToggle = (d) => {
		let localObject = {};

		switch (d.id) {
			case "isStudent":
				localObject = { ...searchFilters, 'isStudent': d.checked };
				break;
			case "isInstructor":
				localObject = { ...searchFilters, 'isInstructor': d.checked };
				break;
			case "isAdmin":
				localObject = { ...searchFilters, 'isAdmin': d.checked };
				break;
			case "isAti":
				localObject = { ...searchFilters, 'isAti': d.checked };
				break;
			case "isTrace":
				localObject = { ...searchFilters, 'isTrace': d.checked };
				break;
			case "isVandy":
				localObject = { ...searchFilters, 'isVandy': d.checked };
				break;
			default:
				return searchFilters;
		}
		dispatch(moduleUserActions.updateSearchFilters(localObject))
			.then(() => {
				switch (tabView) {
					case TAB_ITEMS.AVAILABLE:
						dispatch(moduleUserActions.filterAvailableUsers(localObject, availableUsers));
						break;
					case TAB_ITEMS.ASSIGNED:
						dispatch(moduleUserActions.filterAssignedUsers(localObject, assignedUsers));
						break;
				}
			});
	}

	return (
		<>
			<Row className="align-items-center">
				<Col className="col-12">
					<h3>Search Entire Roll</h3>
				</Col>
				<Col className="col-12">
					<Row className="align-items-right">
						<Col className="col-7 text-left">
							<Input
								name='search'
								innerRef={treeRef}
								type='text'
								autoComplete='nofill'
								placeholder='Search user by name.....'
								value={localSearchText}
								onChange={item => setLocalSearchText(item.target.value)}
							/>
						</Col>
						<Col className="col-5 text-center">
							<div>

								<Button color="info" size="lg" className="pr-4 fs--1 me-2"
									onClick={filterResults}>
									<FontAwesomeIcon icon="search" className="ml-1 fs--6" /> Search User
								</Button>

								<Button color="info" size="lg" className="pr-4 fs--1 me-2"
									onClick={clearFilter}>
									<FontAwesomeIcon icon="trash" className="ml-1 fs--6" /> Clear Search
								</Button>
							</div>
						</Col>
					</Row>
					<Row className="align-items-center mt-3">
						<Col className="col-12">{filterMessage && <Badge color='danger' style={{ marginRight: 8 }}>{filterMessage}</Badge>}</Col>
					</Row>
					<Row className="align-items-center mt-3">
						<Col className="col-6">
							<small style={{ fontWeight: 'bold' }}>show me all.....</small><br />
							<div className="form-check form-check-inline">
								<input className="form-check-input"
									id="isStudent"
									type="checkbox"
									//value="option1"
									onChange={({ target }) => filterToggle(target)}
									checked={searchFilters.isStudent}
								/>
								<label className="form-check-label">Students</label>
							</div>
							<div className="form-check form-check-inline">
								<input className="form-check-input"
									id="isInstructor"
									type="checkbox"
									//value="option1"
									onChange={({ target }) => filterToggle(target)}
									checked={searchFilters.isInstructor}
								/>
								<label className="form-check-label">Instructors</label>
							</div>
							<div className="form-check form-check-inline">
								<input className="form-check-input"
									id="isAdmin"
									type="checkbox"
									//value="option1"
									onChange={({ target }) => filterToggle(target)}
									checked={searchFilters.isAdmin}
								/>
								<label className="form-check-label">Admins</label>
							</div>

						</Col>
						<Col className="col-6 text-right">
							<small style={{ fontWeight: 'bold' }}>filter by.....</small><br />
							<div className="form-check form-check-inline">
								<input className="form-check-input"
									id="isVandy"
									type="checkbox"
									onChange={({ target }) => filterToggle(target)}
									checked={searchFilters.isVandy}
								/>
								<label className="form-check-label">Vandenberg</label>
							</div>
							<div className="form-check form-check-inline">
								<input className="form-check-input"
									id="isAti"
									type="checkbox"
									onChange={({ target }) => filterToggle(target)}
									checked={searchFilters.isAti}
								/>
								<label className="form-check-label" >ATI</label>
							</div>
							<div className="form-check form-check-inline">
								<input className="form-check-input"
									id="isTrace"
									type="checkbox"
									onChange={({ target }) => filterToggle(target)}
									checked={searchFilters.isTrace}
								/>
								<label className="form-check-label" >Trace</label>
							</div>

						</Col>
					</Row>
				</Col>

			</Row>
		</>
	);
};
export default UserSearch;