import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/answer';

export const getAll = (questionId) => {
	let url = `${apiRoot}/GetAllByQuestionId/${questionId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const updateOrder = (data) => {
	let url = `${apiRoot}/UpdateOrder`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const update = (data) => {
	let url = `${apiRoot}/Update`;

	let payload = {
		"answerTemplateId": data.answerTemplateId,
		"questionTemplateId": data.questionTemplateId,
		"answer": data.answer,
		"isCorrect": data.isCorrect,
		"order": data.order
	};


	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};
	return handleFetch(url, options, true);
}

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const remove = (questionTemplateId, answerTemplateId) => {
	let url = `${apiRoot}/Delete/${questionTemplateId}/${answerTemplateId}`;

	const options = {
		method: API_METHOD.GET,
		//body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}