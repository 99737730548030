import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/question';

export const getAll = (quizId) => {
	let url = `${apiRoot}/GetAllBySectionId/${quizId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}


export const setAsCorrectAnswer = (questionTempalteId, answerTemplateId) => {
	let url = `${apiRoot}/SetCorrectAnswer/${questionTempalteId}/${answerTemplateId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const updateOrder = (data) => {
	let url = `${apiRoot}/UpdateOrder`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const update = (data) => {
	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const remove = (sectionTempalteId, questionTemplateId) => {
	let url = `${apiRoot}/Delete/${sectionTempalteId}/${questionTemplateId}`;

	const options = {
		method: API_METHOD.GET,
	};
	return handleFetch(url, options, true);
}