import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, Row, Col, CardHeader } from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

// services
import { USER_MODULE_STATUS_VALUES, LOCAL_STORAGE } from '../services/appConstants';
import * as userModuleApi from '../services/userModuleApi';
import * as studentActions from '../redux/actions/studentActions';

const Dashboard = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [moduleData, setModuleData] = useState([]);

    useEffect(() => {
        getMyModules();
    }, []);

    const getMyModules = async () => {
        setShowLoader(true);

        let resp = await userModuleApi.getMyModules();

        if (resp.data.totalResults != 0) {
            setModuleData(resp.data.results);
        }

        setShowLoader(false);
    }

    const buttonActionItem = (row, navTo) => {
        setShowLoader(true);
        //save module items into local storage 
        let myRequest = {
            userModuleId: row.userModuleId,
            moduleTemplateId: row.moduleTemplateId,
            appUserId: row.appUserId,
            statusId: row.statusId
        };
        localStorage.setItem(LOCAL_STORAGE.STUDENT_MODULE, JSON.stringify(myRequest));

        dispatch(studentActions.setModuleManifest(myRequest))
            .then((resp) => {
                // don't do shit until we save into redux
                navigate('/app/student-module');
            });
    }

    const actionFormatter = (rowData) => {
        const { statusId } = rowData.row.original;

        let action = 'Load';

        if (statusId !== USER_MODULE_STATUS_VALUES.EXPIRED) {

            return (
                <>
                    <Button size="sm" outline={true} className=" fs--1 " color="info" onClick={() => buttonActionItem(rowData.row.original, 'StudentModule')}>
                        {action}
                    </Button>
                </>
            );
        }
    };

    const badgeFormatter = (rowData) => {
        let color = '';
        let icon = '';
        let text = '';

        const { statusId } = rowData.row.original;

        switch (statusId) {
            case USER_MODULE_STATUS_VALUES.NEW:
                color = 'secondary';
                icon = 'edit';
                text = 'New';
                break;
            case USER_MODULE_STATUS_VALUES.IN_PROGRESS:
                color = 'primary';
                icon = 'stream';
                text = 'In Progress';
                break;
            case USER_MODULE_STATUS_VALUES.COMPLETED:
                color = 'success';
                icon = 'user-graduate';
                text = 'Completed';
                break;
            case USER_MODULE_STATUS_VALUES.FAILED:
                color = 'danger';
                icon = 'user-times';
                text = 'Failed';
                break;
            case USER_MODULE_STATUS_VALUES.EXPIRED:
                color = 'danger';
                icon = 'calendar-times';
                text = 'Expired';
                break;
        }


        return (
            <SoftBadge pill bg={color} className="fs--2">
                {text}

            </SoftBadge>

        );
    };


    const columns = [
        {
            accessor: 'moduleTemplateId',
            Header: 'Module ID',
            disableSortBy: false,
            headerProps: { style: { width: '200px', textAlign: 'center', fontWeight: 'bold' } },
            cellProps: { className: 'py-2 align-middle text-center' },
        },
        {
            accessor: 'module.title',
            Header: 'Module Name',
            disableSortBy: false,
            headerProps: { style: { width: '50%', textAlign: 'left', fontWeight: 'bold' } },
            cellProps: { className: 'pl-2 py-2 align-middle' },
        },
        {
            accessor: 'statusId',
            Header: 'Status',
            disableSortBy: false,
            headerProps: { style: { fontWeight: 'bold' } },
            cellProps: { className: 'pl-2 py-2 align-middle' },
            Cell: badgeFormatter
        },
        {
            accessor: 'action',
            Header: 'Actions',
            disableSortBy: true,
            headerProps: { style: { width: '15%', fontWeight: 'bold', textAlign: "center" } },
            cellProps: { className: 'align-middle text-center' },
            Cell: actionFormatter
        }
    ];

    return (
        <>
            <PageHeader title="My Dashboard"
                description="Future location of graphs and vital app info including training modules and updates." className="mb-3" />
            <Row >
                <Col className="col-12">
                    <Card className="mb-3">
                        <CardHeader className="align-content-right">
                            <Row>
                                <Col className="col-6">
                                    <h5>Module List</h5>
                                </Col>
                                <Col className="col-6 text-right">
                                    <Row className="align-items-right">
                                        <Col className="col-12 text-right">
                                            {/* <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={launchNewModuleModal}>New Module</Button> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>

                            {moduleData && <AdvanceTableWrapper
                                columns={columns}
                                data={moduleData}
                                //selection
                                sortable
                                pagination
                                perPage={10}
                            >

                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs--1 mb-0 overflow-hidden'
                                    }}
                                />
                            </AdvanceTableWrapper>}


                            {/* {showLoader === true ?
                                (<Loader />) :
                                (<Scrollbar style={{ height: 500 }}>
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="moduleTemplateId"
                                            data={moduleData}
                                            columns={columns}
                                            bordered={false}
                                            classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                                            rowClasses="btn-reveal-trigger"
                                            headerClasses="bg-200 text-900"
                                        />
                                    </div>
                                </Scrollbar>)} */}
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Dashboard;