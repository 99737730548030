import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Label, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';


// components

// services
import * as moduleUserActions from '../../../redux/actions/moduleUserActions';
import { USER_MODULE_STATUS_VALUES } from '../../../services/appConstants';

const AvailableUsers = ({ onAction }) => {
	let dispatch = useDispatch();
	const { filteredAvailableUsers } = useSelector(state => state.moduleUserReducer);

	const nameFormatter = (cell, row) => (
		<>
			{row.firstName} {row.lastName}
		</>
	);

	const providerFormatter = (cell, row) => {
		//let provider = utils.getProviderName(row.providerId).label;
		return (
			<p className="mb-0 text-500">{row.provider}</p>
		)
	};

	const actionFormatter = (cell, item) => {

		let temp = item.statusId;
		if (temp !== USER_MODULE_STATUS_VALUES.NEW) {
			// the user has interacted with the module at some point
			return (
				<div style={{ textAlign: 'right' }}>
					<Badge color={`soft-danger`} className="rounded-capsule fs--1">Locked!
						<FontAwesomeIcon icon={'user-lock'} transform="shrink-2" className="ml-1 fs--4" style={{ width: 25 }} />
					</Badge>
				</div>
			);
		}
		return (
			<div style={{ textAlign: 'right' }}>
				<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="secondary" onClick={() => onAdd(item)}>
					<FontAwesomeIcon icon='user-plus' className="ml-1 fs--4" style={{ width: 25 }} />
				</Button>
			</div>
		);
	};

	const showCheckFormatter = ((cell) => {
		if (cell === true) {
			return <Badge color={`soft-success`} className="rounded-capsule fs--1 d-block"><FontAwesomeIcon icon='check' className="ml-1 fs--4" color={'green'} /></Badge>
		}
	});

	const onAdd = (item) => {

		onAction(item, 'attached');
	}

	const moduleActionAll = async () => {
		//setShowLoader(true);
		for (let i = 0; i < filteredAvailableUsers.length; i++) {
			let singleItem = filteredAvailableUsers[i];

			onAction(singleItem, 'attached');
		}
	}

	const columns = [
		{
			dataField: 'firstName',
			text: 'Name',
			classes: 'py-2 align-middle',
			formatter: nameFormatter,
			sort: true
		},
		{
			dataField: 'email',
			text: 'Email',
			classes: 'py-2 align-middle',
			sort: true
		},
		{
			dataField: 'provider',
			text: 'Provider',
			classes: 'py-2 align-middle',
			sort: true,
			formatter: providerFormatter
		},
		{
			dataField: 'isAdmin',
			text: 'Admin',
			classes: 'py-2 align-middle text-center',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { textAlign: 'center' };
			},
			formatter: (item) => showCheckFormatter(item)
		},
		{
			dataField: 'isInstructor',
			text: 'Instructor',
			classes: 'py-2 align-middle text-center',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { textAlign: 'center' };
			},
			formatter: (item) => showCheckFormatter(item)
		},
		{
			dataField: 'isStudent',
			text: 'Student',
			classes: 'py-2 align-middle text-center',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { textAlign: 'center' };
			},
			formatter: (item) => showCheckFormatter(item)
		},
		{
			dataField: 'action',
			classes: 'border-0 align-middle text-right',
			headerClasses: 'border-0',
			text: '',
			formatter: actionFormatter
		}
	];

	return (
		<>
			<Row className="align-items-center">
				<Col className="col-12">
					<h5>Available Users List</h5>
				</Col>
				<Col className="col-12 text-right mr-6 py-2">
					<Label className="mr-2">Add All To Module</Label>
					<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={() => moduleActionAll()}>
						<FontAwesomeIcon icon='user-graduate' className="ml-1 fs--4" style={{ width: 25 }} />
					</Button>

				</Col>
				<Col className="col-12">
					<div className="table-responsive">
						<BootstrapTable
							bootstrap4
							keyField="appUserId"
							data={filteredAvailableUsers}
							columns={columns}
							bordered={false}
							classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
							rowClasses="btn-reveal-trigger"
							headerClasses="bg-200 text-900"
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};
export default AvailableUsers;