import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button, Row, Col, FormGroup, Label, Alert, Input, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// components
import Question from './_Question';
import Loader from '../../components/common/Loader';

// services
import { LOCAL_STORAGE } from '../../services/appConstants'
import * as studentActions from '../../redux/actions/studentActions';
import * as userQuizActions from '../../redux/actions/userQuizActions';

const QuizModal = ({ showModal, section, userSection, onCancelQuiz }) => {
	let dispatch = useDispatch();
	const { selectedQuestion, userQuestions } = useSelector(state => state.userQuizReducer);

	const [errorMessage, setErrorMessage] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const [agreeToQuizTerms, setAgreeToQuizTerms] = useState(false);
	const [disablePrevButton, setDisablePrevButton] = useState(false);
	const [disableNextButton, setDisableNextButton] = useState(false);
	const [disableFinishButton, setDisableFinishButton] = useState(false);
	const [showFinishButton, setShowFinishButton] = useState(false);
	const [showFailWarning, setShowFailWarning] = useState(false);

	useEffect(() => {
		// build the deck for the quiz
		if (agreeToQuizTerms === true) {

			startQuiz();
		}

	}, [agreeToQuizTerms]);


	useEffect(() => {
		// check what we have to do to the buttons
		let indexOfCurrentQuestion = userQuestions.findIndex(item => item.userQuestionId === selectedQuestion.userQuestionId);

		if (indexOfCurrentQuestion !== -1) {
			let currentQuestion = userQuestions[indexOfCurrentQuestion];


			if (indexOfCurrentQuestion === 0) {
				// we are at the first question, hide the previous button
				setDisablePrevButton(true);
			} else {
				setDisablePrevButton(false);
			}

			if (indexOfCurrentQuestion === (userQuestions.length - 1)) {
				setShowFinishButton(true)
				currentQuestion.selectedAnswerId === null ? setDisableFinishButton(true) : setDisableFinishButton(false)

			} else {
				// hide the finish button, we are not done yet
				setShowFinishButton(false);

				// don't allow user to move to next question unless they have answered current question
				// they can go back but not forward
				currentQuestion.selectedAnswerId === null ? setDisableNextButton(true) : setDisableNextButton(false)
			}
		}

	}, [selectedQuestion]);

	const startQuiz = () => {

		setShowLoader(true);
		let payload = {
			userSectionId: userSection.userSectionId,
			sectionTemplateId: section.sectionTemplateId
		}

		dispatch(userQuizActions.startQuiz(payload))
			.then(resp => {
				setShowLoader(false);
			})

		// log quiz attempt -  this property is not part of the userSection so we
		// are going to piggy back it onto it from the section template
		// userSection.attemptLimit = section.numberOfAttemptsAllowed;
		// debugger;
		dispatch(studentActions.logQuizAttempt(userSection));
	}

	const buttonAction = (action) => {
		let params = JSON.parse(localStorage.getItem(LOCAL_STORAGE.STUDENT_MODULE));
		// grade current question
		switch (action) {
			case "next":
				let indexOfNextItem = userQuestions.findIndex(item => item.questionOrder === (selectedQuestion.questionOrder + 1))
				dispatch(userQuizActions.setSelectedQuestion(userQuestions[indexOfNextItem]));

				break;

			case "back":
				let indexOfPevItem = userQuestions.findIndex(item => item.questionOrder === (selectedQuestion.questionOrder - 1))
				dispatch(userQuizActions.setSelectedQuestion(userQuestions[indexOfPevItem]));
				break;

			case "complete":

				dispatch(userQuizActions.gradeQuiz(selectedQuestion.userQuizId, userSection.userSectionId, userQuestions, section.numberOfAttemptsAllowed))
					.then(() => {
						dispatch(userQuizActions.setEmptyQuestion());
						dispatch(studentActions.setModuleManifest(params));
						closeQuiz();
					});
				break;
			case "cancel":
				dispatch(userQuizActions.gradeQuiz(selectedQuestion.userQuizId, userSection.userSectionId, userQuestions, section.numberOfAttemptsAllowed))
					.then(() => {
						dispatch(userQuizActions.setEmptyQuestion());
						dispatch(studentActions.setModuleManifest(params));

						closeQuiz();
					});

				break;
		}
	}

	const closeQuiz = () => {
		setAgreeToQuizTerms(false);
		setShowFailWarning(false);

		onCancelQuiz();
	}

	return (
		<>
			<Modal isOpen={showModal} centered style={{ maxWidth: 1000 }}>
				<Row style={{ marginVertical: 5, height: 45 }}>
					<Col className="col-6">
						<div className="ml-1 mt-1 p-2">
							<h5>{showFailWarning === true ? "Stop, and read carefully!" : "Quiz Attempt"}</h5>
						</div>
					</Col>
					<Col className="col-6 text-right">
						{showFailWarning === false && <div style={{ alignItems: 'left', paddingTop: 10 }}>

							{selectedQuestion.questionTemplateId === 0 ? (
								<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={closeQuiz}>
									<FontAwesomeIcon icon="times" className="mr-1 fs--4" /> Close</Button>
							) : (<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={() => setShowFailWarning(true)}>
								<FontAwesomeIcon icon="times" className="mr-1 fs--4" /> Cancel Quiz</Button>)}
						</div>}
					</Col>
				</Row>
				<ModalBody className="bg-light">
					{errorMessage === true && <Alert color='danger'>{errorMessage}</Alert>}
					{showLoader === true && <Loader ></Loader>}
					{showFailWarning === true ? (<Alert color="danger">WARNING! This quiz will be marked as failed if you cancel now.  Are you sure you want to cancel?</Alert>) : (
						<>
							{(agreeToQuizTerms === false) ? (<>
								<Row>
									<Col className="col-12">
										<p>Please review the terms of the quiz below carefully.  Not complying with the requirements will result in a failed attempt.</p>
										<ul>
											<li>You must complete the quiz in its entirety</li>
											<li>If you exit the quiz before completing, it will be recorded as a failed attempt</li>
											<li>Once complete, click the "Grade Quiz" button at the end of the quiz</li>
											<li>If you think you will not have enough time to complete the quiz, DO NOT ATTEMPT the quiz</li>
											<li>Wait until you have sufficient time to complete the quiz</li>
											<li>If you need more time to review, DO NOT ATTEMPT the quiz.  Go back and review the materials provided</li>
										</ul>
										<p>If you feel you are ready to take the quiz then click on the button below to begin your quiz.  Good luck!
										</p>
									</Col>
								</Row>
							</>) : (
								<Row>
									<Col className="col-12">
										<Question />
									</Col>
								</Row>
							)}
						</>
					)}
				</ModalBody>
				<ModalFooter>
					{showFailWarning === true ? (<><Button size="sm" outline={false} className="pr-3 fs--1 mr-2" color="danger" onClick={() => buttonAction('cancel')}>Yes, Cancel Quiz</Button>
						<Button size="sm" outline={false} className="pr-3 fs--1 mr-2" color="success" onClick={() => setShowFailWarning(false)}>No, Continue With The Quiz</Button></>) : (
						agreeToQuizTerms === false ? (<>
							<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={() => setAgreeToQuizTerms(true)}>Start Quiz</Button>
						</>) : (
							<>
								<Button size="sm" outline={true} disabled={disablePrevButton} className="pr-3 fs--1 mr-2" color="primary" onClick={() => buttonAction('back')}>Back</Button>

								{showFinishButton === false ? (
									<Button size="sm" outline={true} disabled={disableNextButton} className="pr-3 fs--1" color="primary" onClick={() => buttonAction('next')}>Next</Button>
								) : (
									<Button size="sm" outline={true} disabled={disableFinishButton} className="pr-3 fs--1" color="primary" onClick={() => buttonAction('complete')}>Finish</Button>
								)}
							</>
						)
					)}


				</ModalFooter>
			</Modal>
		</>
	);
};
export default QuizModal;