import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button, Card, CardBody, Row, Col, CardHeader,
	Modal, ModalHeader, ModalBody, ModalFooter, FormGroup,
	Label
} from 'reactstrap';

// components
import SectionDataTable from './_SectionDataTable';
import SectionFormModal from './_SectionFormModal';
import Questions from './_Questions';
import Answers from './_Answers';
import UploadVideoModal from './_UploadVideoModal';
import Loader from '../../components/common/Loader';

// services
import { SECTION_TYPEID } from '../../services/appConstants'
import * as sectionActions from '../../redux/actions/sectionActions';
import * as questionActions from '../../redux/actions/questionActions';
import * as resourceApi from '../../services/resourceApi';
import * as sectionApi from '../../services/sectionApi';
import UploadSelectModal from './_UploadSelectModal';

const SectionMaster = ({ parentModuleTemplateId }) => {

	let dispatch = useDispatch();
	const { section } = useSelector(state => state.sectionReducer);
	const { showAnswers } = useSelector(state => state.questionReducer);

	const fileInputRefSol = useRef(null)
	const [showAttachmentModal, setShowAttachmentModal] = useState(false);
	const [showVideoModal, setShowVideoModal] = useState(false);
	const [showSectionDetails, setShowSectionDetails] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [fileToUpload, setFileToUpload] = useState({});
	const [showLoader, setShowLoader] = useState(false);
	const [videoList, setVideoList] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [showEditFromModal, setShowEditFormModal] = useState(false); // display section form modal

	const onSelectedItem = (row) => {
		dispatch(sectionActions.setSelectedItem(row))
			.then(() => {
				setShowSectionDetails(true);
			});
	}

	const launchUploadPdfModal = async () => {

		let fileType = '';
		switch (section.sectionTypeId) {
			case SECTION_TYPEID.PDF:
				// pdf						

				resourceApi.getPdfList(fileType)
					.then((resp) => {
						setFileList(resp.data.results);
						setShowAttachmentModal(true);
					});

				break;

			case SECTION_TYPEID.VIDEO:


				resourceApi.getVideoList(false)
					.then((resp) => {
						setFileList(resp.data.results);
						setShowAttachmentModal(true);
					});

				break;
		}

	}

	const uploadPdf = async () => {
		if (fileToUpload.name) {
			setShowLoader(true);

			dispatch(sectionActions.uploadFile(fileToUpload, section))
				.then(resp => {
					setShowLoader(false);
					setShowUploadModal(false);
				})

		}
	}

	const openFileDialogSol = () => {
		fileInputRefSol.current.click();
	}

	const handleChange = (event) => {
		setShowLoader(true);
		let file = event.target.files[0];

		setFileToUpload(file);
		setShowLoader(false);
	}

	const deletePdf = () => {
		setShowLoader(true);
		dispatch(sectionActions.deleteFile(section));
		setShowUploadModal(false);
		setErrorMessage('');
		setFileToUpload({});
		setShowLoader(false);
	}

	// const openInNewTab = (url) => {

	// 	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	// 	if (newWindow) newWindow.opener = null
	// }

	const onDelete = async () => {

		await sectionApi.remove(parentModuleTemplateId, section.sectionTemplateId)
			.then((response) => {

				if (response.data.success === true) {
					// record was removed

					dispatch(sectionActions.setItems(parentModuleTemplateId))
						.then(() => {
							setErrorMessage('');
							setShowSectionDetails(false);
							setShowDeleteModal(false);
						});
				}
			});
	}

	const launchDeleteModal = () => {
		setShowDeleteModal(true);
	}

	const launchModal = () => {
		// show the section form modal
		// set selected section in redux
		setShowEditFormModal(true);
	}

	const cancelSectionEdit = () => {
		// just close the edit modal
		setShowEditFormModal(false);
	}

	const updateSection = (itemToUpdate) => {

		// update redux and clear the section object
		dispatch(sectionActions.updateItem(itemToUpdate))
			.then(() => {
				setShowEditFormModal(false);
			});
	}

	const addAttachmentToSection = (file) => {
		updateSection({ ...section, fileName: file.name, url: file.uri });
		setShowAttachmentModal(false);
	}

	const closeAttachmentModal = () => {
		setShowAttachmentModal(false);
	}
	const cancelVideoModal = () => {
		setShowVideoModal(false);
	}

	const backToSections = () => {
		//dispatch(questionActions.clearQuestions());
		setShowSectionDetails(false);
	}

	return (
		<>
			<UploadSelectModal showUploadModal={showAttachmentModal}
				closeAttachmentModal={closeAttachmentModal}
				addAttachmentToSection={addAttachmentToSection}
				fileList={fileList}
			/>
			{/* <UploadPdfModal
				showModal={showPdfModal}
				cancelItem={cancelPdfModal}
				sectionSubmit={updateSection}
			/> */}
			<UploadVideoModal
				showModal={showVideoModal}
				cancelItem={cancelVideoModal}
				videoList={videoList}
				sectionSubmit={updateSection}
			/>

			<SectionFormModal
				showModal={showEditFromModal}
				cancelItem={cancelSectionEdit}
				sectionSubmit={updateSection}
			/>

			<Modal isOpen={showDeleteModal} centered style={{ maxWidth: 600 }}>
				<ModalHeader>Delete Section</ModalHeader><br />
				<ModalBody className="bg-light">
					<Row>
						<Col xl={12}>
							<Label className="text-danger">Delete section?</Label>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button size="sm" outline={true} className="pr-3 fs--1 m3-2" color="success" onClick={onDelete}>Delete</Button>
					<Button size="sm" outline={true} className="pr-3 fs--1 m3-2" color="danger" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
				</ModalFooter>
			</Modal>



			{/* <Modal isOpen={showUploadModal} centered style={{ maxWidth: 900 }}>
				<ModalHeader>Upload Media</ModalHeader><br />
				<ModalBody className="bg-light">
					<Row>
						<Col xl={12}>
							{showLoader == true && <Loader />}
							<FormGroup>
								<Label className="font-weight-bold">Media</Label><br />
								{(section.url && section.sectionTypeId !== SECTION_TYPEID.QUIZ) && <>
									<Row>
										<Col xl={12}>
											<a href={section.url} target='_blank'><Label>{section.fileName}</Label></a>
										</Col>
									</Row>
								</>}

								{(!section.url) && <>
									<Label>Click here <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={openFileDialogSol}>
										<FontAwesomeIcon icon="file-upload" className="ml-1 fs--4" /> Upload/Replace File
										<input type="file" style={{ display: 'none' }} onChange={(event) => handleChange(event)} ref={fileInputRefSol} />
									</Button> to select media to upload.</Label>
									{(fileToUpload && fileToUpload.name) && <>
										<br />
										<Label>Media to upload: <Label className="font-weight-bold">{fileToUpload.name}</Label></Label>
									</>}
								</>}
								{(section.sectionTypeId === SECTION_TYPEID.QUIZ) && <>
									<Label className="text-danger">Cannot upload media for a quiz.</Label>
								</>}
							</FormGroup>
						</Col>

					</Row>

				</ModalBody>
				<ModalFooter>

					{(section.url) && <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={deletePdf}>
						<FontAwesomeIcon icon="trash" className="ml-1 fs--4" /> Remove Media
					</Button>}
					{(!section.url) && <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={uploadPdf}>Upload</Button>}
					<Button size="sm" outline={true} className="pr-3 fs--1" color="danger" onClick={() => setShowUploadModal(false)}>Cancel</Button>
				</ModalFooter>
			</Modal> */}


			{(showSectionDetails === false && parentModuleTemplateId) && <SectionDataTable selectedItem={onSelectedItem} moduleTemplateId={parentModuleTemplateId} />}
			{showLoader === true && <Loader />}
			{(showSectionDetails === true) && <>
				<Row>
					<Col className="col-12">
						<Card className="mb-3">
							<CardHeader >
								<Row className="align-items-center">
									<Col className="col-6">
										<h3>Section Details</h3>
									</Col>
									<Col className="col-6 text-right">
										<Row className="align-items-right">
											<Col className="col-12 text-right">
												{(section.sectionTypeId !== SECTION_TYPEID.QUIZ) && <Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="warning" onClick={launchUploadPdfModal}>Manage Upload</Button>}
												<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="success" onClick={launchModal}>Edit Section Settings</Button>
												<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => backToSections()}>Back To Sections</Button>
											</Col>
										</Row>

									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<Row>
									<Col xl={3}>
										<FormGroup>
											<Label className="font-weight-bold">Section Name</Label><br />
											<Label>{section.description}</Label>
										</FormGroup>
									</Col>
									<Col xl={1}>
										<FormGroup>
											<Label className="font-weight-bold">Type</Label><br />
											<Label>{section.sectionType}</Label>
										</FormGroup>
									</Col>
									<Col xl={4}>
										<FormGroup>
											<Label className="font-weight-bold">Media</Label><br />
											{(section.sectionTypeId === SECTION_TYPEID.QUIZ) ? (<>
												<Label className="text-danger">Cannot upload media for a quiz.</Label>
											</>) : (<Label style={{ cursor: 'pointer' }}>{section.url ? <a href={section.url} rel="noreferrer" target='_blank'><Label>{section.fileName}</Label></a> : 'Edit to upload media'}</Label>)}
										</FormGroup>
									</Col>
									{section.sectionTypeId === SECTION_TYPEID.QUIZ &&
										<>
											<Col xl={2}>
												<FormGroup>
													<Label className="font-weight-bold">Attempts Allowed</Label><br />
													<Label>{section.numberOfAttemptsAllowed}</Label>
												</FormGroup>
											</Col>
											<Col xl={2}>
												<FormGroup>
													<Label className="font-weight-bold">Passing Score (%)</Label><br />
													<Label>{section.passingScore}</Label>
												</FormGroup>
											</Col>
										</>}
								</Row>
								<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={launchDeleteModal}>Delete Section</Button>
								{section.sectionTypeId === SECTION_TYPEID.QUIZ && <Row>
									<Col className="col-12">
										{(showAnswers === true) ? (<Answers />) : (<Questions />)}
									</Col>

								</Row>}
							</CardBody>
						</Card>
					</Col>
				</Row>

			</>
			}
		</>
	);
};
export default SectionMaster;