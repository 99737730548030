import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, FormGroup, Label, } from 'reactstrap';
import Select from 'react-select';
import MaskedInput from 'react-maskedinput'

// services
import * as userApi from '../services/userApi';
import { PROVIDERS } from '../services/appConstants';
import { BUTTON_ACTIONS } from '../services/appConstants'
import * as utils from '../helpers/utils';

const AdminPasswordReset = ({ selectedItem, cancelPasswordModal }) => {

	const [errorMessage, setErrorMessage] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [disableButton, setDisableButton] = useState(false);



	const updatePassword = () => {

		if (!newPassword) {
			setErrorMessage('Oops! Enter a new password');
			return;
		}

		let payload = {
			email: selectedItem.email,
			password: newPassword
		};

		userApi.adminResetPassword(payload)
			.then(resp => {
				setErrorMessage('');
				cancelPasswordModal();
			});

	}

	return (
		<>
			<Modal isOpen={true} centered style={{ maxWidth: 500 }}>
				<ModalHeader>User Password Reset</ModalHeader>
				<ModalBody>
					{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}

					<div className="form-row">
						<div className="col-12">

							<FormGroup>
								<Label>New Password *<small>(example: Test21@!)</small></Label>
								<Input
									autoComplete='nofill'
									type='password'
									value={newPassword}
									onChange={({ target }) => setNewPassword(target.value)}
								/>
							</FormGroup>

						</div>

					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={updatePassword} disabled={disableButton}>Update Password</Button>{'  '}
					<Button color="info" onClick={cancelPasswordModal}>Cancel</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default AdminPasswordReset;