import { SETTINGS as ActionTypes } from '../actionTypes';


const initialState = {
	//settings: {
	isRTL: false,
	isFluid: true,
	isDark: false,
	isTopNav: false,
	isOpen: false,
	isSidePanelOpen: false,
	showBurgerMenuItem: false,
	isNavbarVerticalCollapsed: false,
	navbarStyle: 'vibrant',
	currency: '$',
	showBurgerMenu: false
	//plyr: { "volume": 1, "muted": false, "speed": 1 }
	//}
};

export const settingsReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.GET_SETTINGS:
			return { ...state };
		case ActionTypes.SET_IS_SIDE_PANEL_OPEN:
			return { ...state, isSidePanelOpen: action.payload };
		case ActionTypes.SET_IS_DARK:
			return { ...state, isDark: action.payload };
		case ActionTypes.SET_IS_TOP_NAV:
			return { ...state, isTopNav: action.payload };
		case ActionTypes.SET_NAV_BAR_STYLE:
			return { ...state, navbarStyle: action.payload };
		case ActionTypes.SET_IS_FLUID:
			return { ...state, isFluid: action.payload };
		case ActionTypes.SET_SHOW_BURGER_MENU:
			return { ...state, showBurgerMenu: action.payload };
		case ActionTypes.SET_IS_NAVBAR_VERTICAL_COLLAPSED:
			return { ...state, isNavbarVerticalCollapsed: action.payload };
		default:
			return state;
	};
}