import { ANSWERS as ActionTypes } from '../actionTypes';

const initialState = {
	answer: { // has to be called selectedQuestion because we have a child property also called question,
		answerTemplateId: 0,
		questionTemplateId: 0,
		answer: '',
		isCorrect: true,
		order: 0
	},
	answers: []
};


export const answerReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_ANSWERS:

			return {
				...state,
				answers: action.payload
			};
		case ActionTypes.SET_SELECTED_ANSWER:
			return {
				...state,
				answer: action.payload
			};

		case ActionTypes.INSERT_ANSWER:
			// push item into  list
			let newList = [...state.answers];
			newList.push(action.payload);

			return {
				...state,
				answers: newList,
				answer: initialState.answer
			};

		case ActionTypes.UPDATE_ANSWER:
			// clear the selected item with the initial.state item
			// update the item in the list of items
			let myItems = [...state.answers];
			let indexToUpdate = myItems.findIndex(item => item.answerTemplateId === action.payload.answerTemplateId);
			myItems[indexToUpdate] = action.payload;

			return {
				...state,
				answers: myItems,
				answer: initialState.answer
			};
		default:
			return state;
	};
}