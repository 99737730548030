import { API_METHOD, ENDPOINTS, LOCAL_STORAGE } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/userModule';

export const getUsersAttachedToModule = (moduleTemplateId) => {
	let url = `${apiRoot}/GetModuleUsers/${moduleTemplateId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const getUsersAvailableToAttach = (moduleTemplateId) => {
	let url = `${apiRoot}/GetUsersAvailableToAttach/${moduleTemplateId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const addUserToModule = (data) => {
	let url = `${apiRoot}/AddUserToModule`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const removeUserFromModule = (data) => {
	let url = `${apiRoot}/RemoveUserFromModule`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const getMyModules = async () => {
	let userGuid = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PROFILE)).id;

	let url = `${apiRoot}/GetAllModulesForUser/${userGuid}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const resetModuleForUser = (data) => {

	let url = `${apiRoot}/ResetModuleForUser`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}