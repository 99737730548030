import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CustomInput, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Row, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form } from 'react-bootstrap';

// components
import PageHeader from '../components/common/PageHeader';
import FalconCardHeader from '../components/common/FalconCardHeader';

// services
import { BUTTON_ACTIONS } from '../services/appConstants'
import * as resourceApi from '../services/resourceApi';


const MediaMangement = () => {
    const baseObject = { mediaId: 0, name: '', uri: '', isActive: true };
    const [showLoader, setShowLoader] = useState(false);
    const [localList, setLocalList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [buttonAction, setButtonAction] = useState('');
    const [localObject, setLocalObject] = useState(baseObject);


    useEffect(() => {
        loadTypes();
    }, []);

    const loadTypes = () => {
        setShowLoader(true);

        // get current list of videos - pass a getAll  = true
        resourceApi.getVideoList(true)
            .then((resp) => {

                setLocalList(resp.data.results);
                setShowLoader(false);
            })
    }

    const launchInsertModal = () => {
        setButtonAction(BUTTON_ACTIONS.INSERT);
        setLocalObject(baseObject);
        setShowModal(true);
    }


    const submitModal = () => {
        if (!localObject.name) {
            setErrorMessage('Please enter a video display name');
            return;
        }

        if (!localObject.uri) {
            setErrorMessage('Please enter a url for the video');
            return;
        }

        switch (buttonAction) {
            case BUTTON_ACTIONS.INSERT:

                resourceApi.insert(localObject)
                    .then(() => {
                        loadTypes();
                        setShowModal(false);
                    });
                break;

            case BUTTON_ACTIONS.UPDATE:

                // proceed
                resourceApi.update(localObject)
                    .then(() => {
                        loadTypes();
                        setShowModal(false);
                    });
                break;
        }
    }

    const cancelModal = () => {
        setShowModal(false);
    }

    const onSelectedItem = (item) => {
        setShowModal(true);
        setButtonAction(BUTTON_ACTIONS.UPDATE);
        setLocalObject(item);
    }


    const updateAction = (cell, item) => {

        return (
            <div style={{ textAlign: 'right' }}>
                <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={() => onSelectedItem(item)}>
                    <FontAwesomeIcon icon='undo-alt' className="ml-1 fs--4" style={{ width: 25 }} /> Edit
                </Button>
            </div>
        );
    };

    const linkColumn = (cell, item) => {

        return (
            <Label style={{ cursor: 'pointer' }}><a href={item.uri} target='_blank' rel="noreferrer"><Label>{item.uri}</Label></a></Label>
        );
    };



    //



    const columns = [
        {
            text: 'Name',
            dataField: 'name',
            classes: 'py-2 align-middle',
            sort: true
        },
        {
            text: 'Is Active',
            dataField: 'isActive',
            classes: 'py-2 align-middle',
            sort: true
        },
        {
            text: 'Uri',
            dataField: 'uri',
            classes: 'py-2 align-middle',
            formatter: linkColumn,
            sort: false
        },
        {
            dataField: 'reset',
            classes: 'border-0 align-middle text-right',
            headerClasses: 'border-0',
            text: '',
            formatter: updateAction
        },
    ];


    return (
        <>
            <Modal isOpen={showModal} centered style={{ maxWidth: 750 }}>
                <ModalHeader>{buttonAction} Video Management</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} className="text-left">Video</Col>
                    </Row>
                    {errorMessage && <Row>
                        <Col md={12} className="text-left text-danger">{errorMessage}</Col>
                    </Row>}
                    <Row>
                        <Col md={12}>
                            <Input
                                name='cart-quantity'
                                type='text'
                                value={localObject.name}
                                onChange={item => setLocalObject({ ...localObject, name: item.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="text-left">Url</Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Input
                                name='cart-quantity'
                                type='text'
                                value={localObject.uri}
                                onChange={item => setLocalObject({ ...localObject, uri: item.target.value })}
                            />
                        </Col>
                    </Row>

                    <Form.Check
                        type="switch"
                        id="status"
                        label="Is Active"
                        className="form-label-nogutter"

                        checked={localObject.isActive}
                        onChange={({ target }) => setLocalObject({ ...localObject, isActive: target.checked })}
                    />


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submitModal}>{buttonAction}</Button>{'  '}
                    <Button color="info" onClick={cancelModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <PageHeader title="Video Management" description="Be sure to setup the streaming service for each video in the azure portal" className="mb-3">
                <Button
                    tag="a"
                    href="#!"
                    color="link"
                    size="sm"
                    className="pl-0"
                    onClick={launchInsertModal}
                >
                    Add a new video
                    <FontAwesomeIcon icon="chevron-right" className="ml-1 fs--2" />
                </Button>
            </PageHeader>

            <Card className="mb-3">
                <FalconCardHeader title="Videos" light={false} />
                <CardBody
                //className="bg-light"
                >

                    <BootstrapTable
                        bootstrap4
                        keyField="mediaId"
                        data={localList}
                        columns={columns}
                        bordered={false}
                        classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                        rowClasses="btn-reveal-trigger"
                        headerClasses="bg-200 text-900"
                    />


                </CardBody>
            </Card>
        </>
    );
};

export default MediaMangement;