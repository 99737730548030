import { API_METHOD, ENDPOINTS, LOCAL_STORAGE } from './appConstants';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/student';

export const getModuleManifest = myRequest => {
	//let userGuid = JSON.parse(await localStorage.getItem(LOCAL_STORAGE.PROFILE)).id;

	let url = `${apiRoot}/GetModuleManifest`;
	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(myRequest)
	};
	return handleFetch(url, options, true);
}

export const startQuizAttempt = (myRequest) => {

	let url = `${apiRoot}/StartQuiz`;
	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(myRequest)
	};
	return handleFetch(url, options, true);
}

export const updateVideoProgress = (myRequest) => {

	let url = `${apiRoot}/UpdateVideoProgress`;
	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(myRequest)
	};
	return handleFetch(url, options, true);
}

export const completeSection = (myRequest) => {

	let url = `${apiRoot}/CompleteSection`;
	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(myRequest)
	};
	return handleFetch(url, options, true);
}

export const gradeQuiz = (myRequest) => {

	let url = `${apiRoot}/GradeQuiz`;
	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(myRequest)
	};
	return handleFetch(url, options, true);
}