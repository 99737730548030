import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Row, Col, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



// components
import SoftBadge from '../components/common/SoftBadge';
import PageHeader from '../components/common/PageHeader';
import ModuleFormModal from './components/_ModuleFormModal';
import Loader from '../components/common/Loader';

import AdvanceTableWrapper from '../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../components/common/advance-table/AdvanceTablePagination';

// services
import { BUTTON_ACTIONS, LOCAL_STORAGE, TAB_ITEMS } from '../services/appConstants';
import * as moduleActions from '../redux/actions/moduleActions';
import * as moduleUserActions from '../redux/actions/moduleUserActions';

const ModuleDashboard = () => {
	let dispatch = useDispatch();
	let navigate = useNavigate();
	const { modules } = useSelector(state => state.moduleReducer);
	const { availableUsers, assignedUsers } = useSelector(state => state.moduleUserReducer);

	const [showModal, setShowModal] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		setShowLoader(true);
		dispatch(moduleActions.setItems())
			.then(() => {
				setShowLoader(false);
			});
	}

	const selectedAction = async (row, navTo) => {

		localStorage.removeItem(LOCAL_STORAGE.MODULE_ID);
		localStorage.setItem(LOCAL_STORAGE.MODULE_ID, JSON.stringify(row.moduleTemplateId));

		// set into redux
		dispatch(moduleActions.setSelectedItem(row))
			.then(resp => {
				switch (navTo) {
					case 'Details':
						navigate('/app/lms-details');
						break;
					case 'Users':
						setShowLoader(true);
						dispatch(moduleUserActions.resetSearchFilters(availableUsers, assignedUsers));
						dispatch(moduleUserActions.updateTabView(TAB_ITEMS.ASSIGNED));
						dispatch(moduleUserActions.setInitAssignedUsers(row.moduleTemplateId))
							.then(() => {
								dispatch(moduleUserActions.setInitAvailableUsers(row.moduleTemplateId))
									.then(() => {
										setShowLoader(false);
										navigate('/app/module-users');
									});
							});
						break;
					default:
						return;
				}
			});
	}

	const actionFormatter = (rowData) => {
		let row = rowData.row.original;

		return (
			<>
				<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => selectedAction(row, 'Users')}>
					<FontAwesomeIcon icon="users" className="ml-1 fs--4" /> Users
				</Button>
				<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => selectedAction(row, 'Details')}>
					<FontAwesomeIcon icon="list" className="ml-1 fs--4" /> Details
				</Button>
			</>
		);
	};

	const badgeFormatter = (rowData) => {
		let color = '';
		let icon = '';
		let text = '';

		const { moduleStatusId } = rowData.row.original;

		switch (moduleStatusId) {

			case 1:
				color = 'warning';
				icon = 'edit';
				text = 'Draft';
				break;
			case 2:
				color = 'success';
				icon = 'check';
				text = 'Active';
				break;
			case 3:
				color = 'danger';
				icon = 'ban';
				text = 'Inactive';
				break;
			case 4:
				color = 'secondary';
				icon = 'archive';
				text = 'Archived';
				break;
		}

		return (
			<SoftBadge pill bg={color} className="fs--2">
				{text}
			</SoftBadge>
		);
	};

	const launchNewModuleModal = () => {
		// set empty item
		dispatch(moduleActions.setEmptyItem());
		setShowModal(true);
	}

	const cancelItem = (row) => {
		setShowModal(false);
	}

	const moduleFormSubmit = () => {
		setShowModal(false);
		history.push('/module-details');
	}

	const columns = [
		{
			accessor: 'moduleTemplateId',
			Header: 'Module',
			disableSortBy: false,
			headerProps: { style: { width: '10%', textAlign: 'center', fontWeight: 'bold' } },
			cellProps: { className: 'py-2 align-middle text-center' }
		},
		{
			accessor: 'title',
			Header: 'Module Name',
			disableSortBy: false,
			headerProps: { style: { width: '50%', textAlign: 'left', fontWeight: 'bold' } },
			cellProps: { className: 'py-2 align-middle text-left' }
		},
		{
			accessor: 'moduleStatusId',
			Header: 'Status',
			disableSortBy: false,
			headerProps: { style: { width: '15%', textAlign: 'center', fontWeight: 'bold' } },
			cellProps: { className: 'py-2 align-middle text-center' },
			Cell: badgeFormatter
		},
		{
			accessor: 'action',
			Header: '',
			disableSortBy: true,
			headerProps: { style: { width: '15%', fontWeight: 'bold', textAlign: "center" } },
			cellProps: { className: 'align-middle text-center' },
			Cell: actionFormatter
		}

		// {
		// 	dataField: 'action',
		// 	text: '',
		// 	headerClasses: 'border-0',
		// 	classes: 'align-middle text-right',
		// 	sort: false,
		// 	headerStyle: (colum, colIndex) => {
		// 		return { width: "25%", textAlign: "right" };
		// 	},
		// 	formatter: actionFormatter
		// }
	];

	return (
		<>
			<ModuleFormModal
				showModal={showModal}
				cancelItem={cancelItem}
				formSubmit={moduleFormSubmit}
				buttonAction={BUTTON_ACTIONS.INSERT}
			/>
			<PageHeader title="Module Dashboard"
				description="Below is a list of your modules with their status.  Modules must be set to 'Active' in order to be visible to students."
				className="mb-3"
			/>

			<Row>
				<Col className="col-12">
					<Card className="mb-3">
						<CardHeader className="align-content-right">
							<Row>
								<Col className="col-6">
									<h5>Module List</h5>
								</Col>
								<Col className="col-6 text-right">
									<Row className="align-items-right">
										<Col className="col-12 text-right">
											<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={launchNewModuleModal}>New Module</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							{showLoader === true ?
								(<Loader />) :
								(<>

									{modules && <AdvanceTableWrapper
										columns={columns}
										data={modules}
										//selection
										sortable
										pagination={true}
										perPage={10}
									>

										<AdvanceTable
											table
											headerClassName="bg-200 text-900 text-nowrap align-middle"
											rowClassName="align-middle white-space-nowrap"
											tableProps={{
												size: 'sm',
												striped: true,
												className: 'fs--1 mb-0 overflow-hidden'
											}}
										/>
									</AdvanceTableWrapper>}

								</>)}
						</CardBody>

					</Card>
				</Col>

			</Row>

			{/* <Card className="mb-3">
				<FalconCardHeader title="Properties" light={false} />
				<CardBody className="bg-light">
					<p>Test</p>
				</CardBody>
			</Card> */}
		</>
	);
};

export default ModuleDashboard;