import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from 'reactstrap';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componentss
import PageHeader from '../components/common/PageHeader';
import ModuleSettingsReadOnly from './components/_ModuleSettingsReadOnly';
import ModuleFormModal from './components/_ModuleFormModal';
import SectionMaster from './components/_SectionMaster';
import Loader from '../components/common/Loader';

// services
import * as moduleApi from '../services/moduleApi';
import * as moduleActions from '../redux/actions/moduleActions';
import * as sectionActions from '../redux/actions/sectionActions';
import { BUTTON_ACTIONS, LOCAL_STORAGE } from '../services/appConstants';

const ModuleDetails = () => {
	let dispatch = useDispatch();
	let navigate = useNavigate();
	const { module } = useSelector(state => state.moduleReducer);

	// module header items
	const [showModuleSettingsEditMode, setShowModuleSettingsEditMode] = useState(false);
	const [moduleTemplate, setModuleTemplate] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		setShowLoader(true);
		let moduleTemplateId = JSON.parse(localStorage.getItem(LOCAL_STORAGE.MODULE_ID));

		if (moduleTemplateId) {

			dispatch(moduleActions.setSelectedItemFromApi(moduleTemplateId));
			setShowLoader(false);
		} else {
			navigate('/module-dashboard');
		}
	}

	// const executeModuleSettingsUpdate = (updatedModule) => {
	// 	// user wants to edit the module settings
	// 	setShowModuleSettingsEditMode(false);
	// 	setModuleTemplate(updatedModule);
	// }

	const cancelModuleSettingsUpdate = (item) => {
		// user wants to exit the module settings modal
		setShowModuleSettingsEditMode(false);
	}

	const moduleFormSubmit = async (selectedItem) => {
		// user got done updating or inserting
		// we need to make sure we have a 

		setShowLoader(true);
		setShowModuleSettingsEditMode(false);

		setShowLoader(false);

	}

	const onDelete = async () => {
		await moduleApi.remove(moduleTemplate.moduleTemplateId);

		// reload the module list
		dispatch(moduleActions.setItems())
			.then(() => {
				navigate('/app/lms-dashboard');
			});
	}

	const launchDeleteModal = (itemToDelete) => {
		setModuleTemplate(itemToDelete);
		setShowDeleteModal(true);
	}



	const backToModuleList = () => {
		dispatch(sectionActions.clearSections());
		navigate('/app/lms-dashboard');
	}

	return (
		<>
			<Modal show={showDeleteModal} centered size="lg">
				<Modal.Header>Delete Module</Modal.Header><br />
				<Modal.Body className="bg-light">
					<Row>
						<Col xl={12}>
							<Label className="text-danger">Delete module?  This cannot be undone!</Label>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button size="sm" className="fs--1 me-2" color="success" onClick={() => onDelete()}>Delete</Button>
					<Button size="sm" className="fs--1 me-2" color="danger" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
				</Modal.Footer>
			</Modal>

			<PageHeader className="mb-3" title="">
				<Row className="align-items-center">
					<Col className="col-3">
						<h3>Module Details</h3>
					</Col>
					<Col className="col-9 text-right">
						<Row className="align-items-right">
							<Col className="col-12 text-right">
								<Button size="sm" className="me-2 fs--1" variant="success" onClick={() => setShowModuleSettingsEditMode(true)}>Edit Module</Button>
								<Button size="sm" className="me-2 fs--1" variant="info" onClick={() => backToModuleList()}><FontAwesomeIcon icon="chevron-left" className="ml-1 fs--2" /> Back To Dashboard</Button>
							</Col>
						</Row>
					</Col>
				</Row>
				<ModuleFormModal
					showModal={showModuleSettingsEditMode}
					cancelItem={cancelModuleSettingsUpdate}
					formSubmit={moduleFormSubmit}
					buttonAction={BUTTON_ACTIONS.UPDATE}
				/>
				{showLoader === true ?
					(<Loader />) :
					(<ModuleSettingsReadOnly
						parentModuleTemplate={module}
						onDelete={launchDeleteModal}
					/>)}
			</PageHeader>


			{module.moduleTemplateId !== 0 && <SectionMaster parentModuleTemplateId={module.moduleTemplateId} />}
		</>
	);
};

export default ModuleDetails;