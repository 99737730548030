import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { VERSION } from '../../services/appConstants';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">


          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
          CAA
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">v{VERSION}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
