import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

// components
import Loader from '../../components/common/Loader';

// services
import * as userQuizActions from '../../redux/actions/userQuizActions';
const lowerAlph = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
const Question = () => {
	let dispatch = useDispatch();
	const { selectedQuestion } = useSelector(state => state.userQuizReducer);

	const selectAnswer = (answerId) => {

		let temp = { ...selectedQuestion };

		temp.selectedAnswerId = answerId;
		dispatch(userQuizActions.setSelectedQuestion(temp))
			.then(resp => {

			});
	}

	return (
		<>
			<Row >
				<Col className="col-12">
					<div className="ml-1 mt-1 p-2">
						<h3>{selectedQuestion.question && (selectedQuestion.questionOrder + 1) + ". " + selectedQuestion.question}</h3>
					</div>
				</Col>
			</Row>
			<Row >
				<Col className="col-12">
					<div className="ml-1 mt-1 p-2">
						{selectedQuestion.questionAnswers.map((item, index) => {
							let color = "secondary";

							if (selectedQuestion.selectedAnswerId === item.answerTemplateId) {
								color = "primary";
							}
							return (
								<div key={index}>
									<Alert color={color} style={{ cursor: 'pointer' }} onClick={() => selectAnswer(item.answerTemplateId)}>
										<h5>{lowerAlph[index] + ". " + item.answer}</h5>
									</Alert>

								</div>
							)
						})}

					</div>
				</Col>
			</Row>

		</>
	);
};
export default Question;