import * as appConsts from './appConstants';

export const handleFetch = async (url, options, authRequired) => {
	let token = null;
	const storedToken = localStorage.getItem(appConsts.LOCAL_STORAGE.TOKEN);



	if (storedToken) {
		token = "Bearer " + storedToken.replace(/['"]+/g, '');
	}

	const headerAuth = {
		'Authorization': token,
		'Content-Type': 'application/json; charset=utf-8'
	};
	const header = {
		'Content-Type': 'application/json; charset=utf-8'
	};

	let fetchOptions = Object.assign(
		{},
		{
			headers: authRequired === true ? headerAuth : header
		},
		options
	);

	let callResponse = {};

	try {
		callResponse = await fetch(url, fetchOptions);



		let payload = await callResponse.json();


		// todo: handle 400 errors array


		let myReturnObject = {
			data: payload,
			status: callResponse.status
		}

		return myReturnObject;

	} catch (e) {

		let myReturnObject = {
			data: 'We are having some trouble on our end.  Please try again later!',
			status: 500
		}

		return myReturnObject;
	};

}