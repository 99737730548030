import React from 'react';
import { Col, Row, Spinner, Alert } from 'reactstrap';

const Loader = props => (
    <>
        <Row className="flex-center">
            <Col md="auto">
                <Spinner {...props} />
            </Col>
        </Row>
        <Row className="flex-center py-3">
            <Col lg="auto">
                <Alert color="info">Loading, please wait......</Alert>
            </Col>
        </Row>

    </>
);



export default Loader;
