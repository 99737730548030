import * as appConsts from './appConstants';
import { handleFetch } from './handleFetch'

const apiRoot = appConsts.ENDPOINTS.API + `/auth`;

export const login = async (e) => {
	let url = `${apiRoot}/UserLogin`;
	const payload = JSON.stringify(e);

	const options = {
		method: appConsts.API_METHOD.POST,
		body: payload
	};

	return await handleFetch(url, options, false);
}

export function sendResetLink(e) {
	let temp = {
		email: e,
		callBackUrl: appConsts.ENDPOINTS.CALL_BACK_URL
	};

	let url = `${apiRoot}/ResetPassword`;

	return new Promise((resolve, reject) => {
		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},

			body: JSON.stringify(temp)
		})
			.then(response => {
				// if (!response.ok) {
				//     var err = httpErrorFromStatus(response.status);
				//     return err;
				// }
				const contentType = response.headers.get('content-type');
				return contentType && response.json();
			})
			.then(json => {
				resolve(json);
			})
			.catch(error => {
				reject(error);
			});
	});
}


export function updatePassword(e) {

	let url = `${apiRoot}/UpdatePassword`;

	return new Promise((resolve, reject) => {
		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(e)
		})
			.then(response => {

				return response.json();
			})
			.then(json => {
				resolve(json);
			})
			.catch(error => {
				reject(error);
			});
	});
}
