export const SETTINGS = {
	GET_SETTINGS: 'GET_SETTINGS',
	SET_SETTINGS: 'SET_SETTINGS',

	SET_SHOW_BURGER_MENU: 'SET_SHOW_BURGER_MENU',
	SET_IS_SIDE_PANEL_OPEN: 'SET_IS_SIDE_PANEL_OPEN',
	SET_IS_DARK: 'SET_IS_DARK',
	SET_IS_TOP_NAV: 'SET_IS_TOP_NAV',
	SET_NAV_BAR_STYLE: 'SET_NAV_BAR_STYLE',
	SET_IS_FLUID: 'SET_IS_FLUID',
	SET_IS_NAVBAR_VERTICAL_COLLAPSED: 'SET_IS_NAVBAR_VERTICAL_COLLAPSED',

};

export const STUDENT = {
	SET_MANIFEST: 'SET_MANIFEST',


	SET_USER_MODULE: 'SET_USER_MODULE',
	UPDATE_USER_MODULE: 'UPDATE_USER_MODULE',
	UPDATE_USER_SECTION: 'UPDATE_USER_SECTION',

	START_QUIZ: "START_QUIZ",
	SET_SELECTED_QUESTION: "SET_SELECTED_QUESTION",
	UPDATE_QUESTION_LIST: "UPDATE_QUESTION_LIST",
	UPDATE_VIDEO_PROGRESS: "UPDATE_VIDEO_PROGRESS",
};

export const TRAINING = {

	SET_MODULES: 'SET_MODULES', // set the modules list
	SET_EMPTY_MODULE: 'SET_EMPTY_MODULE', // used for insert or to clear out the selected item
	INSERT_MODULE: 'INSERT_MODULE',
	UPDATE_MODULE: 'UPDATE_MODULE',
	DELETE_MODULE: 'DELETE_MODULE',
	SET_SELECTED_MODULE: "SET_SELECTED_MODULE",

	SET_MODULE_USERS: "SET_MODULE_USERS",
	INSERT_MODULE_USER: "INSERT_MODULE_USER",
	REMOVE_MODULE_USER: "REMOVE_MODULE_USER",
};

export const SECTIONS = {
	// sections
	SET_SECTIONS: "SET_SECTIONS",
	INSERT_SECTION: "INSERT_SECTION",
	UPDATE_SECTION: "UPDATE_SECTION",
	REMOVE_SECTION: "REMOVE_SECTION",
	SET_SELECTED_SECTION: "SET_SELECTED_SECTION",
	SET_EMPTY_SECTION: "SET_EMPTY_SECTION", // used for insert or to clear out the selected item
};

export const QUESTIONS = {
	// sections
	SET_QUESTIONS: "SET_QUESTIONS",
	INSERT_QUESTION: "INSERT_QUESTION",
	UPDATE_QUESTION: "UPDATE_QUESTION",
	REMOVE_QUESTION: "REMOVE_QUESTION",
	SET_SELECTED_QUESTION: "SET_SELECTED_QUESTION",
	SET_EMPTY_QUESTION: "SET_EMPTY_QUESTION",
	SHOW_ANSWERS: "SHOW_ANSWERS"
};

export const ANSWERS = {
	// sections
	SET_ANSWERS: "SET_ANSWERS",
	INSERT_ANSWER: "INSERT_ANSWER",
	UPDATE_ANSWER: "UPDATE_ANSWER",
	REMOVE_ANSWER: "REMOVE_ANSWER",
	SET_SELECTED_ANSWER: "SET_SELECTED_ANSWER",
	SET_EMPTY_ANSWER: "SET_EMPTY_ANSWER",
};


export const USER_QUIZ = {
	// sections
	SET_QUIZES: "SET_QUIZES",
	INSERT_QUIZ: "INSERT_QUIZ",
	SET_SELECTED_QUIZ: "SET_SELECTED_QUIZ",
	SET_SELECTED_QUESTION: "SET_SELECTED_QUESTION",
	UPDATE_ANSWER: "UPDATE_ANSWER",
	REMOVE_ANSWER: "REMOVE_ANSWER",
	SET_EMPTY_QUESTION: "SET_EMPTY_QUESTION",
};

export const MODULE_USER = {
	SET_INIT_AVAILABLE_USERS: "SET_INIT_AVAILABLE_USERS",
	SET_FILTERED_AVAILABLE_USERS: "SET_FILTERED_AVAILABLE_USERS",

	SET_FILTERED_ASSIGNED_USERS: "SET_FILTERED_ASSIGNED_USERS",
	SET_INIT_ASSIGNED_USERS: "SET_INIT_ASSIGNED_USERS",

	ASSIGN_SINGLE_USER: "ASSIGN_SINGLE_USER",
	REMOVE_SINGLE_USER: "REMOVE_SINGLE_USER",

	UPDATE_SEARCH_FILTERS: "UPDATE_SEARCH_FILTERS",
	UPDATE_SEARCH_TEXT: "UPDATE_SEARCH_TEXT",
	CLEAR_SEARCH_FILTERS: "CLEAR_SEARCH_FILTERS",
	SET_TAB_VIEW: "SET_TAB_VIEW",
	SEARCH_BY_TEXT_AVAILABLE: "SEARCH_BY_TEXT_AVAILABLE",
	SEARCH_BY_TEXT_ASSIGNED: "SEARCH_BY_TEXT_ASSIGNED",
};
