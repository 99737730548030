import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Row, Col, CardHeader } from 'reactstrap';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// components
import PageHeader from '../components/common/PageHeader';
import SoftBadge from '../components/common/SoftBadge';

import SectionPdf from './_SectionPdf';
import SectionVideo from './_SectionVideo';
import SectionQuiz from './_SectionQuiz';

// services
import { USER_MODULE_STATUS_VALUES, LOCAL_STORAGE, SECTION_TYPEID } from '../services/appConstants';
import * as studentActions from '../redux/actions/studentActions';

const StudentModule = () => {
	let dispatch = useDispatch();
	const { module, sections, userModule, userSections } = useSelector(state => state.studentReducer);
	const [localName, setLocalName] = useState('');

	useEffect(() => {
		if (userModule.userModuleId === 0) {
			rehydrateRedux();
		}

		let localStorageProfile = localStorage.getItem(LOCAL_STORAGE.PROFILE);
		if (localStorageProfile) {
			let temp = JSON.parse(localStorageProfile);
			setLocalName(temp.firstName + ' ' + temp.lastName);
		}

	}, []);

	const rehydrateRedux = async () => {
		// get keys from localStorage
		let params = JSON.parse(localStorage.getItem(LOCAL_STORAGE.STUDENT_MODULE));

		dispatch(studentActions.setModuleManifest(params));
	}

	const mySections = userSections.map((item, index) => {
		// we need to get a different card based on the type
		// find the section template

		let sectionTemplate = sections.find(s => s.sectionTemplateId === item.sectionTemplateId);

		switch (sectionTemplate.sectionTypeId) {
			case SECTION_TYPEID.VIDEO:
				return (
					<SectionVideo key={index} section={sectionTemplate} userSectionId={item.userSectionId} />
				);
			case SECTION_TYPEID.PDF:
				return (
					<SectionPdf key={index} section={sectionTemplate} userSectionId={item.userSectionId} />
				);
			case SECTION_TYPEID.QUIZ:

				return (
					<SectionQuiz key={index} section={sectionTemplate} userSection={item} />
				);
		}
	});

	const badgeFormatter = (status) => {

		if (status != 0) {
			let color = '';
			let icon = '';
			let text = '';

			switch (status) {
				case USER_MODULE_STATUS_VALUES.NEW:
					color = 'secondary';
					icon = 'edit';
					text = 'New';
					break;
				case USER_MODULE_STATUS_VALUES.IN_PROGRESS:
					color = 'primary';
					icon = 'stream';
					text = 'In Progress';
					break;
				case USER_MODULE_STATUS_VALUES.COMPLETED:
					color = 'success';
					icon = 'user-graduate';
					text = 'Completed';
					break;
				case USER_MODULE_STATUS_VALUES.FAILED:
					color = 'danger';
					icon = 'user-times';
					text = 'Failed';
					break;
				case USER_MODULE_STATUS_VALUES.EXPIRED:
					color = 'danger';
					icon = 'calendar-times';
					text = 'Expired';
					break;
			}

			return (
				<Badge color={`soft-${color}`} className="rounded-capsule fs-1 d-block">
					{text}
					<FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
				</Badge>
			);
		}
	};

	return (
		<>
			<PageHeader title="" description="" className="mb-3 mr-2">
				<Row style={{ marginBottom: 10 }}>
					<Col className="col-6">
						<h3>{module.title}</h3>
					</Col>
					<Col className="col-6 text-right">
						{badgeFormatter(userModule.statusId)}
					</Col>
				</Row>
			</PageHeader>
			{userSections.length > 0 && mySections}

			{userModule.statusId === USER_MODULE_STATUS_VALUES.COMPLETED &&
				<Row>
					<Col xl={12} className="pr-xl-2">
						<Card className="mb-3">
							<CardHeader className="align-content-right">
								<Row>
									<Col className="col-12 text-center">
										<h1>Certificate Of Completion</h1>
									</Col>
								</Row>
								<Row>
									<Col className="col-12 text-center">
										<h5>This certifies that</h5>
									</Col>
								</Row>
								<Row>
									<Col className="col-12 text-center">
										<h2>
											{localName}
										</h2>
									</Col>
								</Row>
								<Row>
									<Col className="col-12 text-center">
										<h4>{module.title}</h4>
									</Col>
								</Row>

							</CardHeader>
							<CardBody>

								<div></div>

							</CardBody>

						</Card>
					</Col>
				</Row>}

		</>
	);
};

export default StudentModule;