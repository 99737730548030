import { USER_QUIZ as ActionTypes } from '../actionTypes';

const initialState = {
	selectedQuestion: {
		correctAnswerId: 0,
		questionOrder: 0,
		isCorrect: 0,
		question: '',
		questionTemplateId: 0,
		selectedAnswerId: 0,
		userQuestionId: 0,
		userQuizId: 0,
		questionAnswers: []
	},
	passingScoreNeeded: 0,
	quizStatusId: 0,
	userQuizId: 0,
	userScore: 0,
	userSectionId: 0,
	answers: [],
	userQuestions: [],

};


export const userQuizReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_SELECTED_QUIZ:
			let tempSelected = action.payload.userQuestions[0];
			tempSelected.questionAnswers = action.payload.answers.filter(item => item.questionTemplateId === tempSelected.questionTemplateId);
			return {
				...state,
				passingScoreNeeded: action.payload.passingScoreNeeded,
				quizStatusId: action.payload.quizStatusId,
				userQuizId: action.payload.userQuizId,
				userScore: action.payload.userScore,
				userSectionId: action.payload.userSectionId,
				answers: action.payload.answers,
				userQuestions: action.payload.userQuestions,
				selectedQuestion: tempSelected
			};

		case ActionTypes.SET_SELECTED_QUESTION:
			let mySelectedQuestion = action.payload;
			mySelectedQuestion.questionAnswers = state.answers.filter(item => item.questionTemplateId === action.payload.questionTemplateId);

			let myItems = [...state.userQuestions];
			let indexToUpdate = myItems.findIndex(item => item.userQuestionId === action.payload.userQuestionId);
			myItems[indexToUpdate] = mySelectedQuestion;
			return {
				...state,
				userQuestions: myItems,
				selectedQuestion: mySelectedQuestion
			};

		// case ActionTypes.SET_QUESTIONS:

		// 	return {
		// 		...state,
		// 		questions: action.payload
		// 	};
		// case ActionTypes.SET_SELECTED_QUESTION:
		// 	return {
		// 		...state,
		// 		question: action.payload
		// 	};
		case ActionTypes.SET_EMPTY_QUESTION:
			// used when user cancels or completes the exam
			return {
				...state,
				selectedQuestion: initialState.selectedQuestion
			};

		// case ActionTypes.UPDATE_QUESTION:
		// 	// clear the selected item with the initial.state item
		// 	// update the item in the list of items
		// 	let myItems = [...state.questions];
		// 	let indexToUpdate = myItems.findIndex(item => item.questionTemplateId === action.payload.questionTemplateId);
		// 	myItems[indexToUpdate] = action.payload;

		// 	return {
		// 		...state,
		// 		questions: myItems,
		// 		question: initialState.question
		// 	};
		default:
			return state;
	};
}