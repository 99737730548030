import { LOCAL_STORAGE, ENDPOINTS, API_METHOD } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/upload';

export function uploadFile(e, sectionTemplateId, sectionTypeId) {
	let tok = null;
	const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
	if (token) {
		tok = token.replace(/['"]+/g, '');
	}

	let url = `${apiRoot}/Upload/${sectionTemplateId}/${sectionTypeId}`;

	let formData = new FormData();
	formData.append('file', e);
	const options = {
		method: 'POST',
		body: formData
	};

	return new Promise((resolve, reject) => {

		let fetchOptions = Object.assign(
			{},
			{
				headers: {
					Authorization: `Bearer ${tok}`,
					//"Content-Type": 'multipart/form-data'
				}
			},
			options
		);

		fetch(url, fetchOptions)
			.then(response => {

				let ErrorHandler = {
					statusCode: 401,
					status: 'Unauthorized'
				};

				const contentType = response.headers.get('content-type');

				if (response.status === 401) {
					return ErrorHandler;
				}
				return contentType && response.json();
			})
			.then(json => {
				resolve(json);
			})
			.catch(error => {

				reject(error);
			});
	});
}

export const deletePdf = (data) => {
	let url = `${apiRoot}/Delete`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}


export const test = () => {
	let url = `${apiRoot}/test`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const getVideoList = () => {
	let url = `${apiRoot}/GetListOfVideos`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}
export const getPdfList = () => {
	let url = `${apiRoot}/GetListOfPdfs`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}