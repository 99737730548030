import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button, CardBody, Row, Col, Collapse,
	FormGroup, Label, Alert, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import Scrollbar from 'react-scrollbars-custom';

// components
import Loader from '../../components/common/Loader';

// services
import * as questionActions from '../../redux/actions/questionActions';
import { BUTTON_ACTIONS } from '../../services/appConstants';
import * as questionApi from '../../services/questionApi';

const Questions = () => {
	let dispatch = useDispatch();
	const { section } = useSelector(state => state.sectionReducer);
	const { questions, question } = useSelector(state => state.questionReducer);

	const [showAddArea, setShowAddArea] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [localQuestion, setLocalQuestion] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [buttonAction, setButtonAction] = useState(BUTTON_ACTIONS.INSERT);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showAddNewItemMessage, setShowAddNewItemMessage] = useState(false);

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {

		setShowLoader(true);
		dispatch(questionActions.setItems(section.sectionTemplateId));
		setShowLoader(false);
	}

	const showAnswers = (item) => {
		dispatch(questionActions.showAnswers(item, true)); // set to true to show
	}

	const launchDeleteModal = (row) => {
		dispatch(questionActions.setSelectedItem(row));
		setShowDeleteModal(true);
	}
	const deleteQuestion = async () => {

		await questionApi.remove(section.sectionTemplateId, question.questionTemplateId)
			.then((resp) => {
				if (resp.data.success === true) {
					dispatch(questionActions.setItems(section.sectionTemplateId))
						.then(() => {
							setShowDeleteModal(false);
						})
				}
			});
	}

	const onItemEdit = (row) => {
		setButtonAction(BUTTON_ACTIONS.UPDATE);
		setLocalQuestion(row.question);
		dispatch(questionActions.setSelectedItem(row));
		setShowAddArea(true);
	}

	const openOrCloseEditArea = (shouldOpen, buttonAction) => {
		setLocalQuestion('');
		setShowAddArea(shouldOpen);
		setButtonAction(buttonAction);
	}

	const questionFormatter = (cell, item) => (
		<>
			<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => onItemEdit(item)}>
				<FontAwesomeIcon icon="edit" className="ml-1 fs--4" /> Edit
			</Button>
			<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="danger" onClick={() => launchDeleteModal(item)}>
				<FontAwesomeIcon icon="trash" className="ml-1 fs--4" /> Delete
			</Button>
			<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => showAnswers(item)}>
				<FontAwesomeIcon icon="list-alt" className="ml-1 fs--4" /> Answers
			</Button>
		</>
	);

	const reorderItems = (item, direction) => {

		dispatch(questionActions.updateOrder(item.sectionTemplateId, questions, item, direction));
	}

	const questionOrderButtons = (cell, item) => {
		var lastItemInArray = questions[questions.length - 1];
		let isLastItem = item.questionTemplateId === lastItemInArray.questionTemplateId ? true : false;
		let isFirstItem = item.order === 0 ? true : false;

		return (
			<div >
				<Button size="sm" outline={true} className="pr-3 fs--1 me-2" disabled={isFirstItem} color={isFirstItem ? 'secondary' : 'info'} onClick={() => reorderItems(item, 'up')}>
					<FontAwesomeIcon icon="chevron-up" className="ml-1 fs--4" />
				</Button>
				<Button size="sm" outline={true} className="pr-3 fs--1 m2-2" disabled={isLastItem} color={isLastItem ? 'secondary' : 'info'} onClick={() => reorderItems(item, 'down')}>
					<FontAwesomeIcon icon="chevron-down" className="ml-1 fs--4" />
				</Button>
			</div>
		);
	};

	const questionColumns = [
		{
			dataField: '',
			text: 'Question Order',
			sort: false,
			classes: 'py-2 align-middle',
			formatter: questionOrderButtons,
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: "left" };
			},
		},

		{
			dataField: 'question',
			text: 'Question',
			classes: 'py-2 align-middle',
			//formatter: linkFormatter,
			sort: false,
			headerStyle: (colum, colIndex) => {
				return { width: "55%", textAlign: "left" };
			},
		},
		// {
		// 	dataField: 'order',
		// 	text: 'order',
		// 	classes: 'py-2 align-middle',
		// 	//formatter: linkFormatter,
		// 	sort: false,
		// },
		{
			dataField: 'action',
			//classes: 'border-0 align-middle',
			classes: 'align-middle text-right',
			headerClasses: 'border-0',
			text: '',
			sort: false,
			formatter: questionFormatter,
			headerStyle: (colum, colIndex) => {
				return { width: "30%", textAlign: "center" };
			},
		}
	];

	const addQuestionToSection = async () => {

		setShowLoader(true);
		if (!localQuestion) {
			setErrorMessage('Please enter a question');
			return;
		}

		if (buttonAction === BUTTON_ACTIONS.UPDATE) {
			// we are updating a question 

			let temp = { ...question };
			temp.question = localQuestion;

			dispatch(questionActions.updateItem(temp))
				.then(() => {
					setShowAddArea(false);
					setShowLoader(false);

					// set defaults		
					setLocalQuestion(''); // clear section name field		
					dispatch(questionActions.setItems(section.sectionTemplateId));
				});
		} else {

			// we are inserting a question
			let newOrderPosition = 0;
			if (questions.length !== 0) {
				// get the last item and add 1 to it
				var lastItemInArray = questions[questions.length - 1];
				let index = questions.findIndex(c => c.questionTemplateId === lastItemInArray.questionTemplateId);
				newOrderPosition = index + 1;
			}

			setErrorMessage('');
			let payload = {
				question: localQuestion,
				sectionTemplateId: section.sectionTemplateId,
				order: newOrderPosition
			};


			await questionApi.insert(payload)
				.then(() => {
					setShowAddArea(false);
					setShowLoader(false);

					// set defaults		
					setLocalQuestion(''); // clear section name field		
					dispatch(questionActions.setItems(section.sectionTemplateId));
				});

		}

	}

	return (
		<>
			<Modal isOpen={showDeleteModal} centered style={{ maxWidth: 600 }}>
				<ModalHeader>Delete Question?</ModalHeader>
				<ModalBody>
					<div className="form-row">
						<div className="col-12">
							<Alert color='danger'>Delete the question below?</Alert>
							{question.question}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="danger" className="pr-3 fs--1 mr-2" onClick={deleteQuestion}>Delete</Button>
					<Button color="info" className="pr-3 fs--1" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
				</ModalFooter>
			</Modal>
			<div className="my-5">
				<Row>
					<Col className="col-6">
						<h3>Questions</h3>
					</Col>
					<Col className="col-6 align-items-right">
						<Row className="align-items-right">
							<Col className="col-12 text-right">
								<Button size="sm" outline={true} className="pr-3 fs--1" color="success" onClick={() => openOrCloseEditArea(true, BUTTON_ACTIONS.INSERT)} >
									Add Question
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
				<Collapse isOpen={showAddArea}>
					<CardBody className="bg-light align-items-right">
						{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
						<FormGroup>
							<Label>Question *</Label>
							<Input
								placeholder='Question'
								autoComplete='nofill'
								value={localQuestion ? localQuestion : ''}
								onChange={({ target }) => setLocalQuestion(target.value)}
							/>
						</FormGroup>
						<div style={{ textAlign: 'right' }}>
							<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={() => addQuestionToSection()}>{buttonAction}</Button>
							<Button size="sm" outline={true} className="pr-3 fs--1" color="danger" onClick={() => setShowAddArea(false)}>Cancel</Button>
						</div>

					</CardBody>
				</Collapse>

				{showLoader === true ?
					(<Loader />) :
					(<div className="my-3">
						<Scrollbar style={{ height: 500 }}>
							{showAddNewItemMessage === true ? (
								<>
									<div style={{ textAlign: 'center', marginTop: 35, marginBottom: 35 }}>
										<FontAwesomeIcon icon='list-alt' size='10x' className='mb-3' />
										<h1 className="fs-5">Get Started!</h1>
										<h5 className="fs-0">Add a question to begin.</h5>
									</div></>) : (


								<div className="table-responsive">
									<BootstrapTable
										bootstrap4
										keyField='questionTemplateId'
										data={questions}
										columns={questionColumns}
										bordered={false}
										classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
										rowClasses="btn-reveal-trigger"
										headerClasses="bg-200 text-900"
									/>
								</div>

							)}
						</Scrollbar>
					</div>)}

			</div>
		</>
	);
};
export default Questions;