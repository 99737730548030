import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button, Row, Col, FormGroup, Label, Alert, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// components
import Loader from '../../components/common/Loader';

// services
import { SECTION_TYPE } from '../../services/appConstants'
import * as sectionActions from '../../redux/actions/sectionActions';


const UploadVideoModal = ({ showModal, cancelItem, videoList }) => {
	let dispatch = useDispatch();
	const { section } = useSelector(state => state.sectionReducer);

	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
	}, []);


	const closeModal = () => {
		cancelItem();
	}


	const itemSelected = (item) => {
		let temp = { ...section };
		temp.fileName = item;

		dispatch(sectionActions.updateFileName(temp));
		cancelItem();
	}

	const myVideos = videoList.map((item, index) => {

		return (
			<div key={index}>
				<a href={'#'} onClick={() => itemSelected(item)}><Label style={{ cursor: 'pointer' }}>{item}</Label></a>
			</div>
		);
	});

	return (
		<>
			<Modal isOpen={showModal} centered style={{ maxWidth: 1000 }}>
				<Row style={{ marginVertical: 5, height: 45 }}>
					<Col className="col-6">
						<div className="ml-1 mt-1 p-2">
							<h5>Upload Video</h5>
						</div>
					</Col>
					<Col className="col-6 text-right">
						<div style={{ alignItems: 'left', paddingTop: 10 }}>
							{/* <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={cancelQuiz}>
								<FontAwesomeIcon icon="times" className="mr-1 fs--4" /> Cancel Quiz</Button> */}
						</div>
					</Col>
				</Row>
				<ModalBody className="bg-light">
					<Row>
						<Col className="col-12">
							<div className="ml-1 mt-1 p-2">
								<Alert color="info">
									<h6>Select a video from the list below to attach to this section.</h6>
								</Alert>
								{myVideos}
							</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button size="sm" outline={false} className="pr-3 fs--1 mr-2" color="success" onClick={closeModal}>Ok</Button>
					<Button size="sm" outline={true} className="pr-3 fs--1" color="primary" onClick={closeModal}>Cancel</Button>
				</ModalFooter>
			</Modal >


		</>
	);
};
export default UploadVideoModal;