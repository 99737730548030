import React, { useState, useEffect } from 'react';
import { Button, Row, Col, FormGroup, Label, Badge } from 'reactstrap';
import moment from 'moment';

// components

// services

const ModuleSettingsReadOnly = ({ parentModuleTemplate, onDelete }) => {

	useEffect(() => {
	}, []);

	const deleteModule = () => {
		// send back to the parent to deal with this
		onDelete(parentModuleTemplate);
	}

	return (
		<>
			<Row className="mt-3">
				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">Title</Label><br />
						<Label>{parentModuleTemplate.title}</Label>
					</FormGroup>
				</Col>
				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">Status</Label><br />
						<Label>{parentModuleTemplate.moduleStatus}</Label>
					</FormGroup>
				</Col>

				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">Date Scheduled For Release</Label><br />
						<Label>{parentModuleTemplate.dateScheduledForRelease && moment(parentModuleTemplate.dateScheduledForRelease).format('L')}</Label>
					</FormGroup>
				</Col>

				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">CE Hours</Label><br />
						<Label>{parentModuleTemplate.ceHours}</Label>
					</FormGroup>
				</Col>

			</Row>
			<Row>
				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">Site Code</Label><br />
						<Label>{parentModuleTemplate.siteCode}</Label>
					</FormGroup>
				</Col>
				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">Site Code Expiration Date</Label><br />
						<Label>{parentModuleTemplate.dateSiteCodeExpires && moment(parentModuleTemplate.dateSiteCodeExpires).format('L')}</Label>
					</FormGroup>
				</Col>
				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">Is Required</Label><br />
						<Label>{parentModuleTemplate.isRequired === true ? 'Yes' : 'No'}</Label>
					</FormGroup>
				</Col>
				<Col xl={3}>
					<FormGroup>
						<Label className="font-weight-bold">Date Must Complete By</Label><br />
						<Label>{parentModuleTemplate.dateMustCompletedBy && moment(parentModuleTemplate.dateMustCompletedBy).format('L')}</Label>
					</FormGroup>
				</Col>


			</Row>
			<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={deleteModule}>Delete Module</Button>
		</>
	);
};
export default ModuleSettingsReadOnly;