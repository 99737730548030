import { combineReducers } from 'redux';
import { settingsReducer } from './reducers/settingsReducer';
import { profileReducer } from './reducers/profileReducer';
import { studentReducer } from './reducers/studentReducer';
import { moduleReducer } from './reducers/moduleReducer';
import { sectionReducer } from './reducers/sectionReducer';
import { questionReducer } from './reducers/questionReducer';
import { answerReducer } from './reducers/answerReducer';
import { userQuizReducer } from './reducers/userQuizReducer';
import { moduleUserReducer } from './reducers/moduleUserReducer';


export const appReducer = combineReducers(
	{
		settingsReducer,
		profileReducer,
		studentReducer,
		moduleReducer,
		sectionReducer,
		questionReducer,
		answerReducer,
		userQuizReducer,
		moduleUserReducer
	});