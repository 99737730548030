// these are the menu routes
export const appRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/app/dashboard'
    }
  ]
};

export const moduleRoutes = {
  label: 'LMS Module',
  labelDisable: true,
  children: [
    {
      name: 'LMS Dashboard',
      active: true,
      icon: 'chalkboard-teacher',
      children: [
        { name: 'LMS', to: '/app/lms-dashboard' },
        { name: 'Video Management', to: '/app/media' }
      ]
    }
  ]
};

export const adminRoutes = {
  label: 'Admin Module',
  labelDisable: true,
  children: [
    {
      name: 'Admin Dashboard',
      active: true,
      icon: 'chalkboard-teacher',
      children: [
        { name: 'App Users', to: '/app/users' },
      ]
    }
  ]
};



export default [
  appRoutes,
  moduleRoutes,
  adminRoutes
];
