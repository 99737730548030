import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// services
import { LOCAL_STORAGE } from '../../services/appConstants';
import * as loginApi from '../../services/loginApi';

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const [message, setMessage] = useState('');

  useEffect(() => {
    removeToken();
  }, []);

  const removeToken = async () => {
    localStorage.removeItem(LOCAL_STORAGE.PROFILE);
    localStorage.removeItem(LOCAL_STORAGE.TOKEN);
  }

  useEffect(() => {
    setIsDisabled(!formData.email || !formData.password);
  }, [formData.email, formData.password]);



  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    // toast.success(`Logged in as ${formData.email}`, {
    //   theme: 'colored'
    // });


    setMessage('');
    setIsDisabled(true);
    loginApi.login(formData)
      .then(async resp => {

        if (resp.data.hasError === false) {

          localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON.stringify(resp.data.token));

          localStorage.setItem(LOCAL_STORAGE.PROFILE, JSON.stringify(resp.data.profile));

          setIsDisabled(false);
          navigate('/app/dashboard');
        } else {
          //setMessage(resp.data.title);
          setMessage('Login failed.  Please try again.');
          setIsDisabled(false);
          return;
        }
      })
      .catch(e => {
        setIsDisabled(false);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      {message && <Alert variant={'danger'}>{message}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Email address</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Email address' : ''}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="email"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        {/* <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row> */}

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            //disabled={!formData.email || !formData.password}
            disabled={isDisabled}
          >
            Log in
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
