

const initialState = {
	id: '',
	firstName: '',
	lastName: ''
};

export const profileReducer = (state = initialState, action) => {

	return initialState;
}