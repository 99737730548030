import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Card, CardBody, Row, Col, Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';


// components
import SoftBadge from '../components/common/SoftBadge';
import Loader from '../components/common/Loader';
import QuizModal from './components/_QuizModal';

// services
import { USER_QUIZ_STATUS_VALUES, USER_SECTION_STATUS_VALUES } from '../services/appConstants';

const SectionQuiz = ({ section, userSection }) => {
	let dispatch = useDispatch();
	const { userQuizes } = useSelector(state => state.studentReducer);

	const [showModal, setShowModal] = useState(false);

	const launchQuizModal = () => {
		// create the use quiz and return
		setShowModal(true);
	}

	const onCancelQuiz = () => {
		setShowModal(false);
	}

	const badgeFormatter = (status) => {
		let color = '';
		let icon = '';
		let text = '';

		switch (status) {
			case USER_QUIZ_STATUS_VALUES.NEW:
				color = 'primary';
				icon = 'ban';
				text = 'In Progress';
				break;
			case USER_QUIZ_STATUS_VALUES.PASSED:
				color = 'success';
				icon = 'check';
				text = 'Passed';
				break;
			case USER_QUIZ_STATUS_VALUES.FAILED:
				color = 'danger';
				icon = 'ban';
				text = 'Failed';
				break;
		}

		return (
			<SoftBadge pill bg={color} className="fs--2">
				{text}

			</SoftBadge >
		);
	};

	const dateFormatter = (item) => {
		var dt = new Date(item);
		dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset())

		return (
			moment(dt).format('LLLL')
		)
	}

	const columns = [
		{
			dataField: 'dateCreated',
			text: 'Date Attempted',
			classes: 'pl-2 py-2 align-middle',
			formatter: dateFormatter,
			sort: false,
			headerStyle: (colum, colIndex) => {
				return { width: "30%", textAlign: "left" };
			},
			style: () => {
				return { fontWeight: 'bold' }
			}
		},
		{
			dataField: 'quizStatusId',
			text: 'Result',
			classes: 'py-2 align-middle',
			formatter: badgeFormatter,
			sort: false,
			headerStyle: (colum, colIndex) => {
				return { width: "20%", textAlign: "left" };
			},
		}
	];

	return (
		<>
			<QuizModal showModal={showModal} onCancelQuiz={onCancelQuiz} userSection={userSection} section={section} />
			<Row>
				<Col xl={12} className="pr-xl-2">
					<Card className="mb-3">
						<CardBody>
							<Row>
								<Col className="col-6">
									<h5>{section.description}</h5>
								</Col>
								<Col className="col-6 text-right">
									<Row className="align-items-right">
										<Col className="col-12 text-right">
											{userSection.quizLimitReached === false &&
												<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={launchQuizModal}
													disabled={(userSection.userSectionStatusId !== USER_SECTION_STATUS_VALUES.COMPLETE) ? false : true}
												>
													<FontAwesomeIcon icon='list' className="mr-1 fs--4" /> New Attempt</Button>}
										</Col>
									</Row>
								</Col>
							</Row>

							<Row style={{ marginBottom: 10 }}>
								<Col className="col-12">
									<p>Below is a history of your quiz attempts.</p>
									{userSection.quizLimitReached === true && <Alert color="danger">You have reached the attempt limit for this quiz.  Please contact an administrator.</Alert>}
								</Col>
							</Row>

							<Row>
								<Col className="col-12">
									<div className="table-responsive">
										<BootstrapTable
											bootstrap4
											keyField="userQuizId"
											data={userQuizes.filter(item => item.userSectionId === userSection.userSectionId)}
											columns={columns}
											bordered={false}
											classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
											rowClasses="btn-reveal-trigger"
											headerClasses="bg-200 text-900"
										/>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default SectionQuiz;