import { MODULE_USER as ActionTypes } from '../actionTypes';
import { TAB_ITEMS } from '../../services/appConstants';



const initialState = {
	searchFilters: { // has to be called selectedQuestion because we have a child property also called question,
		text: '',
		isStudent: false,
		isInstructor: false,
		isAttached: false,
		isAdmin: false,
		isVandy: false,
		isAti: false,
		isTrace: false
	},
	searchText: '',
	tabView: TAB_ITEMS.ASSIGNED,
	availableUsers: [],
	filteredAvailableUsers: [],
	assignedUsers: [],
	filteredAssignedUsers: []
};

export const moduleUserReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_TAB_VIEW:
			return {
				...state,
				tabView: action.payload
			};
		case ActionTypes.SET_INIT_AVAILABLE_USERS:
			return {
				...state,
				availableUsers: action.payload,
				filteredAvailableUsers: action.payload,
			};

		case ActionTypes.SET_FILTERED_AVAILABLE_USERS:
			return {
				...state,
				filteredAvailableUsers: action.payload,
			};



		case ActionTypes.UPDATE_SEARCH_FILTERS:
			return {
				...state,
				searchFilters: action.payload,
				searchText: ''
			};

		case ActionTypes.SEARCH_BY_TEXT_AVAILABLE:

			// get the search text and the payload
			// for the text search, we search the master list

			return {
				...state,
				searchFilters: initialState.searchFilters,
				searchText: action.payload.searchText,
				filteredAvailableUsers: action.payload.list
			};

		case ActionTypes.SEARCH_BY_TEXT_ASSIGNED:

			// get the search text and the payload
			// for the text search, we search the master list

			return {
				...state,
				searchFilters: initialState.searchFilters,
				searchText: action.payload.searchText,
				filteredAssignedUsers: action.payload.list
			};

		case ActionTypes.CLEAR_SEARCH_FILTERS:
			return {
				...state,
				searchFilters: initialState.searchFilters,
				filteredAvailableUsers: action.payload.available,
				filteredAssignedUsers: action.payload.assigned
			};



		// get all on init
		case ActionTypes.SET_INIT_ASSIGNED_USERS:
			// 			
			return {
				...state,
				assignedUsers: action.payload,
				filteredAssignedUsers: action.payload
			};
		// 
		case ActionTypes.SET_FILTERED_ASSIGNED_USERS:
			// 			
			return {
				...state,
				filteredAssignedUsers: action.payload
			};

		case ActionTypes.ASSIGN_SINGLE_USER:
			// update the master list and filtered list

			let myMasterList = [...state.availableUsers];
			let masterIndexToUpdate = myMasterList.findIndex(item => item.appUserId === action.payload.appUserId);

			myMasterList.splice(masterIndexToUpdate, 1);

			//  check if filtered list has some data
			let myFilteredList = [];
			if (Array.isArray(state.filteredAvailableUsers) || state.filteredAvailableUsers.length) {
				myFilteredList = [...state.filteredAvailableUsers];
				let filterIndexToUpdate = myFilteredList.findIndex(item => item.appUserId === action.payload.appUserId);
				myFilteredList.splice(filterIndexToUpdate, 1);

			}

			return {
				...state,
				availableUsers: myMasterList,
				filteredAvailableUsers: myFilteredList
			};

		case ActionTypes.REMOVE_SINGLE_USER:
			// update the master list and filtered list

			let myMasterList2 = [...state.assignedUsers];
			let masterIndexToUpdate2 = myMasterList2.findIndex(item => item.appUserId === action.payload.appUserId);

			myMasterList2.splice(masterIndexToUpdate2, 1);

			//  check if filtered list has some data
			let myFilteredList2 = [];
			if (Array.isArray(state.filteredAssignedUsers) || state.filteredAssignedUsers.length) {
				myFilteredList2 = [...state.filteredAssignedUsers];
				let filterIndexToUpdate2 = myFilteredList2.findIndex(item => item.appUserId === action.payload.appUserId);
				myFilteredList2.splice(filterIndexToUpdate2, 1);

			}




			return {
				...state,
				assignedUsers: myMasterList2,
				filteredAssignedUsers: myFilteredList2
			};

		default:
			return state;
	};
}