import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Row, Col, Progress, Alert } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import ReactPlayer from 'react-player';

// components
import Loader from '../components/common/Loader';
import Duration from './Duration';

// services
import { USER_SECTION_STATUS_VALUES, LOCAL_STORAGE } from '../services/appConstants';
import * as studentActions from '../redux/actions/studentActions';

// test videos
// https://gist.github.com/jsturgis/3b19447b304616f18657

const SectionVideo = ({ section, userSectionId }) => {
	let dispatch = useDispatch();
	let playerRef = useRef();
	const { userSections } = useSelector(state => state.studentReducer);


	//const [url, setUrl] = useState(section.url);
	const [canCompleteSection, setCanCompleteSection] = useState({}); // used for Alert color
	const [videoReady, setVideoReady] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [playing, setPlaying] = useState(false);
	const [played, setPlayed] = useState(0); // actual cursor location
	const [duration, setDuration] = useState(0);
	const [maxPlayed, setMaxPlayed] = useState(0); // holder for how far i'm allowed to go
	const [apiPlayed, setApiPlayed] = useState(0); // value from db,
	const [seeking, setSeeking] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [localVideoProgress, setLocalVideoProgress] = useState(null);
	const [localUserSection, setLocalUserSection] = useState({});

	useEffect(() => {
		// get the userSection from redux using the section Id passed
		let myUserSectionFromRedux = userSections.find(item => item.userSectionId === userSectionId);

		// set section to local state from redux
		setLocalUserSection(myUserSectionFromRedux);

		// get the video progress from redux
		let myLocation = myUserSectionFromRedux.videoProgress; //videoProgressTime;
		if (myLocation === null) {
			// if null then set the location of the video to 0 the start
			myLocation = 0;
		}

		// set the local location progress
		setLocalVideoProgress(myLocation);

		//console.log('init api time played ' + myLocation)

		setMaxPlayed(myLocation);

		// not sure what this is
		setApiPlayed(myLocation);

	}, []);

	useEffect(() => {
		// don't log progress if the max played is less than api played
		// the api played could be at 15 min but the current maxPlayed could be at 5 min. 
		// that means the user jumped back.  we only want the maxPlayed to be greater than
		// the api played.  in this example we want the maxPlayed to be greater than 15 min to 
		// start saving that progress.



		// add 5 seconds to apiPlayed
		let varHodler = parseFloat(apiPlayed) + .005
		let maxHolder = parseFloat(maxPlayed)
		if (maxHolder > varHodler) {
			setApiPlayed(maxPlayed);

			let myUserSectionFromRedux = userSections.find(item => item.userSectionId === userSectionId);
			myUserSectionFromRedux.videoProgress = maxPlayed;
			dispatch(studentActions.updateVideoTime(myUserSectionFromRedux));
		}







		// if (maxPlayed > parseFloat(apiPlayed)) {
		// 	console.log('try to update the time')
		// 	let myUserSectionFromRedux = userSections.find(item => item.userSectionId === userSectionId);

		// 	if (localVideoProgress === null) {
		// 		myUserSectionFromRedux.videoProgress = maxPlayed;
		// 		// we are going to update for the first time
		// 		
		// 		setLocalVideoProgress(maxPlayed);

		// 	} else {
		// 		// we have a progress from the database
		// 		// it's in the localVideoProgress prop

		// 		let apiProgressLimit = parseFloat(localVideoProgress) + .1; // add 10 seconds

		// 		//console.log('max played - ' + maxPlayed)
		// 		//console.log('local video progress ' + localVideoProgress)

		// 		if (maxPlayed > apiProgressLimit) {
		// 			console.log('hit the api')
		// 			myUserSectionFromRedux.videoProgress = maxPlayed;
		// 			// save to the db
		// 			dispatch(studentActions.updateVideoTime(myUserSectionFromRedux));

		// 			setLocalVideoProgress(maxPlayed);

		// 		}
		// 		else {


		// 			console.log('test')
		// 		}
		// 	}

		// 	//console.log("callApi", maxPlayed);
		// 	setApiPlayed(maxPlayed);
		// } else {


		// 	console.log('the current time is less than what we already watched.  dont update');

		// }
	}, [maxPlayed]);

	useEffect(() => {
		let sectionStatusId = localUserSection.userSectionStatusId;

		let color = "warning";
		switch (sectionStatusId) {
			case USER_SECTION_STATUS_VALUES.COMPLETE:
				color = "success";
				break;
			case USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION:
				color = "danger";
				break;
		}

		setCanCompleteSection({ color: color, title: "View the video to its completion in order to unlock the completion action." });
	}, [localUserSection.userSectionStatusId]);

	useEffect(() => {
		//videoReady === false ? setIsLoading(true) : setIsLoading(false)
	}, [videoReady]);

	const handlePlayPause = () => {
		setPlaying(!playing);
	};

	const handlePlay = () => {
		setPlaying(true);
	};

	const handlePause = () => {
		setPlaying(false);
	};
	const handleSeekMouseDown = (e) => {
		setSeeking(true);
	};
	const handleSeekChange = (e) => {
		// manually move the location

		const newValue = parseFloat(e.target.value);
		if (localVideoProgress < newValue) {
			e.preventDefault();
			return;
		}
		setPlayed(newValue);
	};
	const handleSeekMouseUp = (e) => {
		setSeeking(false);
		const newValue = parseFloat(e.target.value);
		if (localVideoProgress < newValue) {
			e.preventDefault();
			return;
		}
		playerRef.seekTo(newValue);
	};
	const handleProgress = (stateRef) => {
		// update max played only if current played is greater than MP


		//console.log(stateRef)
		// We only want to update time slider if we are not currently seeking
		if (!seeking) {
			if (stateRef.played > apiPlayed) {

				setMaxPlayed(stateRef.played);
			}

			setPlayed(stateRef.played);
			//setLocalVideoProgress(stateRef.played);
		}
	};
	const handleEnded = () => {
		let myUserSectionFromRedux = userSections.find(item => item.userSectionId === userSectionId);
		// set progress to 1
		myUserSectionFromRedux.videoProgress = 1;
		myUserSectionFromRedux.userSectionStatusId = USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION;
		myUserSectionFromRedux.userSectionStatus = "Can Complete Section";

		dispatch(studentActions.updateVideoTime(myUserSectionFromRedux));
		setLocalVideoProgress(1);
		setLocalUserSection(myUserSectionFromRedux);

		setPlaying(false);
	};
	const handleDuration = (duration) => {

		setDuration(duration);
	};
	// const handleClickFullscreen = () => {
	// 	screenfull.request(playerRef);
	// };
	const ref = (player) => {
		playerRef = player;
	};
	const handleMute = () => {
		setIsMuted(!isMuted);
	};
	const handleReady = (ready) => {
		setIsLoading(false);
	};

	const onCompleteSection = (status) => {
		let payload = { ...localUserSection };
		payload.userSectionStatusId = status === true ? USER_SECTION_STATUS_VALUES.COMPLETE : USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION;
		setLocalUserSection(payload)

		dispatch(studentActions.completeSection(payload));

		let params = JSON.parse(localStorage.getItem(LOCAL_STORAGE.STUDENT_MODULE));
		dispatch(studentActions.setModuleManifest(params));
	}

	return (
		<>
			<Row >
				<Col xl={12} className="pr-xl-2">
					<Card className="mb-3">
						<CardBody>
							<Row style={{ marginBottom: 10 }}>
								<Col className="col-12">
									<h5>{section.description}</h5>
								</Col>
							</Row>
							<Row style={{ marginBottom: 5 }}>
								<Col className="col-12">

									<div className="player-wrapper">
										{isLoading === true && <Loader />}
										<ReactPlayer
											ref={(e) => ref(e)}
											className="react-player"
											width={isLoading === true ? "0%" : "100%"}
											height={isLoading === true ? "0%" : "100%"}
											url={section.url}
											pip={false}
											playing={playing}
											controls={false}
											loop={false}
											playbackRate={1}
											volume={1}
											muted={isMuted}
											onReady={() => handleReady()}
											//onBuffer={() => buffering()}
											//onBufferEnd={() => endBuffering()}
											onStart={() => console.log("onStart")}
											onPlay={() => handlePlay()}
											onPause={() => handlePause()}
											onEnded={() => handleEnded()}
											onError={(e) => console.log("onError", e)}
											onProgress={(progress) => handleProgress(progress)}
											onDuration={(duration) => handleDuration(duration)}
										/>

									</div>
								</Col>
							</Row>
							{isLoading === false &&
								<Row style={{ marginBottom: 5 }}>
									<Col className="col-6">
										<div style={{ marginTop: 25, textAlign: 'center' }}>
											<Button size="sm" outline={true} className="me-2" color="info" onClick={() => handlePlayPause()}>
												{playing ? (<>
													<FontAwesomeIcon icon={faPause} className="ml-1 fs--4" /> Pause
												</>) : (<><FontAwesomeIcon icon={faPlay} className="ml-1 fs--4" />  Play</>)}
											</Button>
											<Button size="sm" outline={true} className="me-2" color="info" onClick={() => handleMute()}>
												{isMuted === true ? (<>
													<FontAwesomeIcon icon={faVolumeUp} className="ml-1 fs--4" /> Unmute
												</>) : (<><FontAwesomeIcon icon={faVolumeMute} className="ml-1 fs--4" />  Mute</>)}
											</Button>

										</div>
									</Col>
									<Col className="col-6">
										<h6>{(localUserSection.videoProgress === "1" || localUserSection.videoProgress === 1) ? "Video Completed!" : "Amount Watched"}</h6>
										<Progress max={1} value={apiPlayed} />
										<input
											type="range"
											min={0}
											max={0.999999}
											step="any"
											style={{ width: '100%' }}
											value={played}
											onMouseDown={(e) => handleSeekMouseDown(e)}
											onChange={(e) => handleSeekChange(e)}
											onMouseUp={(e) => handleSeekMouseUp(e)}
										/> <Duration seconds={duration * played} /> - <Duration seconds={duration * (1 - played)} />
									</Col>
								</Row>}

							{(localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION) ?
								(<Row>
									<Col className="col-12">
										<Alert color={canCompleteSection.color}>
											<Form.Check
												type="switch"
												id="pdfStatus"
												className="form-label-nogutter"
												name="showFollowers"
												label={(localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION || localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE) ? "I have watched the video to its completion." : canCompleteSection.title}
												checked={localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE ? true : false}
												onChange={({ target }) => onCompleteSection(target.checked)}
											/>
										</Alert>
									</Col>
								</Row>) : (
									<Row>
										<Col className="col-12">
											<Alert color={canCompleteSection.color}>
												{localUserSection.userSectionStatusId !== USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION ? (canCompleteSection.title) : ("You have successfully completed this section!")}
											</Alert>
										</Col>
									</Row>
								)}
						</CardBody>
					</Card>
				</Col>
			</Row>

		</>
	);
};

export default SectionVideo;