import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button, CardBody, Row, Col, Collapse,
	Modal, ModalHeader, ModalBody, ModalFooter,
	FormGroup, Label, Alert, Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';

// components
import Loader from '../../components/common/Loader';

// services
import * as answerActions from '../../redux/actions/answerActions';
import * as questionActions from '../../redux/actions/questionActions';
import { BUTTON_ACTIONS } from '../../services/appConstants';
import * as answerApi from '../../services/answerApi';


const Answers = () => {
	let dispatch = useDispatch();
	const { question } = useSelector(state => state.questionReducer);
	const { answer, answers } = useSelector(state => state.answerReducer);

	const [showAddArea, setShowAddArea] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');
	const [localAnswer, setLocalAnswer] = useState('');
	const [buttonAction, setButtonAction] = useState(BUTTON_ACTIONS.INSERT);
	const [showAddNewItemMessage, setShowAddNewItemMessage] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		dispatch(answerActions.setItems(question.questionTemplateId));

	}

	const datatableActions = (cell, item) => (
		<>
			<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => onItemEdit(item)}>
				<FontAwesomeIcon icon="edit" className="ml-1 fs--4" /> Edit
			</Button>
			<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="danger" onClick={() => launchDeleteModal(item)}>
				<FontAwesomeIcon icon="trash" className="ml-1 fs--4" /> Delete
			</Button>
		</>
	);

	const answerIsCorrectActionButtons = (cell, item) => {
		let color = item.isCorrect ? 'success' : 'soft-light';
		if (item.isCorrect) {
			return (
				<>
					<Button size="sm" outline={true} className="pr-3 fs--1 me-2" disabled={item.isCorrect} color={color} onClick={() => updateIsCorrect(item)}>
						<FontAwesomeIcon icon="check-square" className="ml-1 fs--4" color="danger" />
					</Button>
				</>
			);
		} else {
			return (
				<>
					<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color={'danger'} onClick={() => updateIsCorrect(item)}>
						<FontAwesomeIcon icon="check-square" className="ml-1 fs--4" color="danger" />
					</Button>
				</>
			);
		}
	}

	const launchDeleteModal = (item) => {
		dispatch(answerActions.setSelectedItem(item));
		setShowDeleteModal(true);
	}
	const deleteAnswer = async () => {
		await answerApi.remove(question.questionTemplateId, answer.answerTemplateId)
			.then((resp) => {
				if (resp.data.success === true) {
					dispatch(answerActions.setItems(question.questionTemplateId))
						.then(() => {
							setShowDeleteModal(false);
						})
				}
			});
	}

	const updateIsCorrect = (item) => {
		setShowLoader(true);
		dispatch(answerActions.setAsCorrectAnswer(answers, item))
			.then(() => {
				setShowLoader(false);
			});
		dispatch(questionActions.setAsCorrectAnswer(item.questionTemplateId, item.answerTemplateId));
	}

	const reorderItems = (item, direction) => {
		dispatch(answerActions.updateOrder(item.questionTemplateId, answers, item, direction));
	}

	const answerOrderButtons = (cell, item) => {

		var lastItemInArray = answers[answers.length - 1];
		let isLastItem = item.answerTemplateId === lastItemInArray.answerTemplateId ? true : false;

		let isFirstItem = item.order === 0 ? true : false;

		return (
			<>
				<Button size="sm" outline={true} className="pr-3 fs--1 me-2" disabled={isFirstItem} color={isFirstItem ? 'secondary' : 'info'} onClick={() => reorderItems(item, 'up')}>
					<FontAwesomeIcon icon="chevron-up" className="ml-1 fs--4" />
				</Button>
				<Button size="sm" outline={true} className="pr-3 fs--1 me-2" disabled={isLastItem} color={isLastItem ? 'secondary' : 'info'} onClick={() => reorderItems(item, 'down')}>
					<FontAwesomeIcon icon="chevron-down" className="ml-1 fs--4" />
				</Button>
			</>
		);
	};

	const hideAnswers = () => {

		dispatch(answerActions.clearAnswers());
		dispatch(questionActions.showAnswers(question, false)); // set to true to show
	}

	const answerColumns = [
		{
			dataField: '',
			text: 'Answer Order',
			classes: 'py-2 align-middle',
			formatter: answerOrderButtons,
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: "left" };
			},
		},
		{
			dataField: 'isCorrect',
			text: 'Is Correct',
			classes: 'py-2 align-middle',
			formatter: answerIsCorrectActionButtons,
			sort: false,
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: "left" };
			},
		},
		{
			dataField: 'answer',
			text: 'Answer',
			classes: 'py-2 align-middle',
			//formatter: linkFormatter,
			sort: false,
			headerStyle: (colum, colIndex) => {
				return { width: "50%", textAlign: "left" };
			},
		},
		{
			dataField: 'order',
			text: 'order',
			classes: 'py-2 align-middle',
			//formatter: linkFormatter,
			sort: false,
		},
		{
			dataField: 'action',
			classes: 'align-middle text-right',
			headerClasses: 'border-0',
			text: '',
			formatter: datatableActions,
			headerStyle: (colum, colIndex) => {
				return { width: "20%", textAlign: "center" };
			},
			style: () => {
				return { textAlign: 'center' }
			}
		}
	];

	const openOrCloseEditArea = (shouldOpen, buttonAction) => {
		setLocalAnswer('');
		setShowAddArea(shouldOpen);
		setButtonAction(buttonAction);
	}

	const onItemEdit = (row) => {
		setButtonAction(BUTTON_ACTIONS.UPDATE);
		setLocalAnswer(row.answer);

		dispatch(answerActions.setSelectedItem(row))
			.then(() => {
				setShowAddArea(true);
			});
	}
	const submitForm = async () => {

		setShowLoader(true);
		if (!localAnswer) {
			setErrorMessage('Please enter an answer');
			return;
		}

		if (buttonAction === BUTTON_ACTIONS.UPDATE) {
			let temp = { ...answer };
			temp.answer = localAnswer;

			dispatch(answerActions.updateItem(temp))
				.then(() => {
					setShowAddArea(false);
					setShowLoader(false);

					// set defaults		
					setLocalAnswer(''); // clear section name field		
				});

		} else {
			let newOrderPosition = 0;
			if (answers.length !== 0) {
				// get the last item and add 1 to it
				var lastItemInArray = answers[answers.length - 1];
				let index = answers.findIndex(c => c.answerTemplateId === lastItemInArray.answerTemplateId);
				newOrderPosition = index + 1;
			}

			setErrorMessage('');
			let payload = {
				answer: localAnswer,
				questionTemplateId: question.questionTemplateId,
				order: newOrderPosition
			};
			await answerApi.insert(payload)
				.then(() => {

					setShowAddArea(false);
					setShowLoader(false);

					// set defaults		
					setLocalAnswer(''); // clear section name field		
					dispatch(answerActions.setItems(question.questionTemplateId));
				});
		}


	}


	return (
		<>
			<Modal isOpen={showDeleteModal} centered style={{ maxWidth: 600 }}>
				<ModalHeader>Delete Answer?</ModalHeader>
				<ModalBody>
					<div className="form-row">
						<div className="col-12">
							<Alert color='danger'>Delete the answer below?</Alert>
							{answer.answer}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="danger" className="pr-3 fs--1 me-2" onClick={deleteAnswer}>Delete</Button>
					<Button color="info" className="pr-3 fs--1 me-2" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
				</ModalFooter>
			</Modal>
			<div className="my-4">
				<Row>
					<Col className="col-8">
						<h3>Answers</h3>
					</Col>
					<Col className="col-4">
						<Row>
							<Col className="col-12">
								<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="success" onClick={() => openOrCloseEditArea(true, BUTTON_ACTIONS.INSERT)}>Add Answer</Button>
								<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => hideAnswers()}>Back To Questions</Button>
							</Col>
						</Row>

					</Col>
					<Col className="col-12">
						<h5>Q: {question.question}</h5>
					</Col>
				</Row>
				<Collapse isOpen={showAddArea}>
					<CardBody className="bg-light align-items-right">
						{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
						<FormGroup>
							<Label>Answer *</Label>
							<Input
								placeholder='Answer'
								autoComplete='nofill'
								value={localAnswer ? localAnswer : ''}
								onChange={({ target }) => setLocalAnswer(target.value)}
							/>
						</FormGroup>
						<div style={{ textAlign: 'right' }}>
							<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="success" onClick={submitForm}>{buttonAction}</Button>
							<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="danger" onClick={() => openOrCloseEditArea(false)}>Cancel</Button>
						</div>

					</CardBody>
				</Collapse>


				{showLoader === true ?
					(<Loader />) :
					(<div className="my-3">

						{showAddNewItemMessage === true ? (
							<>
								<div style={{ textAlign: 'center', marginTop: 35, marginBottom: 35 }}>
									<FontAwesomeIcon icon='list-alt' size='10x' className='mb-3' />
									<h1 className="fs-5">Get Started!</h1>
									<h5 className="fs-0">Add an answer to begin.</h5>
								</div></>) : (
							<div className="table-responsive">
								<BootstrapTable
									bootstrap4
									keyField="answerTemplateId"
									data={answers}
									columns={answerColumns}
									bordered={false}
									classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
									rowClasses="btn-reveal-trigger"
									headerClasses="bg-200 text-900"
								/>
							</div>)}

					</div>)}

			</div>
		</>
	);
};
export default Answers;