import { USER_QUIZ as ActionTypes } from '../actionTypes';
import * as studentApi from '../../services/studentApi';

// gets all previous attempts and cannot load to view just see the results
export function getAllQuizes(item) {
	return async (dispatch) => {

		let response = await studentApi.startQuizAttempt(item);

		let payload = null;
		if (response.status === 200) {
			payload = response.data;
		}

		dispatch(
			{
				type: ActionTypes.SET,
				payload: payload
			}
		)
	}
}

export function startQuiz(item) {
	return async (dispatch) => {

		// let myPayload = {
		// 	useSectionId: myRequest.userSectionId,
		// 	quizTemplateId: myRequest.quizTemplateId
		// };	

		let response = await studentApi.startQuizAttempt(item);

		let payload = null;
		if (response.status === 200) {
			payload = response.data;
		}

		dispatch(
			{
				type: ActionTypes.SET_SELECTED_QUIZ,
				payload: payload
			}
		)
	}
}

export function setEmptyQuestion() {
	return async (dispatch) => {


		dispatch(
			{
				type: ActionTypes.SET_EMPTY_QUESTION,
				payload: null
			}
		)
	}
}

export function setSelectedQuestion(payload) {
	return async (dispatch) => {
		// used to view the question but also update the question answer
		dispatch(
			{
				type: ActionTypes.SET_SELECTED_QUESTION,
				payload: payload
			}
		)
	}
}

export function gradeQuiz(userQuizId, userSectionId, userQuestions, attemptsAllowed) {
	return async (dispatch) => {
		// send a list of user questions

		let temp = {
			userQuizId,
			userSectionId,
			userQuestions,
			attemptLimit: attemptsAllowed
		};

		await studentApi.gradeQuiz(temp);

		dispatch(
			{
				type: ActionTypes.SET_EMPTY_QUESTION,
				payload: null
			}
		)
	}
}