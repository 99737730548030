import { ANSWERS as ActionTypes } from '../actionTypes';
import * as answerApi from '../../services/answerApi';



export function setItems(questionTemplateId) {
	return async (dispatch) => {
		let response = await answerApi.getAll(questionTemplateId);

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_ANSWERS,
				payload: payload
			}
		)
	}
}

export function setSelectedItem(item) {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_SELECTED_ANSWER,
				payload: item
			}
		)
	}
}

export function clearAnswers() {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_ANSWERS,
				payload: []
			}
		)
	}
}

export function updateItem(item) {
	return async (dispatch) => {

		let response = await answerApi.update(item);

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.result;
		}

		dispatch(
			{
				type: ActionTypes.UPDATE_ANSWER,
				payload: payload
			}
		)
	}
}

export function setAsCorrectAnswer(answers, selectedItem) {
	return async (dispatch) => {
		// find the old correct answer if there is any
		let oldCorrectItem = answers.find(c => c.isCorrect === true);
		if (oldCorrectItem) { // this will be null if there is no previous correct answer
			// set old item = to false
			oldCorrectItem.isCorrect = false;
			await answerApi.update(oldCorrectItem);
		}

		// update the new item to correct
		selectedItem.isCorrect = true;

		let payload = {};

		await answerApi.update(selectedItem)
			.then(async () => {
				// get all new list of answers with the correct updated items
				let response = await answerApi.getAll(selectedItem.questionTemplateId);


				if (response.data.success === true) {
					payload = response.data.results;
				}
			});




		dispatch(
			{
				type: ActionTypes.SET_ANSWERS,
				payload: payload
			}
		)
	}
}

export function updateOrder(questionTemplateId, allAnswers, answerToMove, direction) {
	return async (dispatch) => {

		let newTemp = allAnswers;

		// get the current index of the row
		let indexOfItemToRemove = allAnswers.findIndex((c) => c.answerTemplateId === answerToMove.answerTemplateId);

		// get the index of where we are going to place the new row		
		let newIndex = (direction === 'down') ? indexOfItemToRemove + 1 : indexOfItemToRemove - 1;

		// remove the item from the array using its current index
		newTemp.splice(indexOfItemToRemove, 1);

		// place the row into its new home using the newIndex position
		newTemp.splice(newIndex, 0, answerToMove);

		let newList = newTemp.map((answerToMove, index) => {
			answerToMove.order = index;
			return answerToMove.answerTemplateId;
		});

		await answerApi.updateOrder({ newOrderList: newList });

		let response = await answerApi.getAll(questionTemplateId);

		let payload = {};
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_ANSWERS,
				payload: payload
			}
		)
	}
}


