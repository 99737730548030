import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { appReducer } from './redux/rootReducer';


const store = createStore(appReducer, applyMiddleware(thunk))


ReactDOM.render(


  <Main>
    <Provider store={store}>
      <App />
    </Provider>
  </Main>,
  document.getElementById('main')

);
