import { QUESTIONS as ActionTypes } from '../actionTypes';

const initialState = {
	question: { // has to be called selectedQuestion because we have a child property also called question,
		questionTemplateId: 0,
		sectionTemplateId: 0,
		question: '',
		correctAnswerId: 0,
		order: 0
	},
	showAnswers: false,
	questions: [],
};


export const questionReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SHOW_ANSWERS:

			return {
				...state,
				showAnswers: action.payload.showAnswers,
				question: action.payload.question
			};
		case ActionTypes.SET_QUESTIONS:

			return {
				...state,
				questions: action.payload
			};
		case ActionTypes.SET_SELECTED_QUESTION:
			return {
				...state,
				question: action.payload
			};
		// case ActionTypes.SET_EMPTY_QUESTION:
		// 	return {
		// 		...state,
		// 		question: initialState.module
		// 	};

		case ActionTypes.INSERT_QUESTION:
			// push item into  list
			let newList = [...state.questions];
			newList.push(action.payload);

			return {
				...state,
				questions: newList,
				question: initialState.question
			};

		case ActionTypes.UPDATE_QUESTION:
			// clear the selected item with the initial.state item
			// update the item in the list of items
			let myItems = [...state.questions];
			let indexToUpdate = myItems.findIndex(item => item.questionTemplateId === action.payload.questionTemplateId);
			myItems[indexToUpdate] = action.payload;

			return {
				...state,
				questions: myItems,
				question: initialState.question
			};
		default:
			return state;
	};
}