import { TRAINING as ActionTypes } from '../actionTypes';
import * as moduleApi from '../../services/moduleApi';
import { LOCAL_STORAGE } from '../../services/appConstants'

export function setItems() {
	return async (dispatch) => {
		let response = await moduleApi.getAll();

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_MODULES,
				payload: payload
			}
		)
	}
}

export function setSelectedItem(item) {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_SELECTED_MODULE,
				payload: item
			}
		)
	}
}

export function setSelectedItemFromApi(id) {
	return async (dispatch) => {

		let resp = await moduleApi.getSingle(id);

		let payload = [];
		if (resp.data.success === true) {

			// get single response
			payload = resp.data.result;
		}

		dispatch(
			{
				type: ActionTypes.SET_SELECTED_MODULE,
				payload: payload
			}
		)
	}
}

export function setEmptyItem() {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_EMPTY_MODULE,
				payload: null
			}
		)
	}
}

export function insertItem(item) {
	return async (dispatch) => {

		let viewModel = {
			"title": item.title,
			"siteCode": item.siteCode,
			"ceHours": item.ceHours,
			"moduleStatusId": 1, // force to draft, must be this value on insert
			"isRequired": item.isRequired,
			"dateMustCompletedBy": item.dateMustCompletedBy,
			"dateScheduledForRelease": item.dateScheduledForRelease,
			"dateSiteCodeExpires": item.dateSiteCodeExpires
		};


		let response = await moduleApi.insert(viewModel);


		let payload = null;
		if (response.data.success === true) {

			// we have a good response
			localStorage.removeItem(LOCAL_STORAGE.MODULE_ID);
			localStorage.setItem(LOCAL_STORAGE.MODULE_ID, JSON.stringify(response.data.result.moduleTemplateId));
			payload = response.data.result;


		} else {
			// TODO: hande a failure

		}

		dispatch(
			{
				type: ActionTypes.INSERT_MODULE,
				payload: payload
			}
		)
	}
}

export function updateItem(item) {
	return async (dispatch) => {
		// has to match the view model
		let viewModel = {
			"moduleTemplateId": item.moduleTemplateId,
			"title": item.title,
			"siteCode": item.siteCode,
			"ceHours": item.ceHours,
			"moduleStatusId": item.moduleStatusId,
			"isRequired": item.isRequired,
			"dateMustCompletedBy": item.dateMustCompletedBy,
			"dateScheduledForRelease": item.dateScheduledForRelease,
			"dateSiteCodeExpires": item.dateSiteCodeExpires
		};

		let response = await moduleApi.update(viewModel);



		let payload = null;
		if (response.data.success === true) {
			payload = response.data.result;
		}

		dispatch(
			{
				type: ActionTypes.UPDATE_MODULE,
				payload: payload
			}
		)
	}
}

export function setModuleUsers() {

	return async (dispatch) => {

		let response = await moduleApi.getAll();

		let payload = null;
		if (response.status === 200) {
			payload = response.data;
		}

		dispatch(
			{
				type: ActionTypes.SET_MODULES,
				payload: payload
			}
		)
	}
}