import { SECTIONS as ActionTypes } from '../actionTypes';
import * as sectionApi from '../../services/sectionApi';
import * as uploadApi from '../../services/uploadApi';

export function setItems(moduleTemplateId) {

	return async (dispatch) => {
		// get sections by module template id
		let response = await sectionApi.getAll(moduleTemplateId);


		let payload = null;
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_SECTIONS,
				payload: payload
			}
		)
	}
}

export function updateOrder(moduleTemplateId, allSections, sectionToMove, direction) {
	return async (dispatch) => {

		let newTemp = allSections;

		// get the current index of the row
		let indexOfItemToRemove = allSections.findIndex((c) => c.sectionTemplateId === sectionToMove.sectionTemplateId);

		// get the index of where we are going to place the new row		
		let newIndex = direction === 'down' ? indexOfItemToRemove + 1 : indexOfItemToRemove - 1;

		// remove the item from the array using its current index
		newTemp.splice(indexOfItemToRemove, 1);

		// place the row into its new home using the newIndex position
		newTemp.splice(newIndex, 0, sectionToMove);


		let newList = newTemp.map((sectionToMove, index) => {
			sectionToMove.order = index;
			return sectionToMove.sectionTemplateId;
		});

		// update the order in the api
		await sectionApi.updateOrder({ newOrderList: newList });

		// get me a new list with the desired order
		let response = await sectionApi.getAll(moduleTemplateId);

		let payload = {};
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_SECTIONS,
				payload: payload
			}
		)
	}
}

export function setSelectedItem(item) {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_SELECTED_SECTION,
				payload: item
			}
		)
	}
}

export function clearSections() {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_SECTIONS,
				payload: []
			}
		)
	}
}


export function updateItem(item) {
	return async (dispatch) => {

		let response = await sectionApi.update(item);

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.result;
		}


		dispatch(
			{
				type: ActionTypes.UPDATE_SECTION,
				payload: payload
			}
		)
	}
}

// export function removeItem(moduleTemplateId, item) {
// 	return async (dispatch) => {

// 		let response = await sectionApi.remove(moduleTemplateId, item);

// 		let payload = null;
// 		if (response.data.success === true) {
// 			payload = response.data.result;
// 		}

// 		return;
// 	}
// }

export function uploadFile(file, item) {
	return async (dispatch) => {
		let payload = null;

		let uploadResponse = await uploadApi.uploadFile(file, item.sectionTemplateId, item.sectionTypeId);
		if (uploadResponse) {

			item.fileName = file.name;
			item.url = uploadResponse.url;

			payload = item;

		}

		dispatch(
			{
				type: ActionTypes.UPDATE_SECTION,
				payload: payload
			}
		)
	}
}

export function updateFileName(item) {
	return async (dispatch) => {

		let response = await sectionApi.update(item);

		let payload = null;
		if (response.status === 200) {
			payload = response.data;
		}

		dispatch(
			{
				type: ActionTypes.UPDATE_SECTION,
				payload: payload
			}
		)
	}
}

export function deleteFile(item) {
	return async (dispatch) => {

		let payload = null;

		payload = {
			sectionTemplateId: item.sectionTemplateId,
			sectionTypeId: item.sectionTypeId,
			fileName: item.fileName
		};

		let uploadResponse = await uploadApi.deletePdf(payload);
		if (uploadResponse) {
			item.fileName = '';
			item.url = '';
			let response = await sectionApi.update(item);

			if (response.status === 200) {
				payload = response.data;
			}
		}

		dispatch(
			{
				type: ActionTypes.UPDATE_SECTION,
				payload: payload
			}
		)
	}
}