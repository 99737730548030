import React, { useState, useEffect, createRef } from 'react';
import {
	Button, Card, CardBody, Input, Badge, CardHeader, Col, Row, UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form } from 'react-bootstrap';

// components
import PageHeader from '../components/common/PageHeader';
import Loader from '../components/common/Loader';
import UserFormModal from './_UserFormModal';
import AdminPasswordReset from './_AdminPasswordReset';
import SoftBadge from '../components/common/SoftBadge';

// services
import * as facilityApi from '../services/facilityApi';
import * as utils from '../helpers/utils';
import * as userApi from '../services/userApi';
import { BUTTON_ACTIONS } from '../services/appConstants'

const Users = () => {
	let table = createRef();

	const [showLoader, setShowLoader] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [filterMessage, setFilterMessage] = useState('');
	const [searchText, setSearchText] = useState('');

	const [buttonAction, setButtonAction] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);
	const [facilityList, setFacilityList] = useState([]);
	const [userMasterList, setUserMasterList] = useState([]);
	const [userList, setUserList] = useState([]);

	const [showPasswordModal, setShowPasswordModal] = useState(false);

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		setShowLoader(true);

		userApi.getAll(false) // will return active and inactive
			.then(response => {
				setShowLoader(false);
				var myUsers = response.data.results.sort((a, b) => {
					return (a.lastName.toUpperCase() > b.lastName.toUpperCase() ? 1 : -1)
				});

				setUserList(myUsers);
				setUserMasterList(myUsers);
			})
			.catch(e => {
				setShowLoader(false);
			});

		facilityApi.getAll()
			.then(response => {

				var myFacility = response.data.results.sort((a, b) => {
					return (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
				}).map(item => {
					return {
						value: item.facilityId,
						label: item.name
					}
				});

				setFacilityList(myFacility);
			})
			.catch(e => {
				setShowLoader(false);
			});
	};


	const insertUserLists = (item) => {
		// we are inserting an item in the list
		setUserMasterList([...userMasterList, item]);
		setShowModal(false);
	};


	const updateUserLists = (selectedItem) => {
		// we are updating an item in the list
		// // update the local master 	list
		let indexInQuestion = userMasterList.findIndex(c => c.id === selectedItem.id);
		userMasterList[indexInQuestion] = selectedItem;

		// update the filtered list for show
		let filteredIndex = userList.findIndex(c => c.id === selectedItem.id);
		userList[filteredIndex] = selectedItem;

		setShowModal(false);
	};
	const cancelItem = () => {
		setShowModal(false);
	};

	const statusFormatter = (statusId) => (
		<>
			{badgeFormatter(statusId)}
		</>
	);

	const badgeFormatter = (status) => {
		let color = '';
		let icon = '';
		let text = '';
		switch (status) {
			case 1:
				color = 'success';
				icon = 'check';
				text = 'Active';
				break;
			case 2:
				color = 'warning';
				icon = 'ban';
				text = 'Inactive';
				break;
		}

		return (
			<SoftBadge pill bg={color} className="fs--2">
				{text}
				<FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
			</SoftBadge>
		);
	};

	const editMode = (row) => {
		setShowModal(true);
		setButtonAction(BUTTON_ACTIONS.UPDATE);
		setSelectedItem(row);
	};
	const passwordReset = (row) => {
		setShowPasswordModal(true);
		setSelectedItem(row);

	};

	const actionFormatter = (cell, item) => {

		return (
			<>
				<div className='inline'>
					<Button size="sm" outline={true} className=" fs--1 " color="info" onClick={() => editMode(item)}>
						Edit
					</Button>{' '}
					<Button size="sm" outline={true} className=" fs--1 " color="info" onClick={() => passwordReset(item)}>
						Reset Password
					</Button>
				</div>

			</>
		)
	};



	const nameFormatter = (cell, row) => (
		<>
			{/* <Link className="text-900 font-weight-semi-bold" to="#!" onClick={() => onSelectedItem(row)}> */}
			{row.firstName} {row.lastName}
			{/* </Link> */}
		</>
	);

	const providerFormatter = (cell, row) => {
		let provider = utils.getProviderName(row.providerId).label;
		return (
			<p className="mb-0 text-500">{provider}</p>
		)
	};

	const columns = [
		{
			dataField: 'firstName',
			text: 'Name',
			classes: 'py-2 align-middle',
			formatter: nameFormatter,
			sort: true
		},
		{
			dataField: 'email',
			text: 'Email',
			classes: 'py-2 align-middle',
			sort: true
		},
		{
			dataField: 'facilityName',
			text: 'Facility',
			classes: 'py-2 align-middle',
			sort: true
		},
		{
			dataField: 'providerId',
			text: 'Provider',
			classes: 'py-2 align-middle',
			sort: true,
			formatter: providerFormatter
		},
		{
			dataField: 'statusId',
			text: 'Status',
			classes: 'py-2 align-middle text-center',
			headerStyle: (colum, colIndex) => {
				return { textAlign: 'center' };
			},
			sort: true,
			formatter: statusFormatter
		},
		{
			dataField: 'action',
			classes: 'py-2 align-middle text-center',
			headerClasses: 'border-0',
			text: 'Edit/Reset Pasword',
			headerStyle: (colum, colIndex) => {
				return { textAlign: 'center' };
			},
			formatter: actionFormatter
		}

	];

	const filterResults = (e) => {
		//setUserList(userMasterList);
		e.preventDefault();
		if (!searchText) {
			setFilterMessage('Oops! Enter name');
			return;
		}
		setFilterMessage('');

		//
		var filteredList = userMasterList.filter(x => x.lastName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
			|| x.firstName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
		setUserList(filteredList);

	};

	const launchInsertModal = () => {
		setShowModal(true);
		setSelectedItem(null);
		setButtonAction(BUTTON_ACTIONS.INSERT);
	};

	const clearFilter = () => {
		setFilterMessage('');
		setSearchText('');
		setUserList(userMasterList);
	};

	// const onSelectedItem = (row) => {

	// 	setShowModal(true);
	// 	setButtonAction(BUTTON_ACTIONS.UPDATE);
	// 	setSelectedItem(row);
	// };

	const onCancelPasswordModal = () => {
		setShowPasswordModal(false);
	};

	return (
		<>
			{showModal === true && <UserFormModal
				showModal={showModal}
				buttonAction={buttonAction}
				facilityList={facilityList}
				selectedItem={selectedItem}
				updateItem={updateUserLists}
				insertItem={insertUserLists}
				cancelItem={cancelItem}
			/>}

			{showPasswordModal === true && <AdminPasswordReset
				selectedItem={selectedItem}
				cancelPasswordModal={onCancelPasswordModal}
			/>}


			<PageHeader title="App users" description="Search and manage app users from here.  Click on the link below to add a new user." className="mb-3">
				<Button
					tag="a"
					href="#!"
					color="link"
					size="sm"
					className="pl-0"
					onClick={launchInsertModal}
				>
					Add a new user
					<FontAwesomeIcon icon="chevron-right" className="ml-1 fs--2" />
				</Button>
			</PageHeader>

			<Card className="mb-3">

				<CardHeader >
					<Row className="align-items-center">
						<Col className="col-3">
							<h5>App Users</h5>
						</Col>
						<Col className="col-9 text-right">

							<Row className="align-items-center">
								<Col className="col-5 text-right">
									{filterMessage && <Badge color='danger' style={{ marginRight: 8 }}>{filterMessage}</Badge>}
								</Col>
								<Col className="col-4 text-right">
									<Input
										name='ffdsfsdfsdf'
										type='text'
										autoComplete='nofill'
										placeholder='Search user by name.....'
										value={searchText}
										onChange={item => setSearchText(item.target.value)}
									/>
								</Col>

								<Col className="col-3 text-center">
									<Button color="link" size="sm" className="pr-3 fs--1"
										onClick={filterResults}>
										Search User
									</Button>
									<Button color="link" size="sm" className="pr-3 fs--1"
										onClick={clearFilter}>
										Clear
									</Button>
								</Col>
							</Row>

						</Col>
					</Row>
				</CardHeader>
				<CardBody >



					{(userList.length === 0) &&
						<div style={{ textAlign: 'center', marginTop: 35, marginBottom: 35 }}>
							<FontAwesomeIcon icon='user-friends' size='10x' className='mb-3' />
							<h1 className="fs-5">Get Started!</h1>
							<h5 className="fs-0">Enter a user name to search</h5>
						</div>
					}

					{showLoader && <Loader />}
					{(!showLoader && userList.length !== 0) &&
						<div className="table-responsive" style={{ minHeight: 300 }}>
							<BootstrapTable
								style={{ zIndex: 1000000, minHeight: '300px' }}
								ref={table}
								bootstrap4
								keyField="id"
								data={userList}
								columns={columns}
								//selectRow={selectRow(onSelect)}
								bordered={false}
								classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
								rowClasses="btn-reveal-trigger"
								headerClasses="bg-200 text-900"

							/>

						</div>
					}
				</CardBody>
			</Card>
		</>
	);
};

export default Users;