import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';

import { CSVLink, CSVDownload } from "react-csv";
// custom 
// https://blog.bitsrc.io/exporting-data-to-excel-with-react-6943d7775a92

// components
import SoftBadge from '../../../components/common/SoftBadge';

// services
import * as moduleUserActions from '../../../redux/actions/moduleUserActions';
import * as userModuleApi from '../../../services/userModuleApi';
import { USER_MODULE_STATUS_VALUES } from '../../../services/appConstants';
import AdvanceTableWrapper from '../../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';

const AssignedUsers = ({ onAction }) => {
	let dispatch = useDispatch();
	const { filteredAssignedUsers } = useSelector(state => state.moduleUserReducer);
	const [showResetModule, setShowResetModule] = useState(false);
	const [itemToReset, setItemToReset] = useState({});

	const onRemove = (item) => {
		onAction(item, 'assigned');
		// update is attached on the list
		// add user to assigned list
		// call api
		//dispatch(moduleUserActions.removeAssignedUserToModule(item));
	}

	const moduleActionAll = async () => {
		//setShowLoader(true);
		for (let i = 0; i < filteredAssignedUsers.length; i++) {
			let singleItem = filteredAssignedUsers[i];

			onAction(singleItem, 'assigned');
			//dispatch(moduleUserActions.removeAssignedUserToModule(singleItem));
		}
	}


	const nameFormatter = (rowData) => {
		return (<>
			{rowData.row.original.firstName} {rowData.row.original.lastName}
		</>)
	};

	const providerFormatter = (rowData) => {
		//let provider = utils.getProviderName(row.providerId).label;
		return (
			<p className="mb-0 text-500">{rowData.row.original.provider}</p>
		)
	};

	const actionFormatter = (item) => {
		let temp = item;
		if (temp !== USER_MODULE_STATUS_VALUES.NEW) {
			// the user has interacted with the module at some point
			return (
				<div style={{ textAlign: 'right' }}>
					<Badge color={`soft-danger`} className="rounded-capsule fs--1">Locked!</Badge>
				</div>
			);
		}
		return (
			<div style={{ textAlign: 'right' }}>
				<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={() => onRemove(item)}>Remove</Button>
			</div>
		);
	};

	const launchResetModal = (item) => {

		setItemToReset({ ...item });

		setShowResetModule(true);
	};

	const resetModule = () => {

		let payload = {
			moduleTemplateId: itemToReset.moduleTemplateId,
			appUserId: itemToReset.appUserId
		};

		userModuleApi.resetModuleForUser(payload)
			.then(() => {
				setShowResetModule(false);
				setItemToReset({});
				dispatch(moduleUserActions.setInitAssignedUsers(itemToReset.moduleTemplateId));


			});



	};
	const resetFormatter = (item) => {

		return (
			<div style={{ textAlign: 'right' }}>
				<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="danger" onClick={() => launchResetModal(item)}>
					<FontAwesomeIcon icon='check' className="ml-1 fs--4" style={{ width: 25 }} /> Reset Module
				</Button>
			</div>
		);
	};

	const showCheckFormatter = ((cell) => {

		if (cell === true) {
			return <Badge color={`soft-success`} className="rounded-capsule fs--1 d-block">
				<FontAwesomeIcon icon={'check'} className="ml-1 fs--4" color={'green'} /></Badge>
		} else {
			return null;
		}
	});

	const badgeFormatter = (rowData) => {
		let color = '';
		let icon = '';
		let text = '';

		const { statusId } = rowData.row.original;

		switch (statusId) {
			case USER_MODULE_STATUS_VALUES.NEW:
				color = 'secondary';
				icon = 'edit';
				text = 'New';
				break;
			case USER_MODULE_STATUS_VALUES.IN_PROGRESS:
				color = 'primary';
				icon = 'stream';
				text = 'In Progress';
				break;
			case USER_MODULE_STATUS_VALUES.COMPLETED:
				color = 'success';
				icon = 'user-graduate';
				text = 'Completed';
				break;
			case USER_MODULE_STATUS_VALUES.FAILED:
				color = 'danger';
				icon = 'user-times';
				text = 'Failed';
				break;
			case USER_MODULE_STATUS_VALUES.EXPIRED:
				color = 'danger';
				icon = 'calendar-times';
				text = 'Expired';
				break;
		}

		return (
			<SoftBadge pill bg={color} className="fs--2">
				{text}
			</SoftBadge>
		);
	};

	const columns = [
		{
			accessor: 'firstName',
			Header: 'Name',

			cellProps: { className: 'py-2 align-middle' },
			Cell: nameFormatter,
			disableSortBy: false,
		},
		{
			accessor: 'email',
			Header: 'Email',
			cellProps: { className: 'py-2 align-middle' },
			disableSortBy: false,
		},
		{
			accessor: 'provider',
			Header: 'Provider',
			cellProps: { className: 'py-2 align-middle' },
			disableSortBy: false,
			Cell: providerFormatter
		},
		{
			accessor: 'isAdmin',
			Header: 'Admin',
			disableSortBy: false,
			cellProps: { className: 'py-2 align-middle text-center' },
			headerProps: { style: { textAlign: 'center', fontWeight: 'bold' } },
			Cell: (item) => showCheckFormatter(item.row.original.isAdmin)
		},
		{
			accessor: 'isInstructor',
			Header: 'Instructor',
			disableSortBy: false,
			cellProps: { className: 'py-2 align-middle text-center' },
			headerProps: { style: { textAlign: 'center', fontWeight: 'bold' } },
			Cell: (item) => showCheckFormatter(item.row.original.isInstructor)
		},
		{
			accessor: 'isStudent',
			Header: 'Student',
			disableSortBy: false,
			cellProps: { className: 'py-2 align-middle text-center' },
			headerProps: { style: { textAlign: 'center', fontWeight: 'bold' } },
			Cell: (item) => showCheckFormatter(item.row.original.isStudent)
		},
		{
			accessor: 'statusId',
			Header: 'Status',
			headerProps: { style: { textAlign: 'center', fontWeight: 'bold' } },
			cellProps: { className: 'py-2 align-middle text-center' },
			disableSortBy: false,
			Cell: badgeFormatter
		},
		{
			accessor: 'reset',
			Header: '',
			disableSortBy: true,
			cellProps: { className: 'py-2 align-middle text-right' },
			Cell: (item) => resetFormatter(item.row.original)
		},
		{
			accessor: 'action',
			Header: '',
			disableSortBy: true,
			cellProps: { className: 'py-2 align-middle text-right' },
			Cell: (item) => actionFormatter(item.row.original)
		}
	];


	return (
		<>
			<Modal isOpen={showResetModule} centered style={{ maxWidth: 600 }}>
				<ModalHeader>Reset Module</ModalHeader><br />
				<ModalBody className="bg-light">
					<Row>
						<Col xl={12}>
							<Label className="text-danger">Reset module for this user?</Label>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button size="sm" outline={false} className="pr-3 fs--1 mr-2" color="success" onClick={resetModule}>Yes, Reset Module</Button>
					<Button size="sm" outline={true} className="pr-3 fs--1" color="danger" onClick={() => setShowResetModule(false)}>Cancel</Button>
				</ModalFooter>
			</Modal>

			<Row className="align-items-center">
				<Col className="col-12">
					<h3>Assigned Module Users</h3>
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col className="col-6 text-left">
					<Button size="sm" outline={true} className="pr-3 fs--1 me-2 mb-2" color="primary">
						<CSVLink data={filteredAssignedUsers} filename="userList.csv">Download List</CSVLink>
					</Button>

				</Col>
				<Col className="col-6 text-right">
					<Button size="sm" outline={false} className="pr-3 fs--1 me-2 mb-2" color="info" onClick={() => moduleActionAll()}>
						<FontAwesomeIcon icon='check' className="ml-1 fs--4" style={{ width: 25 }} /> Remove All From Module
					</Button>
				</Col>
			</Row>

			<Row>
				<Col className="col-12">

					{filteredAssignedUsers && <AdvanceTableWrapper
						columns={columns}
						data={filteredAssignedUsers}
						sortable
						pagination
						perPage={10}
					>

						<AdvanceTable
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
						/>
					</AdvanceTableWrapper>}

					{/* <BootstrapTable
						bootstrap4
						keyField="appUserId"
						data={filteredAssignedUsers}
						columns={columns}
						bordered={false}
						classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
						rowClasses="btn-reveal-trigger"
						headerClasses="bg-200 text-900"
					/> */}
				</Col>
			</Row>
		</>
	);
};
export default AssignedUsers;