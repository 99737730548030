import { STUDENT as ActionTypes } from '../actionTypes';
import * as studentApi from '../../services/studentApi';


export function setModuleManifest(myRequest) {

	return async (dispatch) => {
		let response = await studentApi.getModuleManifest(myRequest);

		let payload = null;
		if (response.data.success === true) {

			payload = response.data.result;
		} else {
			// return - we are fucked!
		}

		dispatch(
			{
				type: ActionTypes.SET_MANIFEST,
				payload: payload
			}
		)
	}
}

export function startQuiz(myRequest) {
	return async (dispatch) => {
		// let myPayload = {
		// 	useSectionId: myRequest.userSectionId,
		// 	quizTemplateId: myRequest.quizTemplateId
		// };	
		let response = await studentApi.startQuizAttempt(myRequest);

		let payload = null;
		if (response.status === 200) {
			payload = response.data;
		} else {
			// return - we are fucked!
		}

		dispatch(
			{
				type: ActionTypes.START_QUIZ,
				payload: payload
			}
		)
	}
}

export function updateVideoTime(item) {
	return async (dispatch) => {

		let progress = null;

		if (item.videoProgress != null) {
			progress = item.videoProgress.toString();
		}



		let payload = {
			"userSectionId": item.userSectionId,
			"progress": progress,
			"statusId": item.userSectionStatusId
		};

		let response = await studentApi.updateVideoProgress(payload);

		if (response.status === 200) {
			payload = response.data;
		} else {

			// return - we are fucked!
		}

		dispatch(
			{
				type: ActionTypes.UPDATE_VIDEO_PROGRESS,
				payload: item
			}
		)
	}
}

export function completeSection(payload) {
	return async (dispatch) => {
		let response = await studentApi.completeSection(payload);

		if (response.status === 200) {
			payload = response.data;
		} else {
			// return - we are fucked!
		}

		dispatch(
			{
				type: ActionTypes.UPDATE_VIDEO_PROGRESS,
				payload: payload
			}
		)
	}
}


export function logQuizAttempt(payload) {
	return async (dispatch) => {
		// we aren't going to make an api call
		// the start quiz call will take care of increasing the user section quiz attempt increment to one more for us
		// all we are doing here is increasing the selected user section attempt by one for redux

		payload.attemptNumber++;

		dispatch(
			{
				type: ActionTypes.UPDATE_USER_SECTION,
				payload: payload
			}
		)
	}
}
