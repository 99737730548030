import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button, Card, CardBody, Row, Col, CardHeader, Collapse, FormGroup, Label, Alert, Input, CustomInput
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import Scrollbar from 'react-scrollbars-custom';

// components
import Loader from '../../components/common/Loader';

// services
import * as sectionActions from '../../redux/actions/sectionActions';
import * as sectionApi from '../../services/sectionApi';


const SectionDataTable = ({ moduleTemplateId, selectedItem }) => {

	let dispatch = useDispatch();
	const { sections } = useSelector(state => state.sectionReducer);
	const descriptionRef = useRef();

	const [errorMessage, setErrorMessage] = useState('');
	const [showAddArea, setShowAddArea] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [sectionDescription, setSectionDescription] = useState('');
	const [sectionTypeId, setSectionTypeId] = useState(2);

	useEffect(() => {

		loadData();
	}, []);

	const loadData = async () => {
		setShowLoader(true);
		dispatch(sectionActions.setItems(moduleTemplateId));
		setShowLoader(false);
	}

	const onSelectedItem = (row) => {
		selectedItem(row);
	}

	const reorderItems = (item, direction) => {
		setShowLoader(true);
		dispatch(sectionActions.updateOrder(moduleTemplateId, sections, item, direction))
			.then(() => {
				setShowLoader(false);
			});
	}

	const actionFormatter = (cell, item) => {
		return (
			<Button size="sm" outline={true} className="pr-3 fs--1 me-2" color="info" onClick={() => onSelectedItem(item)}>
				<FontAwesomeIcon icon="edit" className="ml-1 fs--4" /> View
			</Button>
		);
	};

	const sectionOrderButtons = (cell, item) => {

		var lastItemInArray = sections[sections.length - 1];
		let isLastItem = item.sectionTemplateId === lastItemInArray.sectionTemplateId ? true : false;
		let isFirstItem = item.order === 0 ? true : false;

		return (
			<>
				<Button size="sm" outline={true} className="pr-3 fs--1 me-2" disabled={isFirstItem} color={isFirstItem ? 'secondary' : 'info'} onClick={() => reorderItems(item, 'up')}>
					<FontAwesomeIcon icon="chevron-up" className="ml-1 fs--4" />
				</Button>
				<Button size="sm" outline={true} className="pr-3 fs--1 me-2" disabled={isLastItem} color={isLastItem ? 'secondary' : 'info'} onClick={() => reorderItems(item, 'down')}>
					<FontAwesomeIcon icon="chevron-down" className="ml-1 fs--4" />
				</Button>
			</>
		);
	};

	const submitForm = async () => {
		setShowLoader(true);
		if (!sectionDescription) {
			setErrorMessage('Please enter a name for the section');
			return;
		}

		let newOrderPosition = 0;
		if (sections.length !== 0) {
			// get the last item and add 1 to it
			var lastItemInArray = sections[sections.length - 1];
			let index = sections.findIndex(c => c.sectionTemplateId === lastItemInArray.sectionTemplateId);
			newOrderPosition = index + 1;
		}

		setErrorMessage('');
		let payload = {
			description: sectionDescription,
			sectionTypeId: sectionTypeId,
			moduleTemplateId: moduleTemplateId,
			order: newOrderPosition
		};

		// insert the new record
		await sectionApi.insert(payload)
			.then((resp) => {

				if (resp.data.success === true) {
					// get a fresh list of records with the correct order
					dispatch(sectionActions.setItems(moduleTemplateId))
						.then(() => {
							setShowAddArea(false);
							setShowLoader(false);

							// set defaults
							setSectionTypeId(2); // pdf
							setSectionDescription(''); // clear section name field	
						});
				}

				// insert failed
				return;
			})

	}



	const columns = [
		{
			dataField: '',
			text: 'Section Order',
			classes: 'py-2 align-middle',
			formatter: sectionOrderButtons,
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: "left" };
			},
		},
		{
			dataField: 'description',
			text: 'Section Name',
			classes: 'py-2 align-middle',
			//formatter: linkFormatter,
			sort: false,
		},
		{
			dataField: 'sectionType',
			text: 'Type',
			classes: 'py-2 align-middle',
			//formatter: linkFormatter,
			sort: false,
		},
		{
			text: 'File',
			dataField: 'fileName',
			classes: 'py-2 align-middle',
			sort: false,
		},
		// {
		// 	dataField: 'order',
		// 	text: 'Order',
		// 	classes: 'py-2 align-middle',
		// 	//formatter: linkFormatter,
		// 	sort: true,
		// },
		{
			dataField: 'action',
			//classes: 'border-0 align-middle', 
			classes: 'align-middle text-right',
			headerClasses: 'border-0',
			text: '',
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: "center" };
			},
			formatter: actionFormatter,
			style: () => {
				return { textAlign: 'center' }
			}
		}
	];

	return (
		<>
			<Row>
				<Col xl={12} >
					<Card className="mb-3">
						<CardHeader >
							<Row className="align-items-center">
								<Col className="col-6">
									<h3>Sections</h3>
								</Col>
								<Col className="col-6 text-right">
									<Row className="align-items-right">
										<Col className="col-12 text-right">
											<Button size="sm" outline={true} className="pr-3 fs--1" color="success" onClick={() => {
												descriptionRef.current.focus();
												setShowAddArea(true);

											}}>
												Add Section
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</CardHeader>
						<Collapse isOpen={showAddArea}>
							<CardBody className="bg-light align-items-right">
								{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
								<div className="form-row">
									<div className="col-6">
										<FormGroup>
											<Label>Section *</Label>
											<Input
												placeholder='Section Description'
												autoComplete='nofill'
												innerRef={descriptionRef}
												value={sectionDescription ? sectionDescription : ''}
												onChange={({ target }) => setSectionDescription(target.value)}
											/>
										</FormGroup>
									</div>
									<div className="col-6">
										<FormGroup>
											<Label >
												Section Type *
											</Label>
											<div>
												<Input
													type="radio"
													name="pdfSection"
													id="pdfSection"
													label="Pdf Section"
													inline="true"
													checked={sectionTypeId === 2 ? true : false}
													onChange={() => setSectionTypeId(2)}
												/>
												<Input
													type="radio"
													name="videoSection"
													id="videoSection"
													label="Video Section"
													inline="true"
													checked={sectionTypeId === 1 ? true : false}
													onChange={() => setSectionTypeId(1)}
												/>
												<Input
													type="radio"
													name="quizSection"
													id="quizSection"
													label="Quiz Section"
													inline="true"
													checked={sectionTypeId === 3 ? true : false}
													onChange={() => setSectionTypeId(3)}
												/>
											</div>
										</FormGroup>
									</div>
								</div>

								<div style={{ textAlign: 'right' }}>
									<Button size="sm" outline={false} className="pr-3 fs--1 mr-2" color="success" onClick={submitForm}>Insert</Button>
									<Button size="sm" outline={true} className="pr-3 fs--1" color="danger" onClick={() => setShowAddArea(false)}>Cancel</Button>
								</div>

							</CardBody>
						</Collapse>

						<CardBody>
							{showLoader === true ?
								(<Loader />) :

								(<Scrollbar style={{ height: 500 }}>

									{sections.length === 0 ? (
										<>
											<div style={{ textAlign: 'center', marginTop: 35, marginBottom: 35 }}>
												<FontAwesomeIcon icon='list-alt' size='10x' className='mb-3' />
												<h1 className="fs-5">Get Started!</h1>
												<h5 className="fs-0">Add a section to begin.</h5>
											</div></>) : (
										<div className="table-responsive">
											<BootstrapTable
												bootstrap4
												keyField="sectionTemplateId"
												data={sections}
												columns={columns}
												bordered={false}
												classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
												rowClasses="btn-reveal-trigger"
												headerClasses="bg-200 text-900"


											/>
										</div>
									)}
								</Scrollbar>)}
						</CardBody>

					</Card>
				</Col>

			</Row>
		</>
	);
};
export default SectionDataTable;