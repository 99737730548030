import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, } from 'reactstrap';
import Select from 'react-select';
import MaskedInput from 'react-maskedinput'
import { Form } from 'react-bootstrap';

// services
import * as userApi from '../services/userApi';
import { PROVIDERS } from '../services/appConstants';
import { BUTTON_ACTIONS } from '../services/appConstants'
import * as utils from '../helpers/utils';

const UserFormModal = ({ buttonAction, selectedItem, showModal, cancelItem, updateItem, insertItem, facilityList }) => {

	const emailRef = useRef();
	const passwordRef = useRef();
	const firstNameRef = useRef();
	const lastNameRef = useRef();

	const baseSelectedFacilityObject = {
		value: 0, label: 'Select A Facility'
	}

	const baseUserObject = {
		id: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
		originalEmail: '',
		password: '',
		zip: '',
		statusId: 1,
		facilityId: null,
		facility: null,
		facilityName: null,
		providerId: 1,
		isAdmin: false,
		isInstructor: false,
		isStudent: false,
		canAccessPortal: false
	};


	const [providerList, setProviderList] = useState(PROVIDERS);
	const [errorMessage, setErrorMessage] = useState('');
	const [selectedProvider, setSelectedProvider] = useState({ value: 1, label: 'ATI' });
	const [userMasterList, setUserMasterList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [selectedFacility, setSelectedFacility] = useState(baseSelectedFacilityObject);
	const [selectedUser, setSelectedUser] = useState(baseUserObject);
	const [isActive, setIsActive] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	useEffect(() => {
		if (buttonAction === BUTTON_ACTIONS.INSERT) {
			setSelectedUser(baseUserObject);
		} else {
			// we are updating
			selectedItem.originalEmail = selectedItem.email;

			setIsActive(selectedItem.statusId === 1 ? true : false);

			var selectedProvider = utils.getProviderName(selectedItem.providerId);
			setSelectedProvider(selectedProvider);

			// selectedItem.facilityId === null ? setSelectedFacility(baseSelectedFacilityObject) :
			// 	setSelectedFacility({ value: selectedItem.facilityId, label: selectedItem.facilityName });
			// debugger;

			setSelectedUser(selectedItem);
		}
	}, []);

	const submitModal = () => {
		if (validateForm()) {
			// we are good to go
			selectedUser.statusId = isActive ? 1 : 2;

			if (selectedUser.phoneNumber) {
				if (!selectedUser.phoneNumber.includes("_")) {
					selectedUser.phoneNumber = utils.cleanPhone(selectedUser.phoneNumber);
				} else {
					setErrorMessage('Phone number is not properly formatted. It is not required!');
					return;
				}
			}

			if (buttonAction === BUTTON_ACTIONS.UPDATE) {

				userApi.update(selectedUser)
					.then(resp => {

						if (resp.data.success === true) {
							updateItem(selectedUser);
						}
					})
					.catch(e => {
						// failed
					})
			} else {
				setDisableButton(true);

				selectedUser.originalEmail = selectedUser.email; // leave this here!!!!!! i know it seems out of place but fucking leave it!
				// otherwise we are inserting
				userApi.insert(selectedUser)
					.then(resp => {
						if (resp.status !== 200) {
							if (resp.data.errors) {
								if (resp.data.errors.duplicateEmail !== undefined) {
									setErrorMessage(resp.data.errors.duplicateEmail[0]);
									setDisableButton(false);
								}
							}
						} else {
							insertItem(resp.data.profile);
						}
					})
					.catch(e => {
						setDisableButton(false);
					})
			}
		}
	}

	const validateForm = () => {
		if (!selectedUser.email) {
			setErrorMessage('Please enter an email address');
			emailRef.current.focus();
			return false;
		} else {
			const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!emailRegex.test(selectedUser.email)) {
				emailRef.current.focus();
				setErrorMessage('Please enter a valid email address');
				return;
			}
		}

		if (buttonAction === BUTTON_ACTIONS.INSERT) {
			if (!selectedUser.password) {
				setErrorMessage('Please enter an password');
				passwordRef.current.focus();
				return false;
			} else {
				const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9 ])(?=.{6,})/;
				if (!passwordRegex.test(selectedUser.password)) {
					setErrorMessage('A password must contain at least 6 characters, one uppercase letter, one lowercase letter, one number, and one special character');
					passwordRef.current.focus();
					return;
				}
			}
		}

		if (!selectedUser.firstName) {
			firstNameRef.current.focus();
			setErrorMessage('Please enter a first name');
			firstNameRef.current.focus();
			return false;
		}

		if (!selectedUser.lastName) {
			setErrorMessage('Please enter a last name');
			lastNameRef.current.focus();
			return false;
		}

		setErrorMessage('');
		return true;
	}

	const cancelModal = () => {
		cancelItem();
	}

	const handleProviderChange = (item) => {
		setSelectedUser({ ...selectedUser, providerId: item.value });
		setSelectedProvider(item);
	}

	// const handleFacilityChange = (item) => {
	// 	if (item) {
	// 		setSelectedUser({ ...selectedUser, facilityId: item.value, facility: item.label, facilityName: item.label });
	// 		setSelectedFacility(item);
	// 	} else {
	// 		// user wants to clear the control
	// 		setSelectedUser({ ...selectedUser, facilityId: null, facility: null, facilityName: null });
	// 		setSelectedFacility(baseSelectedFacilityObject);
	// 	}
	// }

	return (
		<>
			<Modal isOpen={showModal} centered style={{ maxWidth: 950 }}>
				<ModalHeader>{buttonAction} User</ModalHeader>
				<ModalBody>
					{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}

					<div className="form-row">
						<div className="col-6">
							<FormGroup >
								<Label>Email *</Label>
								<Input
									innerRef={emailRef}
									placeholder='Email'
									autoComplete='nofill'
									value={(selectedUser && selectedUser.email) ? selectedUser.email : ''}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, email: target.value })}
								/>
							</FormGroup>
							{buttonAction === BUTTON_ACTIONS.INSERT && <FormGroup>
								<Label>Password * <small>(example: Test21@!)</small></Label>
								<Input
									innerRef={passwordRef}
									placeholder='Password'
									autoComplete='nofill'
									type='password'
									value={(selectedUser && selectedUser.password) ? selectedUser.password : ''}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, password: target.value })}
								/>
							</FormGroup>}
							<FormGroup>
								<Label>First Name *</Label>
								<Input
									innerRef={firstNameRef}
									placeholder='First name'
									autoComplete='nofill'
									value={(selectedUser && selectedUser.firstName) ? selectedUser.firstName : ''}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, firstName: target.value })}
								/>
							</FormGroup>
							<FormGroup>
								<Label>Last Name *</Label>
								<Input
									innerRef={lastNameRef}
									placeholder='Last name'
									autoComplete='nofill'
									value={(selectedUser && selectedUser.lastName) ? selectedUser.lastName : ''}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, lastName: target.value })}

								/>
							</FormGroup>
							<FormGroup>
								<Label>Phone</Label>
								<MaskedInput
									className="form-control" mask="(111) 111-1111"
									name="fone"
									autoComplete='nofill'
									value={(selectedUser && selectedUser.phoneNumber) ? selectedUser.phoneNumber : ''}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, phoneNumber: target.value })} />
							</FormGroup>
							<FormGroup>
								<Label>Zip</Label>
								<Input
									placeholder='Zip'
									autoComplete='nofill'
									value={(selectedUser && selectedUser.zip) ? selectedUser.zip : ''}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, zip: target.value })}
								/>
							</FormGroup>
						</div>
						<div className="col-6">
							<FormGroup>
								<Label>Provider *</Label>
								<Select
									value={{ value: selectedProvider.value, label: selectedProvider.label }}
									defaultValue={{ value: 1, label: 'ATI' }}
									onChange={handleProviderChange}
									options={providerList} />

							</FormGroup>
							{/* <FormGroup>
								<Label>Facility</Label>
								<Select
									value={{ value: selectedFacility.value, label: selectedFacility.label }}
									//defaultValue={{ value: 0, label: 'Select Facility' }}
									onChange={handleFacilityChange}
									isClearable
									options={facilityList} />
							</FormGroup> */}
							<FormGroup >
								<Label>Status</Label>
								<Form.Check
									type="switch"
									id="status"

									label="Is Active"
									className="form-label-nogutter"
									name="showFollowers"
									checked={isActive}
									onChange={({ target }) => setIsActive(target.checked)}
								/>
							</FormGroup>




							<FormGroup>
								<Label>Roles</Label><br />
								<Form.Check
									type="switch"
									id="admin"
									inline
									label="Is Admin"
									className="form-label-nogutter"
									name="showFollowers"
									checked={selectedUser.isAdmin}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, isAdmin: target.checked })}
								/>

								<Form.Check
									type="switch"
									id="instructor"
									inline
									label="Is Instructor"
									className="form-label-nogutter"
									name="showFollowers"
									checked={selectedUser.isInstructor}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, isInstructor: target.checked })}
								/>

								<Form.Check
									type="switch"
									id="student"
									inline
									label="Is Student"
									className="form-label-nogutter"
									name="showFollowers"
									checked={selectedUser.isStudent}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, isStudent: target.checked })}
								/>

							</FormGroup>
							<FormGroup>
								<Label>Can Access Portal</Label>
								<Form.Check
									type="switch"
									id="portal"
									label="Can Access Portal"
									className="form-label-nogutter"
									name="showFollowers"
									checked={selectedUser.canAccessPortal}
									onChange={({ target }) => setSelectedUser({ ...selectedUser, canAccessPortal: target.checked })}
								/>

							</FormGroup>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={submitModal} disabled={disableButton}>{buttonAction}</Button>{'  '}
					<Button color="info" onClick={cancelModal}>Cancel</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default UserFormModal;