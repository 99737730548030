import { SECTIONS as ActionTypes } from '../actionTypes';

const initialState = {
	section: {
		sectionTemplateId: 0,
		moduleTemplateId: 0,
		description: '',
		sectionTypeId: 0,
		sectionType: '',
		url: '',
		fileName: '',
		order: 0,
		numberOfAttemptsAllowed: null,
		passingScore: null
	},
	sections: [],
};


export const sectionReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_SECTIONS:

			return {
				...state,
				sections: action.payload
			};
		case ActionTypes.SET_SELECTED_SECTION:
			return {
				...state,
				section: action.payload
			};
		case ActionTypes.SET_EMPTY_SECTION:
			return {
				...state,
				section: initialState.module
			};

		case ActionTypes.INSERT_SECTION:
			// push item into  list
			let newList = [...state.sections];
			newList.push(action.payload);

			return {
				...state,
				sections: newList,
				section: initialState.section
			};

		case ActionTypes.UPDATE_SECTION:
			// clear the selected item with the initial.state item
			// update the item in the list of items
			let myItems = [...state.sections];
			let indexToUpdate = myItems.findIndex(item => item.sectionTemplateId === action.payload.sectionTemplateId);
			myItems[indexToUpdate] = action.payload;


			return {
				...state,
				sections: myItems,
				section: action.payload
			};
		default:
			return state;
	};
}