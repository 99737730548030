import { MODULE_USER as ActionTypes } from '../actionTypes';
import * as userModuleApi from '../../services/userModuleApi';
import { PROVIDERS } from '../../services/appConstants'

export function updateTabView(view) {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.SET_TAB_VIEW,
				payload: view
			}
		)
	}
}








export function setInitAvailableUsers(moduleTemplateId) {
	return async (dispatch) => {

		let response = await userModuleApi.getUsersAvailableToAttach(moduleTemplateId);

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_INIT_AVAILABLE_USERS,
				payload: payload
			}
		)
	}
}

export function textSearchMasterListAvailable(searchText, masterList) {
	// user wants to search by text, we search against the
	return async (dispatch) => {

		let payload = null;
		let myList = masterList.filter(x => x.lastName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
			|| x.firstName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);


		let error = null;
		if (!Array.isArray(myList) || !myList.length) {
			// the item was not found in the list, return an error object			
			error = `${searchText} was not found in the filtered list of users.  Please try again!`;

		} else {
			payload = {
				searchText: searchText,
				list: myList
			};

			dispatch(
				{
					type: ActionTypes.SEARCH_BY_TEXT_AVAILABLE,
					payload: payload
				}
			)
		}

		return error;
	}
}

export function textSearchMasterListAssigned(searchText, masterList) {
	// user wants to search by text, we search against the
	return async (dispatch) => {

		let payload = null;
		let myList = masterList.filter(x => x.lastName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
			|| x.firstName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);


		let error = null;
		if (!Array.isArray(myList) || !myList.length) {
			// the item was not found in the list, return an error object			
			error = `${searchText} was not found in the filtered list of users.  Please try again!`;

		} else {
			payload = {
				searchText: searchText,
				list: myList
			};

			dispatch(
				{
					type: ActionTypes.SEARCH_BY_TEXT_ASSIGNED,
					payload: payload
				}
			)
		}

		return error;
	}
}

export function filterAvailableUsers(search, masterList) {
	return async (dispatch) => {

		let payload = filterList(search, masterList);

		dispatch(
			{
				type: ActionTypes.SET_FILTERED_AVAILABLE_USERS,
				payload: payload
			}
		)
	}
}
export function filterAssignedUsers(search, masterList) {
	return async (dispatch) => {

		let payload = filterList(search, masterList);

		dispatch(
			{
				type: ActionTypes.SET_FILTERED_ASSIGNED_USERS,
				payload: payload
			}
		)
	}
}


export function updateSearchFilters(search) {
	return async (dispatch) => {

		dispatch(
			{
				type: ActionTypes.UPDATE_SEARCH_FILTERS,
				payload: search
			}
		)
		return search;
	}
}

export function resetSearchFilters(available, assigned) {
	return async (dispatch) => {

		let payload = {
			assigned: assigned,
			available: available
		};
		dispatch(
			{
				type: ActionTypes.CLEAR_SEARCH_FILTERS,
				payload: payload
			}
		)
	}
}

export function setInitAssignedUsers(moduleTemplateId) {
	return async (dispatch) => {

		let response = await userModuleApi.getUsersAttachedToModule(moduleTemplateId);

		let payload = null;
		if (response.data.success === true) {
			payload = response.data.results;
		}

		dispatch(
			{
				type: ActionTypes.SET_INIT_ASSIGNED_USERS,
				payload: payload
			}
		)
	}
}

export function filterList(search, userMasterList) {

	let vandyList = [];
	let atiList = [];
	let traceList = [];
	let providerList = [];

	if (search.isVandy === false && search.isAti === false && search.isTrace === false) {
		providerList = [...userMasterList];
	} else {
		if (search.isVandy === true) {
			vandyList = userMasterList.filter(c => c.provider === PROVIDERS[1].label);
		}
		if (search.isAti === true) {
			atiList = userMasterList.filter(c => c.provider === PROVIDERS[0].label);
		}
		if (search.isTrace === true) {
			traceList = userMasterList.filter(c => c.provider === PROVIDERS[2].label);
		}
		let combinedList = [...vandyList, ...atiList, ...traceList];

		providerList = [...new Set([...combinedList])];
	}

	let students = [];
	let instructors = [];
	let admins = [];

	let filteredList = [];

	if (search.isAdmin === false && search.isInstructor === false && search.isStudent === false) {
		return providerList;
	} else {
		if (search.isStudent === true) {
			students = providerList.filter(c => c.isStudent === true);
		}
		if (search.isInstructor === true) {
			instructors = providerList.filter(c => c.isInstructor === true);
		}
		if (search.isAdmin === true) {
			admins = providerList.filter(c => c.isAdmin === true);
		}
		let combinedList = [...students, ...instructors, ...admins];
		filteredList = [...new Set([...combinedList])]; // remove dups

		return filteredList;
	}
}

export function assignUserToModule(item) {
	return async (dispatch) => {

		// call api to create userModule record
		let apiPayload = {
			moduleTemplateId: item.moduleTemplateId,
			appUserId: item.appUserId
		};

		await userModuleApi.addUserToModule(apiPayload);



		return;

		// dispatch(
		// 	{
		// 		type: ActionTypes.ASSIGN_SINGLE_USER,
		// 		payload: item
		// 	}
		// )
	}
}

export function removeAssignedUserToModule(item) {
	return async (dispatch) => {

		// call api to create userModule record
		let apiPayload = {
			moduleTemplateId: item.moduleTemplateId,
			appUserId: item.appUserId
		};

		await userModuleApi.removeUserFromModule(apiPayload);

		// update master list item so show isAttached to true

		return;
		// dispatch(
		// 	{
		// 		type: ActionTypes.REMOVE_SINGLE_USER,
		// 		payload: item
		// 	}
		// )
	}
}