import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/section';

export const getAll = (moduleTemplateId) => {

	let url = `${apiRoot}/GetAllByModuleId/${moduleTemplateId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const getSingle = (sectionTemplateId) => {

	let url = `${apiRoot}/GetSingle/${sectionTemplateId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

export const updateOrder = (data) => {
	let url = `${apiRoot}/UpdateOrder`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const update = (data) => {
	// let temp = {
	// 	sectionTemplateId: data.sectionTemplateId,
	// 	moduleTemplateId: data.moduleTemplateId,
	// 	description: data.description,
	// 	sectionTypeId: data.sectionTypeId,
	// 	fileName: data.fileName,
	// 	url: data.url,
	// 	order: data.order,
	// 	passingScore: data.passingScore,
	// 	numberofAttemptsAllowed: data.numberofAttemptsAllowed
	// };

	let request = {
		"sectionTemplateId": data.sectionTemplateId,
		"moduleTemplateId": data.moduleTemplateId,
		"description": data.description,
		"sectionTypeId": data.sectionTypeId,
		"fileName": data.fileName,
		"url": data.url,
		"order": data.order,
		"passingScore": data.passingScore,
		"numberOfAttemptsAllowed": data.numberOfAttemptsAllowed
	};

	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};
	return handleFetch(url, options, true);
}

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

export const remove = (moduleTemplateId, sectionTemplateId) => {

	let url = `${apiRoot}/Delete/${moduleTemplateId}/${sectionTemplateId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}