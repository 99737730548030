import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Row, Col, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';

// components
import PageHeader from '../components/common/PageHeader';
import Loader from '../components/common/Loader';

// services
import { USER_SECTION_STATUS_VALUES, LOCAL_STORAGE } from '../services/appConstants';
import * as studentActions from '../redux/actions/studentActions';

const SectionPdf = ({ section, userSectionId }) => {
	let dispatch = useDispatch();
	const { userSections } = useSelector(state => state.studentReducer);

	const [canCompleteSection, setCanCompleteSection] = useState({}); // used for Alert color
	const [localUserSection, setLocalUserSection] = useState({});

	useEffect(() => {
		// get the userSection from redux using the section Id passed
		let myUserSectionFromRedux = userSections.find(item => item.userSectionId === userSectionId);
		setLocalUserSection(myUserSectionFromRedux);
	}, []);

	useEffect(() => {
		// change background color for the radio
		let sectionStatusId = localUserSection.userSectionStatusId;

		let color = "warning";
		switch (sectionStatusId) {
			case USER_SECTION_STATUS_VALUES.COMPLETE:
				color = "success";
				break;
			case USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION:
				color = "danger";
				break;
		}

		setCanCompleteSection({ color: color, title: "Download and view the attached material in order to unlock the completion action." });

	}, [localUserSection.userSectionStatusId]);

	const linkClick = () => {
		// update the status		
		let payload = { ...localUserSection };
		payload.userSectionStatusId = USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION;
		setLocalUserSection(payload);

		// we are cheating here
		// we are going to use the update video time to update the status to "able to be completed"
		// i don't want to make another call just to update the status of the user section to 4
		// in this case the video progress will just be null and ignored but we will 
		// update the statusId like we want
		dispatch(studentActions.updateVideoTime(payload));

		window.open(section.url ?? 'https://google.com', '_blank');
	}

	const onCompleteSection = (status) => {

		let payload = { ...localUserSection };
		payload.userSectionStatusId = status === true ? USER_SECTION_STATUS_VALUES.COMPLETE : USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION;
		setLocalUserSection(payload);


		// pass with status of completed
		dispatch(studentActions.completeSection(payload))
			.then(() => {
				let params = JSON.parse(localStorage.getItem(LOCAL_STORAGE.STUDENT_MODULE));
				dispatch(studentActions.setModuleManifest(params));
			});


	}

	return (
		<>
			<Row
			//style={{ marginTop: 50, marginBottom: 50 }}
			>
				<Col xl={12} className="pr-xl-2">
					<Card className="mb-3">
						<CardBody>
							<Row style={{ marginBottom: 10 }}>
								<Col className="col-12">
									<h5>{section.description}</h5>
								</Col>
							</Row>
							<Row style={{ marginBottom: 5 }}>
								<Col className="col-12 mb-2">
									<a style={{ color: '#2c7be5', textDecoration: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => linkClick()}><FontAwesomeIcon icon="file-pdf" className="ml-1 fs--4" /> {section.fileName}</a>
								</Col>
							</Row>
							{(localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION) ?
								(<Row>
									<Col className="col-12">
										<Alert color={canCompleteSection.color}>
											{/* <input
												type="switch"
												id="pdfStatus"
												label={(localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION || localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE) ? "I have reviewed the material attached." : canCompleteSection.title}
												//disabled={(localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION) ? false : true}
												checked={localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE ? true : false}
												onChange={({ target }) => onCompleteSection(target.checked)}
											/> */}

											{/* <CustomInput
												type="switch"
												id="pdfStatus"
												label={(localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION || localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE) ? "I have reviewed the material attached." : canCompleteSection.title}
												checked={localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE ? true : false}
												onChange={({ target }) => onCompleteSection(target.checked)}
											/> */}

											<Form.Check
												type="switch"
												id="pdfStatus"
												className="form-label-nogutter"
												name="showFollowers"
												label={(localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION || localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE) ? "I have reviewed the material attached." : canCompleteSection.title}
												checked={localUserSection.userSectionStatusId === USER_SECTION_STATUS_VALUES.COMPLETE ? true : false}
												onChange={({ target }) => onCompleteSection(target.checked)}
											/>
										</Alert>
									</Col>
								</Row>) : (
									<Row>
										<Col className="col-12">
											<Alert color={canCompleteSection.color}>
												{localUserSection.userSectionStatusId !== USER_SECTION_STATUS_VALUES.CAN_COMPLETE_SECTION ? (canCompleteSection.title) : ("You have successfully completed this section!")}
											</Alert>
										</Col>
									</Row>
								)
							}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default SectionPdf;